import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import "hammerjs";
import PropTypes from 'prop-types';

const SharedTooltip = ({ category, points }) => {
  return (
    <div>
      <div>{category}</div>
      {points.map((point) => (
        <div key={point}>
          {point.category} : {point.dataItem}
        </div>
      ))}
    </div>
  );
};

SharedTooltip.propTypes = {
  category: PropTypes.any,
  points: PropTypes.array.isRequired,
  map: PropTypes.func
};

const chartStyle = {
  position: "absolute",
  bottom: 0,
  width: "100%",
  height: "100%",
  padding: "2%",
};

const BarChart = props => {
  const roundToNextHundred = num => Math.ceil(num / 100) * 100;
  const sharedTooltipRender = context => <SharedTooltip {...context} />;
  const maxValue = Math.max(...props.yAxisData);
  const yMax = maxValue > 100 ? maxValue : 100;
  return (
    <Chart style={chartStyle}>
      <ChartTooltip shared={true} render={sharedTooltipRender} />
      <ChartTitle text={props?.title} />
      <ChartLegend position="top" orientation="horizontal" />
      <ChartValueAxis>
        <ChartValueAxisItem
          title={{
            text: "Count",
          }}
          min={0}
          max={roundToNextHundred(yMax)}
        />
      </ChartValueAxis>
      <ChartCategoryAxis>
        <ChartCategoryAxisItem
          categories={props.xAxisData}
          startAngle={45}
          labels={{
            rotation: "auto",
          }}
        />
      </ChartCategoryAxis>
      <ChartSeries>
        <ChartSeriesItem
          tooltip={{
            visible: true,
          }}
          color="#66c5d2"
          type="column"
          data={props.yAxisData}
        />
      </ChartSeries>
    </Chart>
  );
};

BarChart.propTypes = {
  yAxisData: PropTypes.array.isRequired,
  title: PropTypes.string,
  xAxisData: PropTypes.array.isRequired,
};

export { BarChart };


