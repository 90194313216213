import "./tank-leaks.scss";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedReport,
  triggerNotification,
} from "../../../../../state-management/actions";
import { reportsService } from "../../../../../services/reports";
import { Button } from "@progress/kendo-react-all";
import { getMultiSelectTreeValue } from "@progress/kendo-react-dropdowns";
import { store } from "../../../../../state-management/store";
import { addAllOptions, colsToShow, getTankGroups } from "./tank-leaks-utils";
import {
  MultiSelectionDropDown,
  multiSelectionDropDownFields,
} from "../../../../components/multi-select-dropdown/multi-select-dropdown";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import {
  getFormattedDate,
  getDaysBeforeDate,
} from "../../../../../services/utils";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import {
  getAllSelectedOptions,
  SCREEN_IDS,
  getShipTos,
  getSiteNames,
  getFilterRegions,
} from "../../reportUtils";
import { DEFAULT_PAGE_SIZE, isDachCluster } from "../common-utils";
import { DropDownSelection } from "../pump-index-report-screen/pump-index-monthly-exception-screen";
import { DateRangePickerComponent } from "../../../../components/date-range-picker/date-range-picker";
import { TabComponent } from "../../../../components/tab-component/tab-component";
import { EndDateInput, StartDateInput } from "../common-grid-cells";
import { UIText } from "../label-constants";

export const LeakTabOptions = {
  TANK_LEAKS: 0,
  DAILY_REPORT: 1,
  MINS_REPORT: 2,
};

const TankLeaksScreen = () => {
  const dispatch = useDispatch();
  const [currentField, setCurrentField] = useState(null);
  const [selectedTab, setSelectedTab] = useState(LeakTabOptions.TANK_LEAKS);
  const [resetIndex, setResetIndex] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState(null);
  const exportGridRef = useRef(null);
  const [exporting, setExporting] = useState(false);

  const [filters, setFilters] = useState({
    selectedRegions: [],
    regions: [],
    selectedSiteId: [],
    selectedSiteName: [],
    selectedProducts: [],
    products: [],
    selectedTanks: [],
    tankGroup: [],
    selectedDates: {
      start: getDaysBeforeDate(1),
      end: getDaysBeforeDate(1),
    },
    prevSelectedDates: {
      start: null,
      end: null,
    },
    allSiteIds: [],
    allSiteNames: [],
  });

  const [masterData, setMasterData] = useState();
  const [leaksData, setLeaksData] = useState([]);
  const [showError, setShowError] = useState(false);
  const [masterDataUpdated, setMasterDataUpdated] = useState(false);
  const [dateUpdated, setDateUpdated] = useState(false);
  const [tabChanged, setTabChanged] = useState(false);

  const [error, setError] = useState("");
  const [filterDataLoading, setFilterDataLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const defaultCountry = useSelector(
    (state) => state?.reports?.selectedCountry
  );
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);
  const defaultCluster = useSelector(
    (state) => state?.reports?.selectedCluster
  );
  const [currentCluster, setCurrentCluster] = useState(defaultCluster);

  const report = store.getState().reports?.selectedReport;

  store.subscribe(() => {
    const { selectedCountry, selectedCluster } = store.getState().reports;
    setCurrentCountryCode(selectedCountry);
    setCurrentCluster(selectedCluster);
  });

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedDates: {
        start: getDaysBeforeDate(1),
        end: getDaysBeforeDate(1),
      },
    }));
  }, [currentCluster]);

  const getTabIndex = (title) => {
    switch (title) {
      case UIText.DAILY_RECONCILIATION_REPORT:
        return LeakTabOptions.DAILY_REPORT;
      case UIText.ONE_FIVE_MINS_RECONCILIATION_REPORT:
        return LeakTabOptions.MINS_REPORT;
      default:
        return LeakTabOptions.TANK_LEAKS;
    }
  };

  useEffect(() => {
    const { selectedReport } = store.getState().reports;
    if (selectedReport?.pageId === SCREEN_IDS.TANK_THRESHOLD_RESULTS) {
      setSelectedTab(getTabIndex(selectedReport?.tab));
    }
  }, [report?.reportId]);

  const [regionSelectedValues, setRegionSelectedValues] = useState(
    filters.selectedRegions
  );

  const [shipToSelectedValues, setShipToSelectedValues] = useState(
    filters.selectedSiteId
  );

  const [siteNameSelectedValues, setSiteNameSelectedValues] = useState(
    filters.selectedSiteName
  );

  const [productSelectedValues, setProductSelectedValues] = useState(
    filters?.selectedProducts
  );
  const [tankSelectedValues, setTankSelectedValues] = useState(
    filters?.selectedTanks
  );

  const [datePickerClosed, setDatePickerClosed] = useState(true);

  useEffect(() => {
    fetchMasterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountryCode]);

  useEffect(() => {
    const regions = getFilterRegions(masterData, true, currentCountryCode);
    const selectedRegions = regions?.length > 0 ? [regions?.[0]] : [];
    setFilters({
      ...filters,
      selectedRegions,
    });
    setRegionSelectedValues(selectedRegions);
    const siteIds =
      filters.selectedRegions?.length > 0
        ? getShipTos(masterData, filters.selectedRegions, false)
        : [];

    const siteNames =
      filters.selectedRegions?.length > 0
        ? getSiteNames(masterData, filters.selectedRegions, false)
        : [];

    setFilters((prev) => {
      let siteId = prev?.selectedSiteId;
      if (!siteId) {
        siteId = report?.filters?.GlobalSiteId?.[0]
          ? report?.filters?.GlobalSiteId?.[0]
          : siteIds?.[0]?.globalSiteId;
      }
      const siteData = siteIds?.find((item) => item.id === siteId);
      const selectedDate = report?.filters?.RequestDate
        ? {
            start: report.filters.RequestDate?.startDate,
            end: report.filters.RequestDate?.endDate,
          }
        : {
            start: prev.selectedDates.start,
            end: prev.selectedDates.end,
          };

      return {
        ...prev,
        regions,
        selectedRegions,
        selectedSiteId: siteId,
        selectedSiteName: siteData?.siteName,
        allSiteIds: siteIds,
        allSiteNames: siteNames,
        selectedDates: selectedDate,
      };
    });
    setMasterDataUpdated(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterData]);

  useEffect(() => {
    if (masterDataUpdated && tabChanged) {
      fetchThresholdData();
    }
    setMasterDataUpdated(false);
    setDateUpdated(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterDataUpdated]);

  const showGridDataView = () => {
    let exportFileName = UIText.Tank_Leaks_Report;
    if (selectedTab === LeakTabOptions.DAILY_REPORT) {
      exportFileName = UIText.Tank_Leaks_Daily_Report;
    } else if (selectedTab === LeakTabOptions.MINS_REPORT) {
      exportFileName = UIText.Tank_Leaks_15_Mins_Report;
    }
    return (
      <AppDataGrid
        resetIndex={resetIndex}
        setResetIndex={setResetIndex}
        take={DEFAULT_PAGE_SIZE}
        style={{ height: "60vh", margin: "0 0.5vw 0.5vw 0.5vw" }}
        pageable={true}
        filterable={true}
        data={leaksData}
        columnsToShow={colsToShow(leaksData, selectedTab, onShipToAction)}
        sortable={true}
        exportFileName={exportFileName}
        ref={exportGridRef}
      />
    );
  };

  const showTabContent = () => (
    <div>
      {showGridToolBar()}
      {showGridDataView()}
    </div>
  );

  const showTabComponent = () => {
    const items = [
      {
        label: UIText.Tank_Leaks,
        key: LeakTabOptions.TANK_LEAKS,
      },
      {
        label: UIText.Daily_Report,
        key: LeakTabOptions.DAILY_REPORT,
      },
      {
        label: UIText.One_Five_Mins_Report,
        key: LeakTabOptions.MINS_REPORT,
      },
    ];
    return (
      <TabComponent
        items={items}
        activeIndex={selectedTab}
        onChange={(index) => {
          setSelectedTab(index);
        }}
      />
    );
  };

  const getProductInfo = (productCodes, siteProducts) => {
    if (productCodes?.length) {
      const products = [];
      productCodes?.forEach((code) => {
        const product = siteProducts?.find((item) => item.id === code);
        if (product) {
          products.push(product);
        }
      });
      return products;
    }
    return null;
  };

  const getSelectedProductInfo = (selectedProducts, siteProducts) => {
    if (selectedProducts?.length) {
      const products = [];
      selectedProducts?.forEach((material) => {
        const product = siteProducts?.find(
          (item) => item.text === material.text
        );
        if (product) {
          products.push(product);
        }
      });
      return products;
    }
    return null;
  };

  const getSelectedTankInfo = (selectedTanks, tankGroupsData) => {
    if (selectedTanks?.length) {
      const tanks = [];
      selectedTanks?.forEach((tankItem) => {
        const tank = tankGroupsData?.find((item) => item.id === tankItem.text);
        if (tank) {
          tanks.push(tank);
        }
      });
      return tanks;
    }
    return null;
  };

  const setFilterOptions = (selectedSiteId) => {
    if (selectedSiteId) {
      const siteData = filters?.allSiteIds?.find(
        (site) => site?.globalSiteId === selectedSiteId
      );
      const siteProducts = siteData?.materials?.map((product) => ({
        id: product?.materialNumber,
        text: product?.productName,
        ...product,
      }));
      const productsData = addAllOptions(siteProducts || []);
      let filterProducts = getProductInfo(
        report?.filters?.Product,
        productsData
      );
      if (!filterProducts) {
        filterProducts = getSelectedProductInfo(
          filters?.selectedProducts,
          productsData
        );
      }
      const products =
        filterProducts?.length > 0 ? filterProducts : [productsData[0]];
      const selectedProducts = productsData?.length > 0 ? products : [];

      setFilters((prev) => {
        return {
          ...prev,
          products: productsData,
          selectedProducts: selectedProducts,
        };
      });
      if (siteProducts?.length) {
        setProductSelectedValues(selectedProducts);
      } else {
        setProductSelectedValues([]);
      }
    } else {
      setFilters((prev) => {
        return {
          ...prev,
          products: [],
          selectedProducts: [],
        };
      });
      setProductSelectedValues([]);
    }
  };

  const getTankInfo = (tankCodes, tankGroupsData) => {
    if (tankCodes?.length) {
      const tanks = [];
      tankCodes?.forEach((code) => {
        const tank = tankGroupsData?.find((item) => item.id === code);
        if (tank) {
          tanks.push(tank);
        }
      });
      return tanks;
    }
    return null;
  };

  useEffect(() => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedRegions: regionSelectedValues,
      };
    });

    if (!regionSelectedValues || regionSelectedValues?.length === 0) {
      setFilters((prev) => {
        return {
          ...prev,
          selectedSiteId: [],
          allSiteIds: [],
          allSiteNames: [],
        };
      });
      setShipToSelectedValues([]);
      setSiteNameSelectedValues([]);
    } else {
      const siteIds =
        regionSelectedValues?.length > 0
          ? getShipTos(masterData, regionSelectedValues, false)
          : [];

      const siteNames =
        regionSelectedValues?.length > 0
          ? getSiteNames(masterData, regionSelectedValues, false)
          : [];

      const selectedSiteId = siteIds?.length > 0 ? [siteIds?.[0]] : [];
      const selectedSiteName = siteNames?.length > 0 ? [siteNames?.[0]] : [];
      setFilters((prev) => {
        return {
          ...prev,
          selectedSiteId: selectedSiteId,
          selectedSiteName: selectedSiteName,
          allSiteIds: siteIds,
          allSiteNames: siteNames,
        };
      });
      setCurrentField(DropDownSelection.ShipTo);
      setShipToSelectedValues(selectedSiteId);
      setSiteNameSelectedValues(selectedSiteName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionSelectedValues]);

  useEffect(() => {
    const tankGroups = getTankGroups(
      masterData,
      filters,
      productSelectedValues
    );
    const tankGroupsData =
      tankGroups?.length === 1 ? tankGroups : addAllOptions(tankGroups, true);
    let filterTanks = getTankInfo(report?.filters?.TankGroupId, tankGroupsData);

    if (!filterTanks) {
      filterTanks = getSelectedTankInfo(filters?.selectedTanks, tankGroupsData);
    }

    const tanks = filterTanks?.length > 0 ? filterTanks : [tankGroupsData[0]];
    const selectedTankGroups = tanks?.length > 0 ? tanks : [];
    setFilters((prev) => {
      return {
        ...prev,
        selectedProducts: productSelectedValues,
        tankGroup: tankGroupsData,
        selectedTanks: selectedTankGroups,
      };
    });
    if (productSelectedValues?.length) {
      setTankSelectedValues(selectedTankGroups);
    } else {
      setTankSelectedValues([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSelectedValues]);

  useEffect(() => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedTanks: tankSelectedValues,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tankSelectedValues]);

  useEffect(() => {
    if (currentField === DropDownSelection.ShipTo) {
      if (filters?.allSiteIds?.length) {
        const siteData = filters?.allSiteIds?.find(
          (siteInfo) => siteInfo?.globalSiteId === shipToSelectedValues?.[0]?.id
        );
        setFilters((prev) => {
          return {
            ...prev,
            selectedSiteId: shipToSelectedValues?.[0]?.id,
            selectedSiteName: siteData?.siteName,
          };
        });
      }

      const site = filters?.allSiteNames?.find(
        (siteInfo) => siteInfo?.globalSiteId === shipToSelectedValues?.[0]?.id
      );
      if (site) {
        setSiteNameSelectedValues([site]);
      } else {
        setSiteNameSelectedValues([]);
      }
      setFilterOptions(site?.globalSiteId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipToSelectedValues]);

  const onSiteNameSelectionChange = () => {
    if (filters?.allSiteIds) {
      const siteData = filters?.allSiteIds?.find(
        (siteInfo) => siteInfo?.siteName === siteNameSelectedValues?.[0]?.id
      );
      setFilters((prev) => {
        return {
          ...prev,
          selectedSiteId: siteData?.globalSiteId,
          selectedSiteName: siteData?.siteName,
        };
      });
    }

    const site = filters?.allSiteIds?.find(
      (siteInfo) => siteInfo?.siteName === siteNameSelectedValues?.[0]?.id
    );
    if (site) {
      setShipToSelectedValues([site]);
    } else {
      setShipToSelectedValues([]);
    }
    setFilterOptions(site?.globalSiteId);
  };

  useEffect(() => {
    if (currentField === DropDownSelection.SiteName) {
      onSiteNameSelectionChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteNameSelectedValues]);

  const onShipToSelection = (event) => {
    setCurrentField(DropDownSelection.ShipTo);
    if (event.operation === "delete") {
      setShipToSelectedValues([]);
    } else {
      setShipToSelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.allSiteIds, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const onSiteNameSelection = (event) => {
    setCurrentField(DropDownSelection.SiteName);
    if (event.operation === "delete") {
      setSiteNameSelectedValues([]);
    } else {
      setSiteNameSelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.allSiteNames, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const onProductSelection = (event) => {
    if (event.operation === "delete") {
      setProductSelectedValues([]);
    } else {
      setProductSelectedValues((prev) => {
        const selectedValues = getAllSelectedOptions(event, prev);
        return getMultiSelectTreeValue(filters?.products, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        });
      });
    }
  };

  const onTankSelection = (event) => {
    if (event.operation === "delete") {
      setTankSelectedValues([]);
    } else {
      setTankSelectedValues((prev) => {
        const selectedValues = getAllSelectedOptions(event, prev);
        return getMultiSelectTreeValue(filters?.tankGroup, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        });
      });
    }
  };

  const onDateSelection = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedDates: event.value,
      };
    });
  };

  const fetchMasterData = async () => {
    try {
      setLeaksData([]);
      setShowError(false);
      setFilterDataLoading(true);

      const response = await reportsService.fetchToleranceTrendMasterData(
        {
          country: currentCountryCode,
        },
        true
      );
      setFilterDataLoading(false);
      if (response) {
        setMasterData([...response]);
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: UIText.error,
              icon: true,
            },
            message: UIText.Unable_to_fetch_the_data,
          })
        );
      }
    } catch (error1) {
      dispatch(
        triggerNotification({
          type: {
            style: UIText.error,
            icon: true,
          },
          message: UIText.Server_error_Unable_to_submit_the_request,
        })
      );
      setError(UIText.Server_error_Unable_to_submit_the_request);
      setFilterDataLoading(false);
      setError("");
    }
  };

  const onShipToAction = (props) => {
    const dataItem = props.dataItem;
    if (dataItem) {
      const selectedDate = {
        start: new Date(dataItem.requestDate),
        end: new Date(dataItem.requestDate),
      };
      const site = filters?.allSiteIds?.find(
        (siteInfo) => siteInfo?.globalSiteId === dataItem?.shipTo
      );

      if (site) {
        const product = site?.materials?.find(
          (productInfo) => productInfo?.productName === dataItem?.product
        );

        const selectedProduct = {
          id: product?.materialNumber,
          text: product?.productName,
          ...product,
        };

        const tank = site?.tanks?.find(
          (tankInfo) => tankInfo?.tankGroupId === dataItem?.tankGroup
        );

        const selectedTank = {
          id: tank?.tankGroupId,
          text: tank?.tankGroupId,
          ...tank,
        };

        setFilters((prev) => {
          return {
            ...prev,
            selectedSiteId: dataItem?.shipTo,
            selectedDates: selectedDate,
            selectedProducts: product ? [selectedProduct] : [],
            selectedTanks: tank ? [selectedTank] : [],
          };
        });
        setShipToSelectedValues([site]);
      }
    }

    if (selectedTab === LeakTabOptions.TANK_LEAKS) {
      setSelectedTab(LeakTabOptions.DAILY_REPORT);
    }

    if (selectedTab === LeakTabOptions.DAILY_REPORT) {
      setSelectedTab(LeakTabOptions.MINS_REPORT);
    }
  };

  const getFilter = (refresh) => {
    return refresh ? appliedFilters : filters;
  };

  const getFilteredProducts = (selectedProducts) => {
    return selectedProducts?.length > 0 ? selectedProducts : [UIText.All];
  };

  const getFilteredTanks = (selectedTankIds) => {
    return selectedTankIds?.length > 0 ? selectedTankIds : [UIText.All];
  };

  const getStartDate = (fromDate) => {
    return fromDate && getFormattedDate(fromDate);
  };

  const getEndDate = (toDate) => {
    return toDate && getFormattedDate(toDate);
  };

  const getThresholdResponse = async (payload) => {
    let response = null;
    switch (selectedTab) {
      case LeakTabOptions.TANK_LEAKS:
        response = await reportsService.fetchLeaksData(payload);
        break;

      case LeakTabOptions.DAILY_REPORT:
        response = await reportsService.fetchReconciliationResults(payload);
        break;

      case LeakTabOptions.MINS_REPORT:
        response = await reportsService.fetchReconciliationResults(payload);
        break;
      default:
        response = [];
    }
    return response;
  };

  const fetchThresholdData = async (tabLoaded = false, refresh = false) => {
    const filter = getFilter(refresh);
    const siteId = filter?.allSiteIds?.find(
      (site) => site?.globalSiteId === filter?.selectedSiteId
    )?.localSiteId;
    const selectedTankIds = filter?.selectedTanks?.map((item) => item?.id);
    const fromDate = filter.selectedDates.start;
    const toDate = filter.selectedDates.end;
    const selectedProducts = filter?.selectedProducts?.map(
      (product) => product?.text
    );
    const filteredProducts = getFilteredProducts(selectedProducts);
    const filteredTankGroups = getFilteredTanks(selectedTankIds);

    const startDate = getStartDate(fromDate);
    const endDate = getEndDate(toDate);

    let payload = {
      countryCode: currentCountryCode,
      leakStartDate: startDate,
      leakEndDate: endDate,
    };

    let valuesSelected = fromDate && toDate;

    if (selectedTab !== LeakTabOptions.TANK_LEAKS) {
      payload = {
        countryCode: currentCountryCode,
        startDate: startDate,
        endDate: endDate,
        shipTo: filter?.selectedSiteId,
        product: filteredProducts,
        isDaily: selectedTab === LeakTabOptions.DAILY_REPORT,
      };

      if (isDachCluster()) {
        payload = {
          ...payload,
          tankGroup: filteredTankGroups,
        };
      }

      valuesSelected = valuesSelected && siteId;
    }

    if (valuesSelected) {
      setTabChanged(false);
      setShowError(false);
      setSubmitLoading(true);
      if (!refresh) {
        setAppliedFilters(filter);
      }
      try {
        const response = await getThresholdResponse(payload);

        setSubmitLoading(false);
        setLeaksData(response);
        setResetIndex(!resetIndex);
      } catch (error1) {
        dispatch(
          triggerNotification({
            type: {
              style: UIText.error,
              icon: true,
            },
            message: UIText.Server_error_Unable_to_submit_the_request,
          })
        );
        setError(UIText.Server_error_Unable_to_submit_the_request);
        setSubmitLoading(false);
        setError("");
      }
    } else if (!tabLoaded) {
      setShowError(true);
    }
  };

  useEffect(() => {
    if (selectedTab === LeakTabOptions.MINS_REPORT) {
      setFilters((prev) => {
        const selectedDate = report?.filters?.RequestDate
          ? {
              start: report.filters.RequestDate?.startDate,
              end: report.filters.RequestDate?.endDate,
            }
          : {
              start: prev.selectedDates.start,
              end: prev.selectedDates.start,
            };
        return {
          ...prev,
          selectedDates: selectedDate,
        };
      });
    }
    setTabChanged(true);
    setLeaksData([]);
    if (selectedTab !== LeakTabOptions.MINS_REPORT) {
      setShowError(false);
      if (!dateUpdated) {
        fetchThresholdData(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    if (selectedTab === LeakTabOptions.MINS_REPORT && datePickerClosed) {
      fetchThresholdData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedDates]);

  const handleDatePickerClose = () => {
    setDatePickerClosed(true);
  };

  const handleReportLink = () => {
    const products = filters.selectedProducts?.map((item) => item.id);
    const tanks = filters.selectedTanks?.map((item) => item.id);

    const { userScreenList } = store.getState().user;
    const module = userScreenList?.find(
      (item) => item.id === SCREEN_IDS.LEAK_DETECTION
    );
    const subModule = module?.children?.find(
      (item) => item.id === SCREEN_IDS.RECONCILIATION_REPORTS
    );
    const reportItem = subModule?.children?.find(
      (item) => item.id === SCREEN_IDS.FIFTEEN_MINS_RECONCILIATION_REPORT
    );

    let selectedReport = {
      moduleId: subModule.parentId,
      submoduleId: reportItem.parentId,
      pageId: reportItem.id,
      pageName: reportItem.pageName,
      reportId: reportItem.reportId,
      title: reportItem.title,
      tableName: reportItem.tableName,
      columnName: reportItem?.columnName,
      filters: {
        GlobalSiteId: [filters.selectedSiteId],
        Product: products,
        TankGroupId: tanks,
        RequestDate: {
          startDate: filters.selectedDates?.start,
          endDate: filters.selectedDates?.end,
        },
      },
    };
    if (selectedTab === LeakTabOptions.DAILY_REPORT) {
      const dailyReportItem = subModule?.children?.find(
        (item) => item.id === SCREEN_IDS.DAILY_RECONCILIATION_REPORT
      );
      selectedReport = {
        moduleId: subModule.parentId,
        submoduleId: dailyReportItem.parentId,
        pageId: dailyReportItem.id,
        pageName: dailyReportItem.pageName,
        reportId: dailyReportItem.reportId,
        title: dailyReportItem.title,
        tableName: dailyReportItem.tableName,
        columnName: dailyReportItem?.columnName,
        filters: {
          GlobalSiteId: [filters.selectedSiteId],
          Product: products,
          TankGroupId: tanks,
          RequestDate: {
            startDate: filters.selectedDates?.start,
            endDate: filters.selectedDates?.end,
          },
        },
      };
    }
    localStorage.setItem("reportsData", JSON.stringify(selectedReport));
    store.dispatch(setSelectedReport(selectedReport));
  };

  const startDateInput = (propValues) => (
    <StartDateInput {...propValues} showError={showError} />
  );

  const endDateInput = (propValues) => (
    <EndDateInput {...propValues} showError={showError} />
  );

  const exportData = async () => {
    setExporting(true);
    exportGridRef?.current?.exportDataInExcel &&
      (await exportGridRef.current.exportDataInExcel());
    setExporting(false);
  };

  const showHeaderFilters = () => {
    return (
      <div className="header-container">
        <div className="filters-container">
          <div className="filter-date">
            <DateRangePickerComponent
              defaultValue={filters.selectedDates}
              value={filters.selectedDates}
              startDateInput={startDateInput}
              endDateInput={endDateInput}
              onChange={onDateSelection}
              onClose={handleDatePickerClose}
              onOpen={() => {
                setDatePickerClosed(false);
              }}
              max={getDaysBeforeDate(1)}
            />
          </div>

          {selectedTab !== LeakTabOptions.TANK_LEAKS && (
            <div className="filters-container">
              <span className="filter">
                <label htmlFor="shipTo">{UIText.ShipTo}</label>
                <MultiSelectionDropDown
                  data={filters.allSiteIds}
                  values={shipToSelectedValues}
                  onChange={onShipToSelection}
                  filterable={true}
                  showError={showError}
                  customStyle={{ width: "8vw", minHeight: "3vh" }}
                  maxSelection={1}
                />
              </span>
              <span className="filter">
                <label htmlFor="shipTo">{UIText.Site_Name}</label>
                <MultiSelectionDropDown
                  data={filters.allSiteNames}
                  values={siteNameSelectedValues}
                  onChange={onSiteNameSelection}
                  filterable={true}
                  showError={showError}
                  customStyle={{
                    width: "15vw",
                    minHeight: "3vh",
                  }}
                  maxSelection={1}
                />
              </span>
              <span className="filter">
                <label htmlFor="region">{UIText.Material_No}</label>
                <MultiSelectionDropDown
                  data={filters.products}
                  values={productSelectedValues}
                  onChange={onProductSelection}
                  filterable={false}
                  placeholder="Select ..."
                  customStyle={{ width: "10vw", minHeight: "3vh" }}
                />
              </span>
              {isDachCluster() && (
                <span className="filter">
                  <label htmlFor="region">{UIText.Tank_Group}</label>
                  <MultiSelectionDropDown
                    data={filters.tankGroup}
                    values={tankSelectedValues}
                    onChange={onTankSelection}
                    filterable={false}
                    placeholder="Select ..."
                    customStyle={{ width: "7vw", minHeight: "3vh" }}
                  />
                </span>
              )}
            </div>
          )}
        </div>
        <span className="filter-button">
          <Button
            className="get-data-btn"
            onClick={() => {
              fetchThresholdData();
              const selectedReport = {
                ...report,
                filters: null,
              };
              localStorage.setItem(
                "reportsData",
                JSON.stringify(selectedReport)
              );
              store.dispatch(setSelectedReport(selectedReport));
            }}
            disabled={filterDataLoading}
          >
            {filterDataLoading ? UIText.Fetching : UIText.Get_data}
          </Button>
        </span>
        <span className="filter-button">
          <Button
            className="refresh-btn"
            onClick={() => {
              fetchThresholdData(false, true);
            }}
            disabled={!appliedFilters}
          >
            {UIText.Refresh}
          </Button>
        </span>
        <span className="filter-button">
          <Button
            className="exportButton"
            onClick={exportData}
            disabled={exporting || !leaksData?.length}
          >
            {exporting ? "Exporting..." : "Export"}
          </Button>
        </span>
        {selectedTab !== LeakTabOptions.TANK_LEAKS && (
          <span className="filter-button">
            <Button
              className="get-data-btn"
              onClick={handleReportLink}
              disabled={filterDataLoading || leaksData?.length === 0}
            >
              {UIText.More}
            </Button>
          </span>
        )}
      </div>
    );
  };

  const showGridToolBar = () => {
    return <div className="gridToolbar">{showHeaderFilters()}</div>;
  };

  return (
    <div className="tank-leaks-screen-container data-grid">
      <div className="row ">
        {showTabComponent()}
        {error && <span>{error}</span>}
        <div className="data-grid-container">
          {showTabContent()}
          {submitLoading && <LoadingPanel message={UIText.Fetching_the_data} />}
          {filterDataLoading && (
            <LoadingPanel message={UIText.Fetching_the_config_data} />
          )}
        </div>
      </div>
    </div>
  );
};

export { TankLeaksScreen };
