import { Button, DropDownList } from "@progress/kendo-react-all";
import { MultiSelectTreeDropdown } from "../../../../components/multi-select-tree/main";
import { userService } from "../../../../../services/user";
import { triggerNotification } from "../../../../../state-management/actions";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { USER_ROLES } from "../common-utils";
import { UIText } from "../label-constants";

const EditRoleDropdown = (props) => {
  const { handelChange, roles, selectedRole } = props;
  const permissibleRoles = roles.map((role) => role.RoleName);
  return (
    <DropDownList
      className="details-container-dropdown"
      value={selectedRole}
      onChange={handelChange}
      data={permissibleRoles}
    />
  );
};

const ActivitySelection = (props) => {
  const { isActive, handelChange } = props;

  return (
    <DropDownList
      className="details-container-dropdown"
      value={isActive ? UIText.Active : UIText.In_Active}
      onChange={handelChange}
      data={[UIText.Active, UIText.In_Active]}
    />
  );
};

const UserEditDetailsContainer = (props) => {
  const { dataItem, roles, siteIds } = props;
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(dataItem["IsActive"] === "Y");
  const [selectedRole, setSelectedRole] = useState(dataItem["RoleName"]);
  const [selectedRoleId, setSelectedRoleId] = useState(dataItem["RoleId"]);
  const saveChanges = async () => {
    dispatch(
      triggerNotification({
        type: {
          style: UIText.info,
          icon: true,
        },
        message: UIText.Updating_the_values,
      })
    );
    const {
      AccessControl,
      Country,
      IsActive,
      RoleId,
      RoleName,
      UniqueUserId,
      UserEmail,
      UserFirstName,
      UserLastName,
      UserType,
    } = dataItem;
    const payload = {
      AccessControl: RoleName === "Global Admin" ? [Country] : AccessControl,
      Country: Country,
      IsActive: IsActive,
      RoleId: RoleId.toString(),
      UserRole: RoleName,
      UniqueUserId: UniqueUserId,
      UserEmail: UserEmail,
      UserFirstName: UserFirstName,
      UserLastName: UserLastName,
      UserType: UserType,
    };
    const response = await userService.updateUserRecord(payload);
    if (response) {
      dispatch(
        triggerNotification({
          type: {
            style: UIText.success,
            icon: true,
          },
          message: UIText.Record_Updated_Successfully,
        })
      );
    }
  };

  const handelDropDownChange = (e) => {
    const roleName = e.value;
    const roleId = roles.filter((role) => role.RoleName === roleName)[0].RoleId;

    dataItem["RoleId"] = roleId;
    dataItem["RoleName"] = roleName;
    setSelectedRole(roleName);
    setSelectedRoleId(roleId);
  };

  const handelActivityChange = (e) => {
    const selectedValue = e.value;
    const isActiveVal = selectedValue === "Active";
    setIsActive(isActiveVal);
    dataItem["IsActive"] = isActiveVal ? "Y" : "N";
  };

  const fields = [
    { fieldName: "Country", title: UIText.Country, editable: false },
    {
      fieldName: "InternalUserId",
      title: UIText.Internal_UID,
      editable: false,
    },
    {
      fieldName: "IsActivatedInCIPM",
      title: UIText.CIPM_Activation,
      editable: false,
    },
    {
      fieldName: "IsActive",
      title: UIText.Is_Active,
      editable: true,
      component: (
        <ActivitySelection
          isActive={isActive}
          handelChange={handelActivityChange}
        />
      ),
    },
    { fieldName: "UniqueUserId", title: UIText.UUID, editable: false },
    {
      fieldName: "RoleName",
      title: UIText.Role,
      editable: true,
      component: (
        <EditRoleDropdown
          selectedRole={selectedRole}
          roles={roles}
          handelChange={handelDropDownChange}
        />
      ),
    },
  ];

  const gridData = [
    {
      text: dataItem["Country"],
      id: 0,
      items: siteIds.map((item, index) => {
        const currentId = index + 1;
        return { text: item, id: currentId };
      }),
    },
  ];

  const handelSiteIdChange = (newSiteIds) => {
    const newIds = newSiteIds.map((site) => site.text);
    dataItem["AccessControl"] = newIds.includes("DE") ? ["DE"] : newIds;
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6">
          <table>
            <tbody>
              {fields.map((item) => (
                <tr key={item.title}>
                  <td>{item.title}</td>
                  <td>
                    {item.editable ? item.component : dataItem[item.fieldName]}
                  </td>
                </tr>
              ))}
              <tr>
                <td>{UIText.Site_Access}</td>
                <td>
                  {selectedRoleId === USER_ROLES.GLOBAL ? (
                    UIText.This_user_has_all_access
                  ) : (
                    <MultiSelectTreeDropdown
                      className="details-container-dropdown"
                      preSelectionEnabled={true}
                      dataItem={dataItem}
                      data={gridData}
                      onChange={handelSiteIdChange}
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-md-6">
          <h4>{UIText.Details}</h4>
          <p>{UIText.Edit_the_user_and_click_Save_to_consolidate_your_changes}</p>
          <Button
            className="user-management-edit-user-screen-btn-submit"
            onClick={saveChanges}
          >
            {UIText.Save}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserEditDetailsContainer;
