import React, { useEffect } from "react";
import { CircularGauge } from "@progress/kendo-react-gauges";
import PropTypes from "prop-types";

const colors = [
  {
    to: 25,
    color: "#0058e9",
  },
  {
    from: 25,
    to: 50,
    color: "#37b400",
  },
  {
    from: 50,
    to: 75,
    color: "#ffc000",
  },
  {
    from: 75,
    color: "#f31700",
  },
];

const AppCircularGauge = props => {
  const [graphValue, setGraphValue] = React.useState(
    props?.value ? props.value : 0
  );
  const arcOptions = {
    value: graphValue,
    colors,
  };

  useEffect(() => {
    setGraphValue(props.value);
  }, [props.value]);

  const centerRenderer = value => <h3>{value}</h3>;

  return (
    <CircularGauge
      style={props.style}
      {...arcOptions}
      centerRender={centerRenderer}
    />
  );
};

AppCircularGauge.prototypes = {
  value: PropTypes.number,
  style: PropTypes.object,
};

export { AppCircularGauge };
