import React, { useEffect, useRef, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import "./site-information.scss";
import { Loader } from "@progress/kendo-react-all";
import { triggerNotification } from "../../../../../state-management/actions";
import { useDispatch } from "react-redux";
import { siteService } from "../../../../../services/sites";
import ConfirmPopup from "../../../../components/confirmation-popup/confirmation-popup";
import { DECIMAL, NUMBERS } from "../common-utils";
import { LabelField } from "./site-information-utils";
import { UIText } from "../label-constants";
import { TextInputField } from "../common-grid-cells";

const onTextInputChange = (props) => {
  const { event, selectedField } = props;
  const position = event.target.element?.selectionStart;
    props?.setCursorPosition(position);
    let inputValue = event.target.value;
    if (props?.allowOnlyNumbers && inputValue?.length > 0) {
      const validNumber = new RegExp("^[0-9]+$");
      inputValue = validNumber.test(inputValue)
        ? inputValue
        : props?.fields?.[selectedField];
    }
    props?.setSelectedItem(selectedField);
    let workCapacity = props?.fields?.workCapacity;
    if (selectedField === "capacity") {
      workCapacity = parseInt(inputValue * DECIMAL.POINT_NINE_FIVE);
    }
    props?.setFields((prev) => {
      return {
        ...prev,
        [selectedField]: inputValue,
        workCapacity: workCapacity,
      };
    });
}

const SiteTankPopupDetails = (props) => {
  const siteItem = props?.currentSiteItem;
  const siteDetailsItem = props?.selectedItem;
  const [showError, setShowError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [currentOffSet, setCurrentOffSet] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState(null);

  const [fields, setFields] = useState({
    materialNo: siteDetailsItem?.materialNo,
    productCode: siteDetailsItem?.productCode,
    productName: siteDetailsItem?.additionalProductInfo,
    tankGroup: siteDetailsItem?.tankGroup,
    tankName:
      siteDetailsItem?.deviceID ||
      siteDetailsItem?.tankName?.replace(/\D/g, ""),
    capacity: siteDetailsItem?.capacity ? `${siteDetailsItem?.capacity}` : "",
    workCapacity: siteDetailsItem?.capacity
      ? `${parseInt(siteDetailsItem?.capacity * DECIMAL.POINT_NINE_FIVE)}`
      : "",
  });

  useEffect(() => {
    inputRef?.current?.focus();
    setTimeout(() => {
      inputRef?.current?.element?.setSelectionRange(
        cursorPosition,
        cursorPosition
      );
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  useEffect(() => {}, [fields?.selectedShipTo]);

  const showHeader = () => {
    const title = props?.isAddTank
      ? UIText.Add_a_New_Tank_Details
      : UIText.Edit_Tank_Details;
    return (
      <div className="deliveryHeader">
        <div className="title">{title}</div>
      </div>
    );
  };

  const showContent = () => {
    return (
      <div className="deliveryContent">
        <div className="leftContainer">
          {props?.isAddTank ? (
            <TextInputField
              label={UIText.Material_No}
              selectedField="materialNo"
              mandatory
              allowOnlyNumbers
              maxLength={10}
              fields={fields}
              setCursorPosition={setCursorPosition}
              setSelectedItem={setSelectedItem}
              setFields={setFields}
              selectedItem={selectedItem}
              showError={showError}
              inputRef={inputRef}
              onChange={onTextInputChange}
            />
          ) : (
            <LabelField label={UIText.Material_No} value={fields?.materialNo} />
          )}

          {props?.isAddTank ? (
            <TextInputField
              label={UIText.Product}
              selectedField="productCode"
              mandatory
              allowOnlyNumbers
              maxLength={10}
              fields={fields}
              setCursorPosition={setCursorPosition}
              setSelectedItem={setSelectedItem}
              setFields={setFields}
              selectedItem={selectedItem}
              showError={showError}
              inputRef={inputRef}
              onChange={onTextInputChange}
            />
          ) : (
            <LabelField label={UIText.Product} value={fields?.productCode} />
          )}

          {props?.isAddTank ? (
            <TextInputField
              label={UIText.Product_Name}
              selectedField="productName"
              mandatory
              fields={fields}
              setCursorPosition={setCursorPosition}
              setSelectedItem={setSelectedItem}
              setFields={setFields}
              selectedItem={selectedItem}
              showError={showError}
              inputRef={inputRef}
              onChange={onTextInputChange}
            />
          ) : (
            <LabelField
              label={UIText.Product_Name}
              value={fields?.productName}
            />
          )}
          {props?.isAddTank ? (
            <TextInputField
              label={UIText.Tank_Group}
              selectedField="tankGroup"
              mandatory
              maxLength={10}
              fields={fields}
              setCursorPosition={setCursorPosition}
              setSelectedItem={setSelectedItem}
              setFields={setFields}
              selectedItem={selectedItem}
              showError={showError}
              inputRef={inputRef}
              onChange={onTextInputChange}
            />
          ) : (
            <LabelField label={UIText.Tank_Group} value={fields?.tankGroup} />
          )}
        </div>
        <div className="rightContainer">
          {props?.isAddTank ? (
            <TextInputField
              label={UIText.Tank}
              selectedField="tankName"
              mandatory
              maxLength={10}
              fields={fields}
              setCursorPosition={setCursorPosition}
              setSelectedItem={setSelectedItem}
              setFields={setFields}
              selectedItem={selectedItem}
              showError={showError}
              inputRef={inputRef}
              onChange={onTextInputChange}
            />
          ) : (
            <LabelField label={UIText.Tank} value={fields?.tankName} />
          )}
          <LabelField
            label={UIText.Operational_Capacity}
            value={fields?.workCapacity}
          />
          <TextInputField
            label={UIText.Capacity}
            selectedField="capacity"
            mandatory
            allowOnlyNumbers
            maxLength={7}
            fields={fields}
            setCursorPosition={setCursorPosition}
            setSelectedItem={setSelectedItem}
            setFields={setFields}
            selectedItem={selectedItem}
            showError={showError}
            inputRef={inputRef}
            onChange={onTextInputChange}
          />
        </div>
      </div>
    );
  };

  const isValid = () => {
    const valid1 =
      fields?.materialNo?.toString()?.length > 0 &&
      fields?.productCode?.toString()?.length > 0 &&
      fields?.productName?.toString()?.length > 0;

    const valid2 =
      fields?.tankGroup?.toString()?.length > 0 &&
      fields?.tankName?.toString()?.length > 0 &&
      fields?.capacity?.toString()?.length > 0;

    const valid3 = fields?.workCapacity?.toString()?.length > 0;

    const allValid = valid1 && valid2 && valid3;

    setShowError(!allValid);
    return allValid;
  };

  const showSubmitButton = () => {
    return (
      <div className="tank-footer-container">
        <div className="info-label">
          {`NOTE: Operational Capacity is 95% of Capacity (${fields.capacity} * 0.95 = ${fields.workCapacity})`}
        </div>
        <div className="submit-btn-container">
          <Button
            className="submit-btn"
            onClick={(event) => {
              if (isValid()) {
                const x = event.clientX;
                const y = event.clientY;
                const offset = {
                  left: x,
                  top: y,
                };
                setCurrentOffSet(offset);
                setShowConfirmationPopup(true);
              }
            }}
          >
            {submitting ? UIText.Submitting : UIText.Save}
          </Button>
          <Button
            className="close-btn"
            onClick={() => {
              props?.cancel();
            }}
          >
            {UIText.Cancel}
          </Button>
        </div>
      </div>
    );
  };

  const createPayload = () => {
    let tankInfo = [];
    if (props?.isAddTank) {
      const newTank = {
        additionalProductInfo: fields?.productName,
        capacity: fields?.capacity,
        materialNo: fields?.materialNo,
        productCode: fields?.productCode,
        tankGroup: fields?.tankGroup,
        tankName: fields?.tankName,
        workCapacity: fields?.workCapacity,
        deviceID: fields?.tankName,
      };
      if (siteItem?.TankInfo?.length > 0) {
        tankInfo = [...siteItem.TankInfo, newTank];
      } else {
        tankInfo = [newTank];
      }
    } else {
      tankInfo = siteItem?.TankInfo?.map((tank) => {
        let tankDetails = tank;
        if (tank?.deviceID === siteDetailsItem?.deviceID) {
          tankDetails = {
            ...tank,
            additionalProductInfo: fields?.productName,
            capacity: fields?.capacity,
            materialNo: fields?.materialNo,
            productCode: fields?.productCode,
            tankGroup: fields?.tankGroup,
            tankName: fields?.tankName,
            workCapacity: fields?.workCapacity,
          };
        }
        return tankDetails;
      });
    }

    const payload = {
      ...siteItem,
      TankInfo: tankInfo,
    };

    return payload;
  };

  const saveChanges = async () => {
    setShowConfirmationPopup(false);
    setSubmitting(true);
    const payload = createPayload();
    const response = await siteService.updateSiteInfo(
      siteItem?.Country,
      siteItem?.GlobalSiteId,
      payload
    );
    if (
      response?.status >= NUMBERS.TWO_HUNDRED &&
      response?.status < NUMBERS.THREE_HUNDRED
    ) {
      dispatch(
        triggerNotification({
          type: {
            style: UIText.success,
            icon: true,
          },
          message: response?.data?.message || response?.data,
        })
      );
      props?.save(payload);
    } else {
      dispatch(
        triggerNotification({
          type: {
            style: UIText.error,
            icon: true,
          },
          message: UIText.Failed_to_save_site_information,
        })
      );
    }
    setSubmitting(false);
  };

  return (
    <div className="popupContainer">
      <div className="deliveryForm">
        {showHeader()}
        {showContent()}
        {showSubmitButton()}
        {showConfirmationPopup && (
          <ConfirmPopup
            offset={currentOffSet}
            position="top"
            isOpen={showConfirmationPopup}
            title={UIText.Are_you_sure_you_want_to_update_the_site_information}
            message={UIText.This_change_would_impact_the_existing_report_data}
            saveAction={() => {
              saveChanges();
            }}
            cancelAction={(e) => {
              if (e.target.textContent !== "YES") {
                setShowConfirmationPopup(false);
              }
            }}
          />
        )}

        {submitting && (
          <div className="loaderContainer">
            <Loader
              size="medium"
              themeColor="warning"
              type="converging-spinner"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { SiteTankPopupDetails };
