import { httpService } from "./http";
import { loggerService } from "../services/logger";
import { API } from "./utils";

class ManualDataService {
  async fetchStockData(payload) {
    const URL = `${API.manualData}/stockData`;
    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async saveManualDataUploadChanges(payload) {
    const URL = `${API.manualData}/manualdata`;
    try {
      const response = await httpService.put(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async fetchClusterDetails() {
    const URL = `${API.clusterDetails}`;
    try {
      const response = await httpService.get(URL);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

}

loggerService.dev("Creating manual Data Service");
const manualDataService = new ManualDataService();

loggerService.dev("Exporting Sites Service");
export { manualDataService };
