import React from "react";
const Tile = (props) => {
  return (
    <div
      className="col-lg-3 col-xl-3 col-md-3 col-sm-3 col-xs-3 tile-row"
      role="button"
      tabIndex="0"
      onClick={props.onTileClick}
    >
      <div className="tile">
        <div className="header">
          <h5>{props?.tileData?.title}</h5>
        </div>
        <div className="body">
          <h6>{props?.tileData?.description?.toString()}</h6>
        </div>
      </div>
    </div>
  );
};

export { Tile };
