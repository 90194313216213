import log from 'loglevel';
const isDevelopment = process.env.NODE_ENV === 'development';
// Set default log level
log.setLevel(isDevelopment ? 'debug' : 'warn');
const loggerService = {
  log: (...args) => log.debug(...args),
  info: (...args) => log.info(...args),
  warn: (...args) => log.warn(...args),
  error: (...args) => log.error(...args),
  setLevel: (level) => log.setLevel(level), 
  dev: (...args) => log.debug(...args),
  environment: () => console.clear(),
  clearConsole: () => console.clear(),
}
loggerService.dev("LoggerService Created");

loggerService.dev("Exporting LoggerService");
export { loggerService };
