import React, { useEffect, useRef, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import "./account-for-deliveries.scss";
import { DropDownList, Loader } from "@progress/kendo-react-all";
import { reportsService } from "../../../../../services/reports";
import { triggerNotification } from "../../../../../state-management/actions";
import { error, getFormattedDate } from "../../../../../services/utils";
import {
  getPlantValues,
  getRegionForSiteId,
  getRegionSiteIds,
  getSlocValues,
} from "./account-for-deliveries-utils";
import {
  getMaterials,
  getShipTos,
  getTankListForMaterial,
  getYesterdayDate,
} from "../../reportUtils";
import { useDispatch, useSelector } from "react-redux";
import { SearchableDropdown } from "../../../../components/searchable-dropdown/searchable-dropdown";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import { isDachCluster } from "../common-utils";
import { UIText } from "../label-constants";
import { DateField, TextInputField } from "../common-grid-cells";

const TimeField = (props) => {
  const defaultValue = new Date();
  const mandatory = props?.mandatory;
  defaultValue.setHours(0, 0, 0, 0);
  const selectedField = `${props?.selectedField}`;
  const selectedValue = props?.fields?.[selectedField];

  const min =
    props?.type === "start"
      ? props?.fields?.deliveryStartTime || defaultValue
      : defaultValue;
  return (
    <div className="deliveryRow">
      <span className="dateLbl">
        {props?.label}
        {mandatory && <span style={{ color: "red" }}>*</span>}
      </span>
      <span className="dateVal">
        <TimePicker
          format="HH:mm"
          placeholder="HH:mm"
          min={min}
          disabled={props?.disabled}
          defaultValue={defaultValue}
          value={selectedValue}
          onChange={(e) => {
            if (e.nativeEvent.type === "click") {
              props?.onTimeChange({
                ...e,
                selectedField,
                defaultValue,
              });
            } else if (!e.target.value) {
              props?.onTimeChange({
                ...e,
                type: "ignore",
                selectedField,
                defaultValue,
              });
            }
          }}
        />
      </span>
    </div>
  );
};

const DropDownField = (props) => {
  const selectedField = `${props?.selectedField}`;
  const value = props?.fields?.[selectedField];
  const selectedValue = value?.length > 0 ? value?.[0] : null;
  const field = `${props?.field}`;
  const data = props?.fields?.[field]?.map((item) => item?.text);
  const filterable = props?.filterable;
  const errorClassName =
    props?.showError &&
    (selectedValue?.id === UIText.Select || !(selectedValue?.id?.length > 0))
      ? "errorClassName"
      : "";

  const handleSearchDropDownSelect = (event) => {
    props?.setSelectedItem(selectedField);
    props?.setFields((prev) => {
      const selectedOption = props?.fields?.[field]?.find(
        (option) => option?.text === event?.value?.text
      );
      if (selectedOption) {
        return {
          ...prev,
          [selectedField]: [selectedOption],
        };
      }
      return prev;
    });
  };

  const handleDropDownListSelect = (event) => {
    props?.setSelectedItem(selectedField);
    props?.setFields((prev) => {
      return {
        ...prev,
        [selectedField]: [event?.value],
      };
    });
  };

  return (
    <div className="deliveryRow">
      <span className="deliveryLbl">
        {props?.label}
        {props?.mandatory && <span style={{ color: "red" }}>*</span>}
      </span>
      <span className="deliveryVal">
        {filterable ? (
          <SearchableDropdown
            data={data}
            textField="text"
            dataItemKey="id"
            filterable={true}
            onSelect={handleSearchDropDownSelect}
            value={{ text: selectedValue?.text }}
            style={{
              width: "15vw",
              marginRight: "1vw",
              fontSize: "10px",
            }}
          />
        ) : (
          <DropDownList
            data={props?.fields?.[`${props?.field}`]}
            textField="text"
            dataItemKey="id"
            className={errorClassName}
            onChange={handleDropDownListSelect}
            value={selectedValue}
            style={{
              width: "15vw",
              marginRight: "1vw",
              fontSize: "10px",
            }}
          />
        )}
      </span>
    </div>
  );
};

const AddDelivery = (props) => {
  const filters = props?.filters;
  const filterData = props?.filterData;

  const defaultTank = {
    id: UIText.Select,
    text: UIText.Select,
  };

  const getDefaultShipTo = () => {
    return filters?.selectedShipTo?.[0]?.id === "all" ||
      !filters?.selectedShipTo
      ? null
      : [filters?.selectedShipTo?.[0]];
  };

  const getDefaultRegion = () => {
    let regions = [];
    if (
      filters?.selectedRegions?.[0]?.id === "all" ||
      !filters?.selectedRegions
    ) {
      regions = selectedShipTo
        ? getRegionForSiteId(filterData, selectedShipTo?.[0]?.id)
        : null;
    } else {
      regions = [filters?.selectedRegions?.[0]];
    }
    return regions;
  };

  const getDefaultSlocs = () => {
    return filters?.selectedSlocs?.[0]?.id === "all" || !filters?.selectedSlocs
      ? null
      : [filters?.selectedSlocs?.[0]];
  };

  const getDefaultPlants = () => {
    return filters?.selectedPlants?.[0]?.id === "all" ||
      !filters?.selectedPlants
      ? null
      : [filters?.selectedPlants?.[0]];
  };

  const selectedShipTo = getDefaultShipTo();
  const selectedRegion = getDefaultRegion();
  const selectedSlocs = getDefaultSlocs();
  const selectedPlants = getDefaultPlants();

  const [showError, setShowError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const removeAllItem = (items) => {
    const filteredItems = items?.filter(
      (item) => item?.id?.toLowerCase() !== "all" || item?.text !== "All"
    );

    return filteredItems;
  };

  const user = useSelector((state) => state?.user?.user);

  const commentOptions = props?.commentOptions?.map((option) => ({
    id: option,
    text: option,
  }));

  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState(null);
  const [fields, setFields] = useState({
    regions: removeAllItem(filters?.regions),
    selectedRegions:
      selectedRegion ||
      (filters?.regions?.length > 1 ? [filters?.regions?.[1]] : []),
    shipTos: selectedRegion
      ? getRegionSiteIds(filterData, selectedRegion?.[0]?.id)
      : removeAllItem(filters?.shipTos),
    selectedShipTo:
      selectedShipTo ||
      (filters?.shipTos?.length > 1 ? [filters?.shipTos?.[1]] : []),
    slocs: removeAllItem(filters?.slocs),
    selectedSlocs:
      selectedSlocs ||
      (filters?.slocs?.length > 1 ? [filters?.slocs?.[1]] : []),
    materials: removeAllItem(filters?.materials),
    selectedMaterial: [defaultTank],

    plants: removeAllItem(filters?.plants),
    selectedPlants:
      selectedPlants ||
      (filters?.plants?.length > 1 ? [filters?.plants?.[1]] : []),
    tanks: [],
    selectedTank: [defaultTank],
    documentDate: null,
    reportDate: new Date(),
    calculatedDelivery: null,
    confirmedDelivery: null,
    difference: null,
    outBoundDeliveryId: null,
    selectedComments: commentOptions?.length > 0 ? [commentOptions?.[0]] : [],
    commentOptions: commentOptions,
    deliveryStartTime: null,
    deliveryEndTime: null,
    startTemperature: null,
    endTemperature: null,
  });

  useEffect(() => {
    inputRef?.current?.focus();
    setTimeout(() => {
      inputRef?.current?.element?.setSelectionRange(
        cursorPosition,
        cursorPosition
      );
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  useEffect(() => {
    if (selectedItem) {
      const shipTos =
        fields.selectedRegions?.length > 0
          ? getShipTos(filterData, fields?.selectedRegions, false)
          : null;
      const shipTo =
        filters.selectedRegions && shipTos?.length > 0 ? [shipTos?.[0]] : [];
      setFields((prev) => {
        return {
          ...prev,
          shipTos: shipTos,
          selectedShipTo: shipTo,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields?.selectedRegions]);

  useEffect(() => {
    if (selectedItem) {
      const plants = getPlantValues(filterData, fields, false);
      const plant = plants?.length > 0 ? [plants[0]] : [];
      const slocs = getSlocValues(filterData, fields);
      const sloc = slocs?.length > 0 ? [slocs[0]] : [];
      const materials =
        fields.selectedShipTo?.length > 0
          ? getMaterials(filterData, fields, false, false)
          : [];
      const tanks =
        fields.selectedShipTo?.length > 0
          ? getTankListForMaterial(filterData, fields, false)
          : [];
      setFields((prev) => {
        return {
          ...prev,
          plants: plants,
          selectedPlants: plant,
          slocs: slocs,
          selectedSlocs: sloc,
          materials: materials,
          selectedMaterial: [defaultTank],
          tanks: tanks,
          selectedTank: [defaultTank],
        };
      });
    } else {
      const tanks =
        fields.selectedShipTo?.length > 0
          ? getTankListForMaterial(filterData, fields, false)
          : [];
      setFields((prev) => {
        return {
          ...prev,
          tanks: tanks,
          selectedTank: [defaultTank],
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields?.selectedShipTo]);

  useEffect(() => {
    const tanks =
      fields.selectedShipTo?.length > 0
        ? getTankListForMaterial(filterData, fields, false)
        : [];
    setFields((prev) => {
      return {
        ...prev,
        tanks: tanks,
        selectedTank: [defaultTank],
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields?.selectedMaterial]);

  useEffect(() => {
    setFields((prev) => {
      const diff = prev?.calculatedDelivery - prev?.confirmedDelivery;
      return {
        ...prev,
        difference: diff,
      };
    });
  }, [fields?.calculatedDelivery, fields?.confirmedDelivery]);

  const onTimeChange = (propValues) => {
    if (propValues?.type === "ignore") {
      setFields((prev) => {
        return {
          ...prev,
        };
      });
    } else {
      const date = propValues.target.value
        ? new Date(propValues?.target.value)
        : propValues?.defaultValue;
      setFields((prev) => {
        setSelectedItem(propValues?.selectedField);
        return {
          ...prev,
          [propValues?.selectedField]: date,
        };
      });
    }
  };

  const onDateChange = (propValues) => {
    const { event, selectedField } = propValues;
    const date = new Date(event?.value);
    setFields((prev) => {
      setSelectedItem(selectedField);
      return {
        ...prev,
        [selectedField]: date,
      };
    });
  };

  const onTextInputChange = (propValues) => {
    const { event, selectedField } = propValues;
    const position = event.target.element?.selectionStart;
    setCursorPosition(position);
    const inputValue = event.target.value;
    const validNumber = new RegExp(/^\d*\.?\d*$/);
    const newValue = validNumber.test(inputValue)
      ? inputValue
      : fields?.[selectedField];
    setSelectedItem(selectedField);
    setFields((prev) => {
      return {
        ...prev,
        [selectedField]: newValue,
      };
    });
  };

  const showHeader = () => {
    return (
      <div className="deliveryHeader">
        <div className="title">{UIText.Add_New_Delivery}</div>
        <div className="close-btn-container">
          <Button
            className="close-btn"
            onClick={() => {
              props?.cancelDelivery();
            }}
          >
            {UIText.Close}
          </Button>
        </div>
      </div>
    );
  };

  const showContent = () => {
    return (
      <div className="deliveryContent">
        <DropDownField
          label={UIText.Region}
          field="regions"
          selectedField="selectedRegions"
          filterable
          fields={fields}
          showError={showError}
          setSelectedItem={setSelectedItem}
          setFields={setFields}
        />
        <DropDownField
          label={UIText.ShipTo}
          field="shipTos"
          selectedField="selectedShipTo"
          mandatory
          filterable
          fields={fields}
          showError={showError}
          setSelectedItem={setSelectedItem}
          setFields={setFields}
        />
        <DropDownField
          label={UIText.Plant}
          field="plants"
          selectedField="selectedPlants"
          fields={fields}
          showError={showError}
          setSelectedItem={setSelectedItem}
          setFields={setFields}
        />
        <DropDownField
          label={UIText.SLOC}
          field="slocs"
          selectedField="selectedSlocs"
          fields={fields}
          showError={showError}
          setSelectedItem={setSelectedItem}
          setFields={setFields}
        />
        <DropDownField
          label={UIText.Material_No}
          field="materials"
          mandatory
          selectedField="selectedMaterial"
          fields={fields}
          showError={showError}
          setSelectedItem={setSelectedItem}
          setFields={setFields}
        />
        <DropDownField
          label={UIText.Tank}
          field="tanks"
          mandatory
          selectedField="selectedTank"
          fields={fields}
          showError={showError}
          setSelectedItem={setSelectedItem}
          setFields={setFields}
        />
        <DateField
          label={UIText.Delivery_Date}
          selectedField="documentDate"
          mandatory
          fields={fields}
          showError={showError}
          max={getYesterdayDate()}
          onDateChange={onDateChange}
        />
        <TimeField
          label={UIText.Delivery_Start_Time}
          selectedField="deliveryStartTime"
          type="start"
          disabled
          fields={fields}
          onTimeChange={onTimeChange}
        />
        <TimeField
          label={UIText.Delivery_End_Time}
          selectedField="deliveryEndTime"
          type="end"
          disabled
          fields={fields}
          onTimeChange={onTimeChange}
        />
        <TextInputField
          label={UIText.Start_Temperature}
          selectedField="startTemperature"
          disabled
          fields={fields}
          selectedItem={selectedItem}
          showError={showError}
          inputRef={inputRef}
          onChange={onTextInputChange}
        />
        <TextInputField
          label={UIText.End_Temperature}
          selectedField="endTemperature"
          disabled
          fields={fields}
          selectedItem={selectedItem}
          showError={showError}
          inputRef={inputRef}
          onChange={onTextInputChange}
        />
        <TextInputField
          label={
            isDachCluster()
              ? UIText.Calculated_Delivery
              : UIText.Detected_Delivery
          }
          selectedField="calculatedDelivery"
          disabled
          fields={fields}
          selectedItem={selectedItem}
          showError={showError}
          inputRef={inputRef}
          onChange={onTextInputChange}
        />
        <TextInputField
          label={UIText.Confirmed_Delivery}
          selectedField="confirmedDelivery"
          mandatory
          fields={fields}
          selectedItem={selectedItem}
          showError={showError}
          inputRef={inputRef}
          onChange={onTextInputChange}
        />
        <div className="deliveryRow">
          <span className="dateLbl">{UIText.Difference}</span>
          <span className="dateVal">{fields?.difference}</span>
        </div>
        <TextInputField
          label={UIText.OutBound_Delivery_No}
          selectedField="outBoundDeliveryId"
          mandatory
          fields={fields}
          selectedItem={selectedItem}
          showError={showError}
          inputRef={inputRef}
          onChange={onTextInputChange}
        />
      </div>
    );
  };

  const isValid = () => {
    const deliveryValues =
      fields?.confirmedDelivery &&
      fields?.outBoundDeliveryId &&
      fields?.documentDate;

    const tankValues =
      fields?.reportDate &&
      fields?.selectedTank?.[0]?.id !== UIText.Select &&
      fields?.selectedTank?.[0]?.id?.length > 0;

    const materialValues =
      fields?.selectedMaterial?.[0]?.id !== UIText.Select &&
      fields?.selectedMaterial?.[0]?.id?.length > 0;

    const allValid = deliveryValues && tankValues && materialValues;

    setShowError(!allValid);
    return allValid;
  };

  const showSubmitButton = () => {
    return (
      <div className="submit-btn-container">
        <Button
          className="submit-btn"
          onClick={() => {
            if (isValid()) {
              saveChanges();
            }
          }}
        >
          {submitting ? UIText.Submitting : UIText.Submit_Delivery}
        </Button>
      </div>
    );
  };

  const createPayload = () => {
    const documentDate = getFormattedDate(fields?.documentDate);
    const reportDate = getFormattedDate(fields?.reportDate);
    const shipTo = fields?.selectedShipTo?.[0]?.id;
    const siteData = fields?.shipTos?.find((item) => item.id === shipTo);
    const selectedComment = fields?.selectedComments?.[0]?.id;
    const selectedTank =
      fields?.selectedTank?.[0]?.id === UIText.Select
        ? ""
        : fields?.selectedTank?.[0]?.id;

    const deliveryStartDate = fields?.documentDate
      ? new Date(fields?.documentDate)
      : new Date();
    deliveryStartDate.setHours(
      fields?.deliveryStartTime ? fields?.deliveryStartTime?.getHours() : 0
    );
    deliveryStartDate.setMinutes(
      fields?.deliveryStartTime ? fields?.deliveryStartTime?.getMinutes() : 0
    );

    const deliveryEndDate = fields?.documentDate
      ? new Date(fields?.documentDate)
      : new Date();
    deliveryEndDate.setHours(
      fields?.deliveryEndTime ? fields?.deliveryEndTime?.getHours() : 0
    );
    deliveryEndDate.setMinutes(
      fields?.deliveryEndTime ? fields?.deliveryEndTime?.getMinutes() : 0
    );

    const payload = {
      countryCode: fields?.selectedRegions?.[0]?.countryCode,
      plant: fields?.selectedPlants?.[0]?.id,
      shipTo: fields?.selectedShipTo?.[0]?.id,
      sloc: fields?.selectedSlocs?.[0]?.id,
      materialNumber: fields?.selectedMaterial?.[0]?.id,
      documentDate: documentDate,
      reportDate: reportDate,
      calculatedDelivery: null,
      confirmedDelivery: parseFloat(fields?.confirmedDelivery),
      difference: fields?.difference,
      outBoundDeliveryId: fields?.outBoundDeliveryId,
      comment: selectedComment === UIText.Select ? "" : selectedComment,
      userName: user?.UserEmail,
      localSiteId: siteData?.localSiteId,
      obn: siteData?.obn,
      region: fields?.selectedRegions?.[0]?.id,
      isRkSIte: siteData?.isRKSite,
      tankId: selectedTank,
      deliveryStartTime: null,
      deliveryEndTime: null,
      startTemperature: null,
      endTemperature: null,
    };

    return payload;
  };

  const saveChanges = async () => {
    setSubmitting(true);
    const payload = createPayload();
    const response = await reportsService.addAFDDelivery(payload);
    if (response?.data?.isSuccess) {
      dispatch(
        triggerNotification({
          type: {
            style: UIText.success,
            icon: true,
          },
          message:
            response?.data?.message || UIText.New_delivery_created_successfully,
        })
      );
      props?.saveDelivery();
    } else {
      dispatch(
        triggerNotification({
          type: {
            style: error,
            icon: true,
          },
          message:
            response?.data?.message || UIText.Unable_to_submit_the_delivery,
        })
      );
      setSubmitting(false);
    }
  };

  return (
    <div className="popupContainer">
      <div className="deliveryForm">
        {showHeader()}
        {showContent()}
        {showSubmitButton()}

        {submitting && (
          <div className="loaderContainer">
            <Loader
              size="medium"
              themeColor="warning"
              type="converging-spinner"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { AddDelivery };
