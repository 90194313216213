import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { SummaryPanel } from "./components/summaryPanel";
import { Tile } from "./components/tile";
import { LoadingPanel } from "../../components/loading-panel/loading-panel";
import "./home.scss";
import { store } from "../../../state-management/store";
import { useDispatch, useSelector } from "react-redux";
import { saveLoggedInUser, saveLoggedInUserUUID, setCurrentCountry } from "../../../state-management/actions";
import { getMenus } from "../reports/components/common-utils";
import { userService } from "../../../services/user";

const HomePage = () => {
  const country = useSelector(
    (state) => state?.user?.user?.UserPreference?.DefaultCountry || "DE"
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [tiles, setTiles] = useState([]);
  const navigate = useNavigate();
  const screenList = store.getState()?.user?.userScreenList;
  const [currentScreenList, setCurrentScreenList] = useState(screenList);
  const defaultCountry = useSelector(
    (state) => state?.reports?.selectedCountry
  );
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);

  useEffect(() => {
    const { userScreenList } = store.getState().user;
    if (userScreenList?.length === 0) {
      setLoading(true);
      async function fetchUserReports() {
        try {
          const uniqueUserId = store.getState()?.login?.UniqueUserId;
          const user = await userService.fetchUser(uniqueUserId, currentCountryCode);
          store.dispatch(saveLoggedInUser(user));
          store.dispatch(saveLoggedInUserUUID(user?.UniqueUserId));
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
      fetchUserReports();
    }

    if (!defaultCountry) {
      dispatch(setCurrentCountry(country));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPageTitle = (report) => {
    let currentItem = report;
    while (currentItem?.children?.length > 0) {
      currentItem = currentItem?.children?.[0];
    }
    return currentItem?.title;
  };

  const getReportId = (report) => {
    return (
      report?.children?.[0]?.children?.[0]?.reportId ||
      report?.children?.[0]?.reportId ||
      ""
    );
  };

  const getPageId = (report) => {
    return (
      report?.children?.[0]?.children?.[0]?.id ||
      report?.children?.[0]?.id ||
      report.id
    );
  };

  const getPageName = (report) => {
    return (
      report?.children?.[0]?.children?.[0]?.pageName ||
      report?.children?.[0]?.pageName ||
      ""
    );
  };

  const getTableName = (report) => {
    return (
      report?.children?.[0]?.children?.[0]?.tableName ||
      report?.children?.[0]?.tableName ||
      ""
    );
  };

  const getColumnName = (report) => {
    return (
      report?.children?.[0]?.children?.[0]?.columnName ||
      report?.children?.[0]?.columnName ||
      ""
    );
  };

  useEffect(() => {
    const menuList = getMenus(currentCountryCode, currentScreenList);
    const cardsList = menuList?.reduce((item, report) => {
      const pageTitle = getPageTitle(report);
      return [
        ...item,
        {
          moduleId: report.id,
          title: report.title,
          pageTitle: pageTitle,
          description: report.description,
          submoduleId: report?.children?.[0]?.id,
          reportId: getReportId(report),
          pageId: getPageId(report),
          pageName: getPageName(report),
          tableName: getTableName(report),
          columnName: getColumnName(report),
        },
      ];
    }, []);
    setTiles(cardsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentScreenList]);

  store.subscribe(() => {
    const { selectedCountry } = store.getState().reports;
    const { userScreenList } = store.getState().user;
    setCurrentScreenList(userScreenList);
    setCurrentCountryCode(selectedCountry);
  });

  const onTileClick = ({
    moduleId,
    submoduleId,
    pageId,
    pageName,
    title,
    reportId,
    tableName,
    pageTitle,
    columnName,
  }) => {
    const selectedReport = {
      moduleId,
      submoduleId,
      pageId,
      pageName,
      title: pageTitle || title,
      reportId,
      tableName,
      columnName,
    };
    localStorage.setItem("reportsData", JSON.stringify(selectedReport));
    navigate("/reports");
  };
  return (
    <div className="container-fluid">
      <div className="row dashboard-page">
        <SummaryPanel />
        <div className="col-md-8 col-sm-8 col-xs-8 col-lg-8 col-xl-8 tiles-panel">
          <div className="container tiles-panel-container">
            <div className="row">
              {tiles?.map((tile) => (
                <Tile
                  tileData={tile}
                  key={`${tile}`}
                  onTileClick={() => onTileClick(tile)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <LoadingPanel message="Refreshing the data.. please wait.." />
      )}
    </div>
  );
};

export { HomePage };
