import { httpService } from "./http";
import { loggerService } from "../services/logger";
import { API, getFormattedDate } from "./utils";
import { store } from "../state-management/store";
import { isBFXCluster } from "../ui/pages/reports/components/common-utils";

/**
 * A Service that handles the reports related operations, for the portal.
 * Such as fetching all the reports available.
 */

const AFD = "ACCOUNTING FOR DELIVERIES";
class ReportsService {
  /**
   * This function fetches the reports from remote server.
   * Different APIs are called based on application's environment.
   */
  async fetchReportsList() {
    const URL = `${API.masterData}/master/menuconfig/DE`;
    try {
      const response = await httpService.get(URL);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  /**
   * This function fetches the reports access token and embedded url from remote server.
   * @param {string} reportId associated with each report-menu item.
   */
  async fetchEmbeddedToken(reportId) {
    const { user } = store.getState().user;
    const URL = API.powerBiToken;
    const payload = {
      UserEmail: user?.UserEmail,
      UserRoleId: user?.RoleId,
      ReportId: reportId,
    };
    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async fetchPreviousComments(countryCode, report, startDate, endDate) {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    let URL = `${
      API.cwrComment
    }/fetch/oota?countryCode=${countryCode}&fromDate=${
      startDate || getFormattedDate(yesterday)
    }&toDate=${endDate || getFormattedDate(today)}`;

    if (report === AFD) {
      URL = `${API.cwrCommentAFD}?countryCode=${countryCode}&fromDate=${
        startDate || "%27%27"
      }&toDate=${endDate || "%27%277"}`;
    }

    try {
      const response = await httpService.get(URL);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async fetchCommentsForm(countryCode, report) {
    let URL = `${API.cwrComment}/screendata/OUT_OF_TOLERANCE/${countryCode}`;

    if (report === AFD) {
      URL = `${API.cwrComment}/screendata/AFD/${countryCode}`;
    }

    try {
      const response = await httpService.get(URL);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async saveReportComment(payload, report) {
    let URL = `${API.cwrComment}`;

    if (report === AFD) {
      URL = `${API.cwrCommentAFD}`;
    }

    try {
      const response = await httpService.put(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async getOOTAChartData(countryCode, startDate, endDate) {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const URL = `${
      API.chartData
    }/chart/oota?countryCode=${countryCode}&fromDate=${
      startDate || getFormattedDate(yesterday)
    }&toDate=${endDate || getFormattedDate(today)}`;

    try {
      const response = await httpService.get(URL);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async getChartData(payload, report) {
    let URL = `${API.cwrComment}/chartdata`;

    if (report === AFD) {
      URL = `${API.cwrComment}/afdchartdata`;
    }

    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async getRestoreData() {
    const URL = `${API.reportRequest}/Restore`;

    try {
      const response = await httpService.get(URL);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async saveRestoreData(report) {
    const URL = `${API.reportRequest}/Restore`;
    const payload = {
      reportCode: report.reportCode,
      restoreStartDate: report.restoreStartDate,
      restoreEndDate: report.restoreEndDate,
    };

    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response.status;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async uploadFile(payload, customHeaders) {
    const URL = `${API.UploadExcel}`;
    const headers = {
      "Content-Type": "multipart/form-data",
      ...customHeaders,
    };
    const options = {
      headers,
    };
    try {
      return await httpService.post(URL, payload, options);
    } catch (error) {
      return error;
    }
  }

  async fetchDuplicateDeliveries(countryCode) {
    const URL = `${API.duplicateDeliveries}?country=${countryCode}`;
    try {
      return await httpService.get(URL);
    } catch (error) {
      return null;
    }
  }

  async submitDuplicateDeliveries(payload) {
    const URL = `${API.duplicateDeliveries}`;

    try {
      const response = await httpService.put(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async ignoreDuplicateDeliveries(payload) {
    const URL = `${API.duplicateDeliveries}/ignore`;

    try {
      return await httpService.put(URL, payload);
    } catch (error) {
      return null;
    }
  }

  async fetchThresholdData(payload) {
    const { selectedCountry } = store.getState().reports;
    const URL = `${API.threshold}/Threshold/GetThresholds`;
    const requestData = {
      country: selectedCountry,
      globalSiteId: payload?.globalSiteId,
      siteId: payload?.siteId,
      selectedTankIds: payload?.selectedTankIds,
      selectedMonths: payload?.selectedMonths?.sort(),
      selectedYears: payload?.selectedYears?.sort(),
      selectedMaterials: payload?.selectedMaterials,
    };

    try {
      const response = await httpService.post(URL, requestData);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
  async fetchThresholdLeaksMasterData(payload) {
    const URL = `${API.threshold}/WsmMaster/GetSiteDetails`;

    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
  async fetchLeaksData(payload) {
    const URL = `${API.threshold}/TankLeaks/GetTankLeakResults`;

    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async fetchReconciliationResults(payload) {
    const URL = `${API.threshold}/TankLeaks/GetReconciliationResults`;

    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
  async updateThreshold(payload) {
    const { selectedCountry } = store.getState().reports;
    const URL = `${API.threshold}/Threshold/UpdateThreshold`;
    const requestData = {
      country: selectedCountry,
      globalSiteId: payload?.globalSiteId,
      siteId: payload?.siteId,
      tankGroupId: payload?.tankGroupId,
      tankIds: payload?.tankIds,
      material: payload?.material,
      requestDate: payload?.thresholdCreateDate,
      updatedThreshold: parseFloat(payload?.threshold),
    };
    try {
      return await httpService.post(URL, requestData);
    } catch (error) {
      return null;
    }
  }

  async storeSitesAPIResponse(key, data) {
    try {
      sessionStorage.setItem(key, JSON.stringify(data));
    } catch (error) {
      loggerService.dev(error);
    }
  }

  async getSitesAPIResponse(key) {
    try {
      return sessionStorage.getItem(key);
    } catch (error) {
      return null;
    }
  }

  async fetchToleranceTrendMasterData(payload, isTankThreshold = false) {
    let URL = `${API.afd}/${payload?.country}/sites`;
    const storageKey = `SITES_API_RESPONSE_${payload?.country}_${isTankThreshold}`;
    if (isTankThreshold) {
      URL = `${URL}/true`;
    } else {
      URL = `${URL}/false`;
    }

    const storedData = await this.getSitesAPIResponse(storageKey);
    if (storedData) {
      return JSON.parse(storedData);
    }

    try {
      const response = await httpService.get(URL);
      if (response?.hasOwnProperty("data")) {
        await this.storeSitesAPIResponse(storageKey, response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async fetchGainAndLossMasterData(payload) {
    const URL = `${API.afd}/gain-loss/${payload?.country}/sites`;
    const storageKey = `SITES_API_RESPONSE_GAIN_LOSS_${payload?.country}`;
    const storedData = await this.getSitesAPIResponse(storageKey);
    if (storedData) {
      return JSON.parse(storedData);
    }
    try {
      const response = await httpService.get(URL);
      if (response?.hasOwnProperty("data")) {
        await this.storeSitesAPIResponse(storageKey, response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async fetchRegionMasterData(payload) {
    const URL = `${API.oota}/oottamaster/GetOOTTAMastersByMarket?market=${payload?.country}`;

    try {
      const response = await httpService.get(URL);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async fetchRegionsData(payload) {
    const { selectedCluster } = store.getState().reports;
    const URL = `${API.afd}/${selectedCluster}/${payload?.country}/regions`;

    try {
      const response = await httpService.get(URL);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async fetchCommentsMasterData() {
    const storageKey = `COMMENTS_MASTER_DATA`;
    const URL = `${API.ottSummary}/comment-types`;

    const storedData = await this.getSitesAPIResponse(storageKey);
    if (storedData) {
      return JSON.parse(storedData);
    }

    try {
      const response = await httpService.get(URL);
      if (response?.hasOwnProperty("data")) {
        await this.storeSitesAPIResponse(storageKey, response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
  async fetchToleranceTrendData(payload) {
    const URL = `${API.oota}/OOTTA/GetOottaData`;

    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
  async fetchToleranceTrendDataV2(payload, historicalViewEnabled) {
    const source = isBFXCluster() ? "beta" : "alpha";
    let URL = `${API.ottSummary}/${source}`;
    if (historicalViewEnabled) {
      URL = URL + "/hist";
    }

    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async saveOOTAReportComment(payload) {
    const source = isBFXCluster() ? "beta" : "alpha";
    const URL = `${API.ottSummary}/${source}/comments/save`;

    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async fetchEodSummaryData(payload, endPoint) {
    const URL = `${API.eod}/eod/${endPoint}`;

    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async fetchEomSummaryData(payload) {
    const URL = `${API.monthlyStockLevel}`;

    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return response;
      }
    } catch (error) {
      return null;
    }
  }

  async fetchEomAuditData(payload) {
    const URL = `${API.eod}/eod/GetEODAuditData`;

    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return response;
      }
    } catch (error) {
      return null;
    }
  }

  async fetchEodAuditData(payload) {
    const URL = `${API.eod}/eod/GetEODAuditData`;

    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
  async saveEodSummaryValues(payload, endPoint) {
    const URL = `${API.eod}/eod/${endPoint}`;

    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
  async fetchPumpIndexData(payload) {
    const URL = `${API.pumpIndex}/pump-register/reports/material`;

    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
  async fetchPumpIndexTankData(payload) {
    const URL = `${API.pumpIndex}/pump-register/reports/tank-group`;

    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
  async savePumpIndexValues(payload) {
    const URL = `${API.pumpIndex}/pump-register/comments`;

    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async fetchPumpMonthlyExceptionData(payload) {
    const URL = `${API.pumpIndex}/pump-register/reports/monthly/exception`;

    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async fetchAccountForDeliveriesData(payload) {
    const { selectedCluster } = store.getState().reports;
    const URL = `${API.afdDeliveries}/afd/GetAfds`;

    try {
      const response = await httpService.post(URL, {
        ...payload,
        market: selectedCluster,
      });
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async fetchAFDAuditData(payload) {
    const URL = `${API.afdDeliveries}/afd/audits`;

    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
  async addAFDDelivery(payload) {
    const URL = `${API.afdDeliveries}/afd/create`;

    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async updateAFDDelivery(payload) {
    const URL = `${API.afdDeliveries}/afd/update`;

    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
  async fetchOOTSummaryData(payload, historicalViewEnabled) {
    let URL = `${API.ottSummary}/comments/summary/${payload.sourceName}`;
    if (historicalViewEnabled) {
      URL = URL + "/hist";
    }
    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async fetchOOTSummaryHeaderData(payload) {
    const { selectedCountry, selectedCluster } = store.getState().reports;
    const URL = `${API.ottSummary}/comments/summary/${selectedCluster}/${selectedCountry}/header`;

    try {
      const response = await httpService.get(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async fetchSiteInfo(payload) {
    const URL = `${API.afd}/${payload.countryCode}/site/${payload.globalSiteId}`;

    try {
      const response = await httpService.get(URL);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async fetchGainLossTankGroupData(payload) {
    const URL = `${API.gainLoss}/tank-group`;

    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
  async fetchGainLossMaterialData(payload) {
    const URL = `${API.gainLoss}/material`;

    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
  async fetchMessageTrackerDeliveries(payload) {
    const URL = `${API.messageTracker}/delivery/logs`;

    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async fetchFailedDeliveries(payload) {
    const URL = `${API.failedDelivery}/${payload?.country}/${payload?.date}`;
    try {
      const response = await httpService.get(URL);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return response;
      }
    } catch (error) {
      return null;
    }
  }

  async retryFailedDelivery(payload) {
    const URL = `${API.failedDelivery}/retry/${payload?.country}/${payload?.rowKey}`;
    try {
      const response = await httpService.get(URL);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return response;
      }
    } catch (error) {
      return null;
    }
  }

  async sendToGSAPStock(payload) {
    const URL = `${API.monthlyStockLevel}/exec`;
    try {
      const response = await httpService.post(URL, payload);
      if (response?.hasOwnProperty("data")) {
        return response;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async getMonthEndStockLogs(payload) {
    const URL = `${API.monthlyStockLevel}/logs/${payload?.country}/${payload?.rowKey}`;
    try {
      const response = await httpService.get(URL);
      return response;
    } catch (error) {
      return null;
    }
  }
}

loggerService.dev("Creating Reports Service");
const reportsService = new ReportsService();

loggerService.dev("Exporting Reports Service");
export { reportsService };
