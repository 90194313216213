const initialState = {
  userScreenList: [],
  user: null,
  language: "english",
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER_SCREEN_LIST":
      return { ...state, userScreenList: action.payload };

    case "SAVE_LOGGED_IN_USER":
      if (!action.payload) {
        return state;
      }
      return { ...state, user: action.payload };

    case "CHANGE_LANGUAGE": {
      const language = action?.payload["newLanguage"] || "english";
      return { ...state, language: language };
    }

    default:
      return state;
  }
};

export default userReducer;
