import { getMonths } from "../../reportUtils";
import { CustomHeaderCell } from "../account-for-deliveries/account-for-deliveries-utils";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-all";
import { DECIMAL } from "../common-utils";
import { getFormattedDate } from "../../../../../services/utils";
import { GainLossTabOptions } from "./gain-loss-overview";
import {
  MissingIconCell,
  MissingMandatoryCell,
  MultilineTextCell,
} from "../common-grid-cells";
import { UIText } from "../label-constants";

export const getMonthIndex = (name) => {
  const monthsData = getMonths();
  const month = monthsData?.find((monthData) => monthData.text === name);
  return month ? month.id : 0;
};

export const getTankGroups = (masterData, filters, productSelectedValues) => {
  const siteData = masterData?.find(
    (site) => site?.globalSiteId === filters?.selectedSiteId
  );
  const productTanks = [];
  productSelectedValues?.forEach((product) => {
    const tanks = siteData?.siteTanks?.filter(
      (tank) => tank?.materialNumber === product?.id || product?.id === "All"
    );
    if (tanks) {
      productTanks?.push(...tanks);
    }
  });

  let tankGroupData = [];

  if (productTanks?.length > 0) {
    const tankGroup = Object.groupBy(productTanks, (item) => item?.tankGroupId);

    tankGroupData = Object.keys(tankGroup)?.map((key) => {
      const groupItem = {
        text: key,
        id: key,
        group: true,
      };
      return groupItem;
    });
  }

  return tankGroupData;
};

export const addAllOptions = (items) => {
  return [
    {
      id: UIText.All,
      text: UIText.All,
    },
    ...items,
  ];
};

export const colsToShow = (selectedTab) => {
  let columns = [
    {
      title: UIText.Date,
      field: "date",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_SIX,
      filterable: true,
    },
    {
      title: UIText.Material_No,
      field: "material",
      headerCell: CustomHeaderCell,
      width:
        selectedTab === GainLossTabOptions.MATERIAL
          ? window.innerWidth * DECIMAL.POINT_ZERO_EIGHT
          : window.innerWidth * DECIMAL.POINT_ZERO_SIX,
      filterable: true,
    },
  ];
  if (selectedTab === GainLossTabOptions.TANK_GROUP) {
    columns = [
      ...columns,
      {
        title: UIText.Tank_Group,
        field: "tankGroupName",
        headerCell: CustomHeaderCell,
        width: window.innerWidth * DECIMAL.POINT_ZERO_FIVE,
        filterable: true,
      },
    ];
  }

  columns = [
    ...columns,
    {
      title: UIText.OutBound_Delivery_No,
      field: "outBound",
      headerCell: (props) => {
        return (
          <CustomHeaderCell {...props} label={UIText.OutBound_Delivery_Ids} />
        );
      },
      cell: (props) => {
        return <MultilineTextCell {...props} />;
      },
      filterable: true,
    },
    {
      title: UIText.Stock,
      field: "stock",
      headerCell: (props) => {
        return <CustomHeaderCell {...props} label={UIText.Stock_in_liters} />;
      },
      cell: (props) => {
        return <MissingMandatoryCell {...props} />;
      },
      width: window.innerWidth * DECIMAL.POINT_ZERO_SIX,
      filterable: true,
    },
    {
      title: UIText.Sales,
      field: "sales",
      headerCell: (props) => {
        return <CustomHeaderCell {...props} label={UIText.Sales_in_liters} />;
      },
      cell: (props) => {
        return <MissingIconCell {...props} />;
      },
      width: window.innerWidth * DECIMAL.POINT_ZERO_SIX,
      filterable: true,
    },
    {
      title: UIText.Deliveries,
      field: "deliveries",
      headerCell: (props) => {
        return (
          <CustomHeaderCell {...props} label={UIText.Deliveries_in_liters} />
        );
      },
      width: window.innerWidth * DECIMAL.POINT_ZERO_SEVEN,
      filterable: true,
    },
    {
      title: UIText.Variance,
      field: "differences",
      headerCell: (props) => {
        return (
          <CustomHeaderCell {...props} label={UIText.Variance_in_liters} />
        );
      },
      cell: (props) => {
        return (
          <MissingIconCell
            {...props}
            dependent={{
              field: "stock",
              message: UIText.Missing_Stock_variance_calculated,
            }}
          />
        );
      },
      width: window.innerWidth * DECIMAL.POINT_ZERO_SEVEN,
      filterable: true,
    },
    {
      title: UIText.Cum_Variance,
      field: "cumDiff",
      headerCell: (props) => {
        return (
          <CustomHeaderCell {...props} label={UIText.Cumulative_variance_in_liters} />
        );
      },
      cell: (props) => {
        return <MissingIconCell {...props} />;
      },
      width: window.innerWidth * DECIMAL.POINT_ZERO_SEVEN,
      filterable: true,
    },
    {
      title: UIText.Cum_Sales,
      field: "cumSales",
      headerCell: (props) => {
        return <CustomHeaderCell {...props} label={UIText.Cumulative_sales} />;
      },
      cell: (props) => {
        return <MissingIconCell {...props} />;
      },
      width: window.innerWidth * DECIMAL.POINT_ZERO_SEVEN,
      filterable: true,
    },
    {
      title: UIText.Variance_Percent,
      field: "diffPercent",
      headerCell: (props) => {
        return <CustomHeaderCell {...props} label={UIText.Variance_in_percent} />;
      },
      cell: (props) => {
        return <MissingIconCell {...props} />;
      },
      width: window.innerWidth * DECIMAL.POINT_ZERO_SEVEN,
      filterable: true,
    },
    {
      title: UIText.Cum_Variance_percent,
      field: "cumDiffPercent",
      headerCell: (props) => {
        return (
          <CustomHeaderCell {...props} label={UIText.Cumulative_variance_in_percent} />
        );
      },
      cell: (props) => {
        return <MissingIconCell {...props} />;
      },
      width: window.innerWidth * DECIMAL.POINT_ZERO_SEVEN,
      filterable: true,
    },
  ];

  return columns;
};

export const colsToExport = (selectedTab) => {
  let columns = [
    {
      title: UIText.Date,
      field: "date",
    },
    {
      title: UIText.ShipTo,
      field: "globalSiteId",
    },
    {
      title: UIText.OBN,
      field: "obn",
    },
    {
      title: UIText.SLOC,
      field: "sloc",
    },
    {
      title: UIText.Plant,
      field: "plant",
    },
    {
      title: UIText.Material_No,
      field: "material",
    },
  ];
  if (selectedTab === GainLossTabOptions.TANK_GROUP) {
    columns = [
      ...columns,
      {
        title: UIText.Tank_Group,
        field: "tankGroupName",
      },
    ];
  }

  columns = [
    ...columns,
    {
      title: UIText.Stock,
      field: "stock",
    },
    {
      title: UIText.Sales,
      field: "sales",
    },
    {
      title: UIText.Deliveries,
      field: "deliveries",
    },
    {
      title: UIText.Delivery_IDs,
      field: "outBound",
    },
    {
      title: UIText.Variance,
      field: "differences",
    },
    {
      title: UIText.Variance_Percent,
      field: "diffPercent",
    },
    {
      title: UIText.Cum_Variance,
      field: "cumDiff",
    },
    {
      title: UIText.Cum_Variance_percent,
      field: "cumDiffPercent",
    },
    {
      title: UIText.Cum_Sales,
      field: "cumSales",
    },
  ];

  return columns;
};

const getSalesFromResponse = (item) => {
  return item?.sales ? Math.round(item?.sales) : item?.sales;
};

const getStockFromResponse = (item) => {
  return item?.stock ? Math.round(item?.stock) : item?.stock;
};

const getDeliveriesFromResponse = (item) => {
  return item?.deliveries ? Math.round(item?.deliveries) : item?.deliveries;
};

const getDifferencesFromResponse = (item) => {
  return item?.differences ? Math.round(item?.differences) : item?.differences;
};

const getDifferenceValueFromResponse = (differences, sales) => {
  return differences && sales ? differences / sales : 0;
};

const getDiffPercentFromResponse = (differenceVal) => {
  return differenceVal ? Math.round(differenceVal * 100) : 0;
};

const updateTotalSales = (sales, totalSales) => {
  if ((sales || sales === 0) && !totalSales) {
    totalSales = 0 + sales;
  } else if (sales) {
    totalSales += sales;
  }
  return totalSales;
};

const updateTotalDeliveries = (deliveries, totalDeliveries) => {
  if ((deliveries || deliveries === 0) && !totalDeliveries) {
    totalDeliveries = 0 + deliveries;
  } else if (deliveries) {
    totalDeliveries += deliveries;
  }
  return totalDeliveries;
};

export const getUpdatedResponse = (
  res,
  filters,
  selectedTab,
  setFooterItem
) => {
  const response = res?.map((item) => ({
    plant: item?.plant,
    sloc: item?.sloc,
    obn: item?.obn,
    globalSiteId: item?.globalSiteId,
    date: item?.requestDateUTC,
    material: item?.materialNumber,
    outBound: item?.outboundDeliveryIdsStr,
    stock: item?.currentStock,
    sales: item?.sales,
    deliveries: item?.keplerDeliveries,
    differences: item?.variance,
    tankGroup: item?.tankGroupId,
    tankGroupName: item?.tankGroupName,
  }));
  response?.sort((a, b) => new Date(a?.date) - new Date(b?.date));
  const cummDiffData = {};

  let totalSales = null;
  let totalDeliveries = null;
  let totalDifferences = null;
  let totalDifferencePercent = null;
  let openingStock = null;
  let closingStock = null;

  const region = filters?.selectedShipTo?.[0]?.region;

  const updatedData = response?.map((item) => {
    const sales = getSalesFromResponse(item);
    const stock = getStockFromResponse(item);
    const deliveries = getDeliveriesFromResponse(item);
    const differences = getDifferencesFromResponse(item);

    if (!openingStock) {
      openingStock = stock;
    }
    closingStock = stock;
    //Diff Percent
    const differenceVal = getDifferenceValueFromResponse(differences, sales);
    const diffPercent = getDiffPercentFromResponse(differenceVal);
    totalSales = updateTotalSales(sales, totalSales);

    totalDeliveries = updateTotalDeliveries(deliveries, totalDeliveries);

    const cumulateType =
      selectedTab === GainLossTabOptions.MATERIAL
        ? parseInt(item?.material)
        : item?.tankGroup;

    let outBound = item?.outBound?.replace(/ /g, "");
    const regex = new RegExp(",", "g");
    outBound = outBound.replace(regex, "\n");

    //Cumm Differences
    cummDiffData[cumulateType] = cummDiffData?.[cumulateType]
      ? cummDiffData?.[cumulateType]
      : {};

    const cummDiff =
      (cummDiffData?.[cumulateType]?.difference
        ? cummDiffData?.[cumulateType]?.difference
        : 0) + differences;
    cummDiffData[cumulateType].difference = cummDiff;

    //Cumm Sales
    const cumSales =
      (cummDiffData?.[cumulateType]?.sales
        ? cummDiffData?.[cumulateType]?.sales
        : 0) + sales;
    cummDiffData[cumulateType].sales = cumSales;

    //cumDiffPercent
    const diffPercentVal =
      cummDiff === 0 && cumSales === 0 ? 0 : cummDiff / cumSales;
    const cumDiffPercent = diffPercentVal
      ? Math.round(diffPercentVal * 100)
      : diffPercentVal;

    return {
      ...item,
      sales: sales,
      stock: stock,
      deliveries: deliveries,
      differences: differences,
      outBound: outBound,
      diffPercent: `${diffPercent}%`,
      cumDiff: cummDiff,
      cumSales: cumSales,
      cumDiffPercent:
        cumDiffPercent !== -Infinity && cumDiffPercent !== Infinity
          ? `${cumDiffPercent}%`
          : null,
    };
  });

  if (
    filters?.selectedProducts?.length === 1 &&
    filters?.selectedProducts?.[0]?.id !== "all"
  ) {
    totalDifferences =
      closingStock + totalSales - (openingStock + totalDeliveries);
    const variancePercent = (totalDifferences / totalSales) * 100;
    totalDifferencePercent =
      totalDifferences === 0 ? 0 : Math.round(variancePercent);
    //single product selection
    setFooterItem({
      region: region,
      openingStock: openingStock,
      closingStock: closingStock,
      sales: totalSales,
      deliveries: totalDeliveries,
      differences: totalDifferences,
      diffPercent:
        totalDifferencePercent !== -Infinity &&
        totalDifferencePercent !== Infinity
          ? `${totalDifferencePercent}%`
          : null,
    });
  }

  return updatedData;
};

export const isValuesSelected = (fromDate, toDate, region, shipTo, filter) => {
  const dateSelected = fromDate && toDate;

  const valuesSelected =
    dateSelected &&
    region?.length > 0 &&
    shipTo?.length > 0 &&
    filter?.selectedProducts?.length > 0;
  return valuesSelected;
};

const getFilteredProducts = (selectedProducts) => {
  return selectedProducts?.[0] === "all" ? [] : selectedProducts;
};

const getFilteredTanks = (selectedTankIds) => {
  return selectedTankIds?.[0] === "all" ? [] : selectedTankIds;
};

const getStartDate = (fromDate) => {
  return fromDate && getFormattedDate(fromDate);
};

const getEndDate = (toDate) => {
  return toDate && getFormattedDate(toDate);
};
export const getFetchGainAndLossPayload = (
  filter,
  currentCountryCode,
  selectedTab
) => {
  const fromDate = filter.selectedDates.start;
  const toDate = filter.selectedDates.end;
  const selectedProducts = filter?.selectedProducts?.map(
    (product) => product?.id
  );
  const filteredProducts = getFilteredProducts(selectedProducts);

  const selectedShipTo = filter?.selectedShipTo?.[0]?.id;
  const localSiteId = filter?.selectedShipTo?.[0]?.localSiteId;
  const selectedTankIds = filter?.selectedTanks?.map((tank) => tank?.id);
  const filteredTanks = getFilteredTanks(selectedTankIds);

  const startDate = getStartDate(fromDate);
  const endDate = getEndDate(toDate);
  const region = filter?.selectedRegions?.[0]?.id;
  const shipTo = filter?.selectedShipTo?.[0]?.id;

  let payload = {
    countryCode: currentCountryCode,
    FromDate: startDate,
    ToDate: endDate,
    GlobalSiteIds: [selectedShipTo],
    LegacySiteIds: [localSiteId],
    MaterialNumbers: filteredProducts,
  };

  let valuesSelected = isValuesSelected(
    fromDate,
    toDate,
    region,
    shipTo,
    filter
  );

  if (selectedTab === GainLossTabOptions.TANK_GROUP) {
    valuesSelected = valuesSelected && filter?.selectedTanks?.length > 0;
    payload = {
      ...payload,
      TankGroupIds: filteredTanks,
    };
  }

  return { payload, valuesSelected };
};

export const BoxItem = ({
  item,
  showIcon = false,
  errorIcon = false,
  link = false,
  linkAction,
  filterDataLoading,
}) => {
  let icon =
    showIcon && !errorIcon && require("../../../../../assets/warning.png");
  if (showIcon && errorIcon) {
    icon = require("../../../../../assets/error.png");
  }
  const value = item?.count;
  const valueAvailable =
    (value || value === 0) && value !== -Infinity && value !== Infinity;
  return (
    <div className="boxContainer">
      <span className="highlighter"></span>
      <span className="box-title">{item?.title}</span>
      {link && (
        <Button
          className="ship-to-link-btn"
          onClick={linkAction}
          disabled={filterDataLoading}
        >
          {value}
        </Button>
      )}
      {!link && valueAvailable && (
        <span className="box-count">{item?.count}</span>
      )}
      {!link && !valueAvailable && showIcon && (
        <Tooltip openDelay={100} position="left" anchorElement="target">
          <img
            className={errorIcon ? "errorIcon" : "warningIcon"}
            title={UIText.Data_not_received}
            src={icon}
            alt="Warning"
          />
        </Tooltip>
      )}
    </div>
  );
};
