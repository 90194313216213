import {
  NotificationGroup,
  Fade,
  Notification,
} from "@progress/kendo-react-all";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { closeNotification } from "../../../state-management/actions";
import { store } from "../../../state-management/store";

const CustomNotificationsContainer = () => {
  const [showNotification, setShowNotification] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const timeOut = 4000;
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = store.subscribe(async () => {
      const state = store.getState()?.reports;
      const currentNotifications = state?.notification;
      if (currentNotifications.length > 0) {
        setNotifications([...currentNotifications]);
        setShowNotification(true);
        setTimeout(() => {
          dispatch(closeNotification());
        }, timeOut);
      } else {
        setNotifications([]);
        setShowNotification(false);
      }
    });
    return () => {
      unsubscribe();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  return (
    <NotificationGroup
      style={{ position: "absolute", top: "10%", right: "2%" }}
    >
      <Fade>
        {showNotification &&
          notifications.map((notification) => {
            return (
              <Notification
                key={`${notification.message}-${notification.type}`}
                type={notification.type}
                closable
                onClose={() => dispatch(closeNotification())}
              >
                <span>{notification.message}</span>
              </Notification>
            );
          })}
      </Fade>
    </NotificationGroup>
  );
};
export { CustomNotificationsContainer };
