import "./common-utils.scss";
import { store } from "../../../../state-management/store";
import { SCREEN_IDS } from "../reportUtils";

export const NUMBERS = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  TWENTY: 20,
  THIRTY: 30,
  SIXTY: 60,
  NINETY: 90,
  TWO_HUNDRED: 200,
  TWO_ZERO_FOUR: 204,
  THREE_HUNDRED: 300,
  FOUR_HUNDRED: 400,
  FIVE_HUNDRED: 500,
  THOUSAND: 1000,
  TWO_THOUSAND_TWENTY_THREE: 2023,
};

export const DEFAULT_PAGE_SIZE = 50;
export const DEFAULT_INITIAL_PAGE_SIZE = 50;
export const NINE_MINS = 899;
export const REFRESH_MS = 3599;
export const THIRTY_MINS = NUMBERS.THIRTY * NUMBERS.SIXTY * NUMBERS.THOUSAND;
export const DECIMAL = {
  POINT_ZERO_THREE_FIVE: 0.035,
  POINT_ZERO_THREE: 0.03,
  POINT_ZERO_FOUR: 0.04,
  POINT_ZERO_FIVE: 0.05,
  POINT_ZERO_SIX: 0.06,
  POINT_ZERO_SEVEN: 0.07,
  POINT_ZERO_EIGHT: 0.08,
  POINT_ZERO_EIGHT_FIVE: 0.085,
  POINT_ZERO_NINE: 0.09,
  POINT_ONE: 0.1,
  POINT_ONE_TWO: 0.12,
  POINT_ONE_THREE: 0.13,
  POINT_ONE_FOUR: 0.14,
  POINT_ONE_FIVE: 0.15,
  POINT_EIGHT_FIVE: 0.85,
  POINT_NINE: 0.9,
  POINT_NINE_FIVE: 0.95,
  POINT_TWO: 0.2,
  POINT_ZERO_ONE_ZERO_FIVE: 0.0105,
  POINT_ZERO_FOUR_THREE: 0.043,
  ONE_POINT_ONE_TWO: 1.12,
  ONE_POINT_TWO: 1.2,
  THREE_POINT_TWO: 3.2,
  THREE_POINT_FOUR: 3.4,
  SIX_POINT_FOUR: 6.4,
  SIX_POINT_EIGHT: 6.8,
};
export const iframeContainer = "iframe-container";

export const IssueRootCause = {
  Blocked_Gauges_Probe_Issue: "Blocked Gauges/Sticking Probe Issue",
  Unknown: "Unknown",
  Confirmed_PLIP: "Confirmed PLIP",
  Confirmed_ATG_Defect: "Confirmed ATG Defect",
  WSMA_system_Issue: "WSMA system Issue",
  Invalid_Overstated_Delivery: "Invalid/Overstated Delivery",
  Missing_Confirmed_Deliveries: "Missing Confirmed Deliveries",
  Missing_Understated_Delivery: "Missing/Understated Delivery",
  Missing_Understated_Sales: "Missing/Understated Sales",
  Possible_ATG_Defect: "Possible ATG Defect",
  No_End_Stock_Data: "No End Stock Data",
  Possible_PLIP: "Possible PLIP",
  Possible_Test_Pump: "Possible Test Pump",
  Site_system_Issue: "Site system Issue",
  Temporarily_Closed_Site: "Temporarily Closed Site",
  Timing_difference_on_delivery: "Timing difference on delivery",
  Waiting_for_confirmed_delivery: "Waiting for confirmed delivery",
};

export const AreaOfIssue = {
  Unknown: "Unknown",
  No_Issue: "No Issue",
  No_Data: "No Data",
  Data_Connection: "Data Connection",
  Data_Line: "Data Line",
  Delivery: "Delivery",
  Sales: "Sales",
  Stocks: "Stocks",
  Tank_Change: "Tank Change",
};

export const PrePosting = {
  Pre_Posting_Activities: "Pre-Posting Activities",
  Supporting_Evidences: "Supporting Evidences",
  Highlighs_Disclosures: "Highlighs/Disclosures",
};

export const showTotalRecordsCount = (messages) => {
  return (
    <div className="total-count-container">
      {messages?.map((message) => {
        return (
          <div className="message" key={message}>
            {message}
          </div>
        );
      })}
    </div>
  );
};

export const getOOTASourceName = (countryCode) => {
  switch (countryCode) {
    case "BE":
    case "NL":
    case "FR":
    case "LU":
      return "beta";
    default:
      return "alpha";
  }
};

export const getAllClusterCountries = () => {
  const { clusters } = store.getState().reports;
  let countries = [];

  Object?.keys(clusters)?.forEach((cluster) => {
    const items = clusters?.[cluster];
    if (items?.length > 0) {
      countries = [...countries, ...items];
    }
  });
  return countries;
};

export const isDachCluster = () => {
  const { selectedCluster } = store.getState().reports;
  return selectedCluster?.toLowerCase() === "dach";
};

export const getSelectedCluster = () => {
  const { selectedCluster } = store.getState().reports;
  return selectedCluster;
};

export const isBFXCluster = () => {
  const { selectedCluster } = store.getState().reports;
  return selectedCluster?.toLowerCase() === "benefrux";
};

export const stringToBool = (str) => {
  if (typeof str !== "string") {
    return false;
  }
  str = str.toLowerCase().trim();
  return str === "true";
};

export const convertStringToArray = (str, separator) => {
  if (typeof str !== "string") {
    return [];
  }
  const arr = str.split(separator);
  return arr;
};
export const getEmptyStrForNull = (value) => {
  return value || "";
};

export const getMenus = (currentCountryCode, menus) => {
  const {
    REACT_APP_IS_DEVELOPER_MODE,
    REACT_APP_PUMP_REGISTER_SUPPORTED_COUNTRIES,
  } = process.env;

  const separator = ",";
  const pumpAllowedRegions = convertStringToArray(
    REACT_APP_PUMP_REGISTER_SUPPORTED_COUNTRIES,
    separator
  );

  const pumpAllowed = pumpAllowedRegions?.includes(currentCountryCode);

  let menuList = stringToBool(REACT_APP_IS_DEVELOPER_MODE)
    ? menus
    : menus?.filter((item) => item?.id !== SCREEN_IDS.MISC);

  menuList = pumpAllowed
    ? menuList
    : menuList?.filter((item) => item?.id !== SCREEN_IDS.PUMP_REGISTER_REPORT);

  return menuList;
};

export const emptyCacheAndHardReload = async () => {
  // 1. Clear localStorage and sessionStorage
  localStorage.clear();
  sessionStorage.clear();

  // 2. Clear cookies
  document.cookie.split(";").forEach((cookie) => {
    document.cookie = cookie
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });

  // 3. Clear service worker cache
  if ("caches" in window) {
    const cacheNames = await caches.keys();
    await Promise.all(cacheNames.map((cache) => caches.delete(cache)));
  }

  // 4. Clear IndexedDB (optional, for PWA apps or data-heavy libraries)
  if (window.indexedDB) {
    const databases = await indexedDB.databases();
    databases.forEach((db) => indexedDB.deleteDatabase(db.name));
  }

  // 5. Force a hard reload, bypassing cache
  window.location.reload(true);
};

export const USER_ROLES = {
  GLOBAL: 1,
  RETAILER: 2,
  SOM_ADMIN: 3,
  FMC: 4,
  RK_SITE: 7,
  VISITOR: 10,
};

export const isTimeOlderThanMins = (date, mins = 10) => {
  const tenMinutesAgo = new Date(Date.now() - mins * 60 * 1000);
  return new Date(date) < tenMinutesAgo;
};


export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
