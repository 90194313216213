import {
  convertUTCDateToLocalDate,
  isDateOlderThanToday,
} from "../../../../../services/utils";
import calculateSize from "calculate-size";
import { Hint } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-all";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { CustomHeaderCell } from "../account-for-deliveries/account-for-deliveries-utils";
import {
  DECIMAL,
  getSelectedCluster,
  isBFXCluster,
  isDachCluster,
  NUMBERS,
} from "../common-utils";
import { getFormattedDate } from "../../../../../services/utils";
import { store } from "../../../../../state-management/store";
import { ShipToLinkCell } from "../common-grid-cells";
import { UIText } from "../label-constants";

export const EODViewOptions = {
  TANK: 0,
  AUDIT: 1,
};

const itemEditable = (dataItem, field) => {
  if (field === "eodSales") {
    return isDateOlderThanToday(new Date(dataItem?.date), NUMBERS.NINETY);
  } else if (field === "eodStock" || field === "eodDeliveries") {
    return isDateOlderThanToday(new Date(dataItem?.date), NUMBERS.SEVEN);
  }
  return true;
};
const getLatestEditItem = (dataItem) => {
  let recentItem = null;
  if (
    dataItem?.EODSaleDetails?.EditedOn &&
    dataItem?.EODStockDetails?.EditedOn
  ) {
    //compare date..
    if (
      new Date(dataItem?.EODSaleDetails?.EditedOn) >
      new Date(dataItem?.EODStockDetails?.EditedOn)
    ) {
      recentItem = dataItem?.EODSaleDetails;
    } else {
      recentItem = dataItem?.EODStockDetails;
    }
  } else if (dataItem?.EODSaleDetails?.EditedOn) {
    recentItem = dataItem?.EODSaleDetails;
  } else if (dataItem?.EODStockDetails?.EditedOn) {
    recentItem = dataItem?.EODStockDetails;
  }

  return recentItem;
};

const isSameInputItem = (selectedItem, dataItem, field) => {
  return selectedItem ? isSame(dataItem, selectedItem, field) : false;
};

const isInputValueEdited = (field, dataItem) => {
  let valueEdited = false;
  if (field === "eodStock") {
    valueEdited = dataItem?.EODStockDetails?.EditedBy?.length > 0;
  } else if (field === "eodSales") {
    valueEdited = dataItem?.EODSaleDetails?.EditedBy?.length > 0;
  }
  return valueEdited;
};

const getInputOldValue = (field, dataItem) => {
  let oldValue = "";
  if (field === "eodStock" && dataItem?.EODStockDetails?.EditedBy?.length > 0) {
    oldValue = dataItem?.EODStockDetails?.OldValue;
  } else if (
    field === "eodSales" &&
    dataItem?.EODSaleDetails?.EditedBy?.length > 0
  ) {
    oldValue = dataItem?.EODSaleDetails?.OldValue;
  }
  return oldValue;
};

const inputEditedOn = (recentItem) => {
  return (
    recentItem?.EditedOn &&
    convertUTCDateToLocalDate(new Date(recentItem?.EditedOn))
  );
};

const getInputErrorStyle = (field, dataItem) => {
  let errorClassName = "";
  if (
    field === "remarks" &&
    dataItem?.remarksError &&
    !(dataItem?.remarks?.length > 0)
  ) {
    errorClassName = "errorClassName";
  }
  return errorClassName;
};

const TextInputCell = (props) => {
  const { dataItem, field, selectedItem, onChange, inputRef, remarksCell } =
    props;
  const editable = itemEditable(dataItem, field);

  if (editable) {
    const sameItem = isSameInputItem(selectedItem, dataItem, field);
    const value = dataItem?.[field];
    let valueEdited = isInputValueEdited(field, dataItem);
    const userIcon = require("../../../../../assets/user-green.png");
    let recentItem = null;
    if (field === "remarks") {
      valueEdited =
        dataItem?.EODSaleDetails?.EditedBy?.length > 0 ||
        dataItem?.EODStockDetails?.EditedBy?.length > 0;

      if (valueEdited) {
        recentItem = getLatestEditItem(dataItem);
      }
    }

    const oldValue = getInputOldValue(field, dataItem);

    const userIconClassName = "userIcon";
    const editedBy = recentItem?.EditedBy;
    const editedOn = inputEditedOn(recentItem);
    const title = `${UIText.Edited_by} "${editedBy}" \n on ${editedOn}`;
    const errorClassName = getInputErrorStyle(field, dataItem);

    const handleChange = (event) => {
      const position = event.target.element?.selectionStart;
      const inputValue = event.target.value;
      const newValue = remarksCell
        ? inputValue
        : inputValue.replace(/[^-?\d*]/g, "");
      const newDataItem = { ...dataItem, [field]: newValue };

      onChange({
        dataItem: newDataItem,
        field: field,
        position: position,
      });
    };

    return (
      <td className="editableCell">
        <div className="editContainer">
          <Input
            ref={sameItem ? inputRef : null}
            value={value}
            maxLength={100}
            className={errorClassName}
            onChange={handleChange}
          />
          {valueEdited && field !== "remarks" && (
            <Tooltip openDelay={100} position="left" anchorElement="target">
              <div
                className="editIndicator"
                title={`${UIText.Previous_Value}: ${oldValue}`}
              ></div>
            </Tooltip>
          )}
          {(field === "eodStock" || field === "eodSales") && !valueEdited && (
            <div className="nonEditIndicator"></div>
          )}
          {valueEdited && field === "remarks" && (
            <Tooltip openDelay={100} position="left" anchorElement="target">
              <span className="userIconContainer">
                <img
                  className={userIconClassName}
                  src={userIcon}
                  alt="User"
                  title={title}
                />
              </span>
            </Tooltip>
          )}
          {!valueEdited && field === "remarks" && (
            <Tooltip openDelay={100} position="left" anchorElement="target">
              <span className="userIconContainer"></span>
            </Tooltip>
          )}
        </div>
        {remarksCell && (
          <Hint direction={"end"}>
            {value?.length || 0} / {100}
          </Hint>
        )}
      </td>
    );
  }

  return <td className="nonEditableCell">{dataItem?.[field]}</td>;
};

export const isSame = (dataItem, selectedItem, field) => {
  const valuesSame =
    dataItem.product === selectedItem.product &&
    dataItem.tank === selectedItem.tank &&
    field === selectedItem?.editField;
  return (
    dataItem.date === selectedItem.date &&
    dataItem.globalSiteId === selectedItem.globalSiteId &&
    valuesSame
  );
};

export const calculateWidth = (field, data) => {
  let maxWidth = 0;
  data?.forEach((item) => {
    const fontSize = `${
      window.innerWidth * DECIMAL.POINT_ZERO_ONE_ZERO_FIVE
    }px`;
    // eslint-disable-next-line
    const size = calculateSize(item[field], {
      font: "Helvetica Neue",
      fontSize: fontSize,
    }); // pass the font properties based on the application
    if (size.width > maxWidth) {
      maxWidth = size.width;
    }
  });
  const minWidth = window.innerWidth * DECIMAL.POINT_ZERO_FIVE;
  return maxWidth < minWidth ? minWidth : maxWidth;
};

const getShipToColumnWidth = (bfxCluster) => {
  return bfxCluster
    ? window.innerWidth * DECIMAL.POINT_ZERO_FIVE
    : window.innerWidth * DECIMAL.POINT_ZERO_EIGHT;
};

const getCumDelColumnWidth = (bfxCluster) => {
  return bfxCluster
    ? window.innerWidth * DECIMAL.POINT_ZERO_FIVE
    : window.innerWidth * DECIMAL.POINT_ZERO_NINE;
};

const getRemarksColumnWidth = (bfxCluster) => {
  return bfxCluster
    ? window.innerWidth * DECIMAL.POINT_ONE_TWO
    : window.innerWidth * DECIMAL.POINT_ONE_FIVE;
};

const addStockColumns = (columns, ref, onChange, selectedItem) => {
  const bfxCluster = isBFXCluster();
  const dachCluster = isDachCluster();
  if (bfxCluster) {
    columns = [
      ...columns,
      {
        title: UIText.EOD_Stock,
        field: "eodStock",
        headerCell: CustomHeaderCell,
        filterable: true,
        width: window.innerWidth * DECIMAL.POINT_ZERO_FIVE,
        cell: (props) => {
          return (
            <TextInputCell
              {...props}
              inputRef={ref}
              onChange={onChange}
              selectedItem={selectedItem}
            />
          );
        },
      },
      {
        title: UIText.Stock_at_eleven_forty_five,
        field: "stockAt1145PM",
        filterable: true,
        editable: false,
        headerCell: CustomHeaderCell,
      },
    ];
  }

  if (dachCluster) {
    columns = [
      ...columns,
      {
        title: UIText.Stock_at_eleven_forty_five,
        field: "stockAt1145PM",
        filterable: true,
        editable: false,
        headerCell: CustomHeaderCell,
        cell: (props) => {
          return (
            <TextInputCell
              {...props}
              inputRef={ref}
              onChange={onChange}
              selectedItem={selectedItem}
            />
          );
        },
      },
    ];
  }
  return columns;
};

const addCummColumns = (columns, ref, onChange, selectedItem) => {
  const bfxCluster = isBFXCluster();
  const dachCluster = isDachCluster();
  if (bfxCluster) {
    columns = [
      ...columns,
      {
        title: UIText.Stock_Diff,
        field: "stockDiff",
        filterable: true,
        editable: false,
        headerCell: CustomHeaderCell,
      },
      {
        title: UIText.EOD_Sales,
        field: "eodSales",
        filterable: true,
        editable: true,
        headerCell: CustomHeaderCell,
        width: window.innerWidth * DECIMAL.POINT_ZERO_FIVE,
        cell: (props) => {
          return (
            <TextInputCell
              {...props}
              inputRef={ref}
              onChange={onChange}
              selectedItem={selectedItem}
            />
          );
        },
      },
      {
        title: UIText.Cumm_Sales_by_15Min,
        field: "cummSalesBy15min",
        filterable: true,
        editable: false,
        headerCell: CustomHeaderCell,
        width: window.innerWidth * DECIMAL.POINT_ZERO_FIVE,
      },
    ];
  }

  if (dachCluster) {
    columns = [
      ...columns,
      {
        title: UIText.Cumm_Sales_by_15Min,
        field: "cummSalesBy15min",
        filterable: true,
        editable: false,
        headerCell: CustomHeaderCell,
        width: window.innerWidth * DECIMAL.POINT_ZERO_EIGHT,
        cell: (props) => {
          return (
            <TextInputCell
              {...props}
              inputRef={ref}
              onChange={onChange}
              selectedItem={selectedItem}
            />
          );
        },
      },
    ];
  }

  if (bfxCluster) {
    columns = [
      ...columns,
      {
        title: UIText.Sales_Diff,
        field: "salesDiff",
        filterable: true,
        editable: false,
        headerCell: CustomHeaderCell,
      },
      {
        title: UIText.EOD_Deliveries,
        field: "eodDeliveries",
        filterable: true,
        headerCell: CustomHeaderCell,
      },
    ];
  }
  return columns;
};

const addTestSalesColumns = (columns) => {
  const bfxCluster = isBFXCluster();
  if (bfxCluster) {
    columns = [
      ...columns,
      {
        title: UIText.Has_Test_Sales,
        field: "hasTestSales",
        filterable: true,
        editable: false,
        headerCell: CustomHeaderCell,
      },
      {
        title: UIText.Test_Sales_Volume,
        field: "totalTestVolume",
        filterable: true,
        editable: false,
        headerCell: CustomHeaderCell,
      },
    ];
  }
  return columns;
};

const addDeliveryDiffColumn = (bfxCluster, columns) => {
  if (bfxCluster) {
    columns = [
      ...columns,
      {
        title: UIText.Deliveries_Diff,
        field: "deliveryDiff",
        filterable: true,
        editable: false,
        headerCell: CustomHeaderCell,
      },
    ];
  }
  return columns;
};

const getTankColumns = (
  eodData,
  ref,
  onChange,
  selectedItem,
  handleShipToLinkAction
) => {
  let columns = [];
  const bfxCluster = isBFXCluster();
  columns = [
    ...columns,
    {
      title: UIText.Date,
      field: "date",
      filterable: true,
      editable: false,
      headerCell: CustomHeaderCell,
      width: calculateWidth("date", eodData),
    },
    {
      title: UIText.ShipTo,
      field: "globalSiteId",
      filterable: true,
      editable: false,
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return (
          <ShipToLinkCell {...props} linkAction={handleShipToLinkAction} />
        );
      },
      width: getShipToColumnWidth(bfxCluster),
    },
    {
      title: UIText.Material_No,
      field: "product",
      filterable: true,
      editable: false,
      headerCell: CustomHeaderCell,
      width: calculateWidth("product", eodData),
    },
    {
      title: UIText.Tank,
      field: "tank",
      headerCell: CustomHeaderCell,
      filterable: true,
      editable: false,
    },
  ];

  columns = addStockColumns(columns, ref, onChange, selectedItem);

  columns = addCummColumns(columns, ref, onChange, selectedItem);

  columns = [
    ...columns,
    {
      title: UIText.Deliveries,
      field: "cummDeliveriesBy15min",
      filterable: true,
      editable: false,
      headerCell: CustomHeaderCell,
      width: getCumDelColumnWidth(bfxCluster),
    },
  ];

  columns = addDeliveryDiffColumn(bfxCluster, columns);

  columns = [
    ...columns,
    {
      title: UIText.Variance,
      field: "eodVariance",
      filterable: true,
      editable: false,
      headerCell: CustomHeaderCell,
    },
  ];

  columns = addTestSalesColumns(columns);

  columns = [
    ...columns,
    {
      title: UIText.Remarks,
      field: "remarks",
      filterable: true,
      editable: true,
      headerCell: CustomHeaderCell,
      width: getRemarksColumnWidth(bfxCluster),
      cell: (props) => {
        return (
          <TextInputCell
            {...props}
            inputRef={ref}
            onChange={onChange}
            selectedItem={selectedItem}
            remarksCell={true}
          />
        );
      },
    },
  ];
  return columns;
};

const getAuditGridColumns = (eodData, handleShipToLinkAction) => {
  let columns = [];
  columns = [
    {
      title: UIText.Country,
      field: "country",
      headerCell: CustomHeaderCell,
      filterable: true,
      editable: false,
      width: calculateWidth("country", eodData),
    },
    {
      title: UIText.ShipTo,
      field: "globalSiteId",
      filterable: true,
      editable: false,
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return (
          <ShipToLinkCell {...props} linkAction={handleShipToLinkAction} />
        );
      },
      width: calculateWidth("globalSiteId", eodData),
    },
    {
      title: UIText.Requested_Date,
      field: "requestDate",
      filterable: true,
      editable: false,
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_SIX,
    },
    {
      title: UIText.Tank,
      field: "tank",
      headerCell: CustomHeaderCell,
      filterable: true,
      editable: false,
      width: calculateWidth("tank", eodData),
    },
    {
      title: UIText.Entity,
      field: "type",
      headerCell: CustomHeaderCell,
      filterable: true,
      editable: false,
      width: calculateWidth("type", eodData),
    },
    {
      title: UIText.Current_Value,
      field: "newValue",
      headerCell: CustomHeaderCell,
      filterable: true,
      editable: false,
      width: calculateWidth("newValue", eodData),
    },
    {
      title: UIText.Previous_Value,
      field: "oldValue",
      headerCell: CustomHeaderCell,
      filterable: true,
      editable: false,
      width: window.innerWidth * DECIMAL.POINT_ZERO_SIX,
    },
    {
      title: UIText.Modified_By,
      field: "modifiedUser",
      filterable: true,
      editable: false,
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ONE_FOUR,
    },
    {
      title: UIText.Modified_On,
      field: "modifiedDate",
      filterable: true,
      editable: false,
      headerCell: CustomHeaderCell,
      width: calculateWidth("modifiedDate", eodData),
    },
    {
      title: UIText.Remarks,
      field: "remarks",
      filterable: true,
      editable: false,
      headerCell: CustomHeaderCell,
    },
  ];

  return columns;
};

export const colsToShow = (
  eodData,
  ref,
  onChange,
  selectedItem,
  selectedMode,
  handleShipToLinkAction
) => {
  let columns = [];
  if (selectedMode === EODViewOptions.TANK) {
    columns = getTankColumns(
      eodData,
      ref,
      onChange,
      selectedItem,
      handleShipToLinkAction
    );
  } else {
    columns = getAuditGridColumns(eodData, handleShipToLinkAction);
  }
  return columns;
};

export const getEditableField = (bfxCluster, field) => {
  return bfxCluster
    ? field === "eodSales" || field === "eodStock"
    : field === "cummSalesBy15min" || field === "stockAt1145PM";
};

export const isBfxValid = (actualItem, changeItem, remarksUpdated) => {
  return (
    parseInt(actualItem.eodStock) !== parseInt(changeItem.eodStock) ||
    parseInt(actualItem.eodSales) !== parseInt(changeItem.eodSales) ||
    remarksUpdated
  );
};

export const isDachValid = (actualItem, changeItem, remarksUpdated) => {
  return (
    parseInt(actualItem.stockAt1145PM) !== parseInt(changeItem.stockAt1145PM) ||
    parseInt(actualItem.cummSalesBy15min) !==
      parseInt(changeItem.cummSalesBy15min) ||
    remarksUpdated
  );
};

export const isBfxFieldsChanged = (actualItem, changeItem, dataItem) => {
  return (
    (parseInt(actualItem.eodStock) !== parseInt(changeItem.eodStock) ||
      parseInt(actualItem.eodSales) !== parseInt(changeItem.eodSales)) &&
    dataItem?.remarks?.length > 0
  );
};

export const isDachFieldsChanged = (actualItem, changeItem, dataItem) => {
  return (
    (parseInt(actualItem.stockAt1145PM) !==
      parseInt(changeItem.stockAt1145PM) ||
      parseInt(actualItem.cummSalesBy15min) !==
        parseInt(changeItem.cummSalesBy15min)) &&
    dataItem?.remarks?.length > 0
  );
};

export const getEODSubmitApiPayload = (changedItems, originalData) => {
  const payload = changedItems?.map((changeItem) => {
    const email = store.getState().user?.user?.UserEmail;
    const actualItem = originalData?.find((item) => {
      const valuesSame =
        item.globalSiteId === changeItem.globalSiteId &&
        item.product === changeItem.product &&
        item.tank === changeItem.tank;
      return (
        item.rowKey === changeItem.rowKey &&
        item.date === changeItem.date &&
        valuesSame
      );
    });
    let updatedItem = {
      RowKey: changeItem?.rowKey,
      Country: changeItem?.country,
      Tank: changeItem?.tank,
      UserEmail: email,
      Date: changeItem?.date,
      SiteId: changeItem?.globalSiteId,
    };
    if (isBFXCluster()) {
      updatedItem = getBFXUpdatedItem(actualItem, changeItem, updatedItem);
    } else {
      updatedItem = getDEUpdatedItem(actualItem, changeItem, updatedItem);
    }

    updatedItem = getRemarkUpdatedItem(changeItem, updatedItem);
    return updatedItem;
  });

  return payload;
};

export const getRemarkUpdatedItem = (changeItem, updatedItem) => {
  if (changeItem?.remarks?.length > 0) {
    updatedItem = {
      ...updatedItem,
      Remarks: changeItem?.remarks,
    };
  }
  return updatedItem;
};

export const getDEUpdatedItem = (actualItem, changeItem, updatedItem) => {
  if (
    parseInt(actualItem?.stockAt1145PM) !== parseInt(changeItem?.stockAt1145PM)
  ) {
    updatedItem = {
      ...updatedItem,
      EODStock: parseInt(changeItem?.stockAt1145PM),
    };
  }
  if (
    parseInt(actualItem?.cummSalesBy15min) !==
    parseInt(changeItem?.cummSalesBy15min)
  ) {
    updatedItem = {
      ...updatedItem,
      EODSales: parseInt(changeItem?.cummSalesBy15min),
    };
  }

  return updatedItem;
};
export const getBFXUpdatedItem = (actualItem, changeItem, updatedItem) => {
  if (parseInt(actualItem?.eodStock) !== parseInt(changeItem?.eodStock)) {
    updatedItem = {
      ...updatedItem,
      EODStock: parseInt(changeItem?.eodStock),
    };
  }
  if (parseInt(actualItem?.eodSales) !== parseInt(changeItem?.eodSales)) {
    updatedItem = {
      ...updatedItem,
      EODSales: parseInt(changeItem?.eodSales),
    };
  }
  return updatedItem;
};

export const getEODFetchAPIRequestPayload = (
  fromDate,
  toDate,
  selectedShipTo,
  filteredTanks,
  filteredProducts,
  selectedTab,
  currentCountryCode
) => {
  const startDate = fromDate && getFormattedDate(fromDate);
  const endDate = toDate && getFormattedDate(toDate);

  const payload =
    selectedTab === EODViewOptions.AUDIT
      ? {
          shipTo: selectedShipTo,
          Country: currentCountryCode,
        }
      : {
          Country: currentCountryCode,
          RequestDateFrom: startDate,
          RequestDateTo: endDate,
          GlobalSiteIds: [selectedShipTo],
          TankGroups: filteredTanks,
          Materials: filteredProducts,
        };
  return payload;
};

export const checkEODMandatoryValuesSelection = (
  filter,
  filteredTanks,
  filteredProducts,
  dateSelected,
  selectedShipTo,
  selectedRegions,
  selectedTab
) => {
  const filterSelected =
    filter?.selectedShipTo && filteredTanks && filteredProducts;

  let valuesSelected = dateSelected && getSelectedCluster() && filterSelected;

  if (selectedTab === EODViewOptions.AUDIT) {
    valuesSelected = selectedShipTo && selectedRegions;
  }
  return valuesSelected;
};

export const formatEODResponseData = (responseData) => {
  const response = responseData?.map((item) => {
    const eodStock = parseInt(item?.eodStock);
    const stockAt1145PM = parseInt(item?.stockAt1145PM);
    const stockDiff = parseInt(item?.stockDiff);
    const eodSales = parseInt(item?.eodSales);
    const cummSalesBy15min = parseInt(item?.cummSalesBy15min);
    const salesDiff = parseInt(item?.salesDiff);
    const eodDeliveries = parseInt(item?.eodDeliveries);
    const cummDeliveriesBy15min = parseInt(item?.cummDeliveriesBy15min);
    const deliveryDiff = parseInt(item?.deliveryDiff);
    const eodVariance = parseInt(item?.eodVariance);
    const eodStockOld = parseInt(item?.eodStockOld);
    const eodSalesOld = parseInt(item?.eodSalesOld);
    const eodDeliveryOld = parseInt(item?.eodDeliveryOld);
    const totalTestVolume = parseInt(item?.totalTestVolume);
    const hasTestSales = totalTestVolume > 0 ? "Y" : "N";

    return {
      ...item,
      eodStock: !Number.isNaN(eodStock) ? eodStock : "",
      eodStockOld: !Number.isNaN(eodStockOld) ? eodStockOld : "",
      stockAt1145PM: !Number.isNaN(stockAt1145PM) ? stockAt1145PM : "",
      stockDiff: !Number.isNaN(stockDiff) ? stockDiff : "",
      eodSales: !Number.isNaN(eodSales) ? eodSales : "",
      eodSalesOld: !Number.isNaN(eodSalesOld) ? eodSalesOld : "",
      cummSalesBy15min: !Number.isNaN(cummSalesBy15min) ? cummSalesBy15min : "",
      salesDiff: !Number.isNaN(salesDiff) ? salesDiff : "",
      eodDeliveries: !Number.isNaN(eodDeliveries) ? eodDeliveries : "",
      eodDeliveryOld: !Number.isNaN(eodDeliveryOld) ? eodDeliveryOld : "",
      cummDeliveriesBy15min: !Number.isNaN(cummDeliveriesBy15min)
        ? cummDeliveriesBy15min
        : "",
      deliveryDiff: !Number.isNaN(deliveryDiff) ? deliveryDiff : "",
      eodVariance: !Number.isNaN(eodVariance) ? eodVariance : "",
      totalTestVolume: !Number.isNaN(totalTestVolume) ? totalTestVolume : "",
      hasTestSales: hasTestSales,
    };
  });
  return response;
};

export const getSelectedShipTo = (filter) => {
  return filter?.selectedShipTo?.[0].id === "all"
    ? "ALL"
    : filter?.selectedShipTo?.[0].id;
};

export const getFilteredProducts = (selectedProducts) => {
  return selectedProducts?.length > 0 ? selectedProducts : ["ALL"];
};

export const getFilteredTanks = (selectedTankIds) => {
  return selectedTankIds?.length > 0 ? selectedTankIds : ["ALL"];
};

export const getActualItem = (changeItem, originalData) => {
  return originalData?.find((item) => {
    const valuesSame =
      item.globalSiteId === changeItem.globalSiteId &&
      item.product === changeItem.product &&
      item.tank === changeItem.tank;
    return (
      item.rowKey === changeItem.rowKey &&
      item.date === changeItem.date &&
      valuesSame
    );
  });
};

export const isItemChanged = (actualItem, dataItem) => {
  let itemChanged = false;
  if (isBFXCluster()) {
    itemChanged =
      parseInt(actualItem.eodStock) !== parseInt(dataItem.eodStock) ||
      parseInt(actualItem.eodSales) !== parseInt(dataItem.eodSales);
  } else {
    itemChanged =
      parseInt(actualItem.stockAt1145PM) !== parseInt(dataItem.stockAt1145PM) ||
      parseInt(actualItem.cummSalesBy15min) !==
        parseInt(dataItem.cummSalesBy15min);
  }
  return itemChanged;
};
