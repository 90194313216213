import "./site-information.scss";
import React, { useEffect, useState } from "react";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid.jsx";
import { siteService } from "../../../../../services/sites.js";
import { useDispatch, useSelector, useStore } from "react-redux";
import {
  clearSiteData,
  persistSiteData,
  triggerNotification,
  updateDetails,
} from "../../../../../state-management/actions.js";
import { Loader } from "@progress/kendo-react-indicators";
import { Button } from "@progress/kendo-react-all";
import {
  getFormattedDate,
  getFormattedDateAndTime,
} from "../../../../../services/utils.js";
import { colsToShow, columnsToExport } from "./site-information-utils.js";
import { SiteBasicDetails } from "./site-details-screen.jsx";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel.jsx";
import { reportsService } from "../../../../../services/reports.js";
import ConfirmPopup from "../../../../components/confirmation-popup/confirmation-popup.js";
import { SiteTankPopupDetails } from "./site-tank-popup-details-screen.jsx";
import { SiteInfoScreen } from "../out-of-tolerance-trend-analysis/out-of-tolerance-site-info.jsx";
import {
  DEFAULT_INITIAL_PAGE_SIZE,
  getEmptyStrForNull,
  NUMBERS,
} from "../common-utils.js";
import SiteTankDetailsController from "./site-tank-details-controller.jsx";
import { UIText } from "../label-constants.js";

const CONFIRMATION_OPTION = {
  TANK_DELETE: 0,
  PUMP_DELETE: 1,
  SAVE_PUMP_DATA: 2,
};

const BoxItem = ({ item }) => {
  return (
    <div className="boxContainer">
      <span className="highlighter"></span>
      <span className="box-title">{item?.title}</span>
      <span className="box-count">{item?.count}</span>
    </div>
  );
};

const SiteInformationScreen = () => {
  const store = useStore();
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user?.user);
  const [showSiteDetails, setShowSiteDetails] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [siteStatuses, setSiteStatuses] = useState([]);
  const [regionList, setRegionList] = useState([]);
  useEffect(() => {
    const writeEnabled = user?.RoleId?.toString() === "3";
    setReadOnly(!writeEnabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const defaultCountry = useSelector(
    (state) => state?.reports?.selectedCountry
  );
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);
  const [siteData, setSiteData] = useState([]);
  const [currentSiteItem, setCurrentSiteItem] = useState();
  const [currentTankItem, setCurrentTankItem] = useState();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [fetchingDetails, setFetchingDetails] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [selectedTankItem, setSelectedTankItem] = useState();
  const [selectedTankPumpItem, setSelectedTankPumpItem] = useState();
  const [currentOffSet, setCurrentOffSet] = useState();
  const [showTankDetails, setShowTankDetails] = useState(false);
  const [addTankDetails, setAddTankDetails] = useState(false);
  const [currentOperation, setCurrentOperation] = useState();
  const [siteStatus, setSiteStatus] = useState({
    total: 0,
    active: 0,
    closed: 0,
    others: 0,
  });
  const [showSiteInfoModal, setShowSiteInfoModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState();

  store.subscribe(() => {
    const { selectedCountry } = store.getState().reports;
    setCurrentCountryCode(selectedCountry);
  });

  useEffect(() => {
    setRegionList([]);
    dispatch(clearSiteData());
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountryCode]);

  const fetchData = async () => {
    setLoading(true);
    await fetchSiteData();
    setLoading(false);
  };

  const fetchOtherData = async () => {
    setLoading(true);
    if (regionList?.length === 0) {
      await fetchRegionData();
    }

    if (siteStatuses?.length === 0) {
      await fetchSiteStatuses();
    }
    setLoading(false);
  };

  const cancelDetailsModal = () => {
    setShowSiteDetails(false);
  };

  const saveDetails = async (siteInfo) => {
    setCurrentSiteItem(siteInfo);
    setShowSiteDetails(false);
    let data = await fetchSiteData();
    data = data?.map((item) => {
      let updatedItem = item;
      if (item?.expanded) {
        updatedItem = {
          ...item,
          ...siteInfo,
          Country: siteInfo?.CountryCode,
          Plant: siteInfo?.PLANT,
          Sloc: siteInfo?.SLOC,
          expanded: true,
        };
      }
      return updatedItem;
    });

    setSiteData(data);
  };

  const cancelTankDetailsModal = () => {
    setShowTankDetails(false);
    setAddTankDetails(false);
  };

  const saveTankDetails = (siteInfo) => {
    setCurrentSiteItem(siteInfo);
    setShowTankDetails(false);
    setAddTankDetails(false);
    const updatedData = siteData?.map((item) =>
      item.GlobalSiteId === siteInfo.GlobalSiteId
        ? { ...item, ...siteInfo }
        : item
    );
    setSiteData(updatedData);
  };

  const formatResponse = (response, dataItem) => {
    const openingDate = getFormattedDate(new Date(response?.SiteOpeningDate));
    const closingDate = getFormattedDate(new Date(response?.SiteClosingDate));
    dataItem.City = response?.City ? response?.City : "";
    dataItem.OpeningDate = openingDate ? openingDate : "";
    dataItem.ClosingDate = closingDate ? closingDate : "";
    dataItem.NozzleInfo = response?.NozzleInfo;
    dataItem.OperatingHours = response?.OperatingHours;
    dataItem.TankInfo = response?.TankInfo?.map((tankInfo) => ({
      ...tankInfo,
      expanded: false,
    }));
    dataItem.Remarks = response?.Remarks ? response?.Remarks : "";
    dispatch(updateDetails(dataItem));
  };

  const updateGridState = (dataItem) => {
    const data = store.getState().reports.siteData;
    const newData = data.map((itemInfo) => {
      if (itemInfo.id === dataItem.id) {
        if (dataItem?.expand) {
          dataItem.expanded = true;
        }
        return dataItem;
      }

      return itemInfo;
    });
    return newData;
  };

  const fetchSiteDetails = async (dataItem) => {
    const response = await siteService.fetchSiteDetailsInfo(
      dataItem?.PartitionKey || dataItem?.Country,
      dataItem?.RowKey || dataItem?.GlobalSiteId
    );
    if (response) {
      formatResponse(response, dataItem);

      const newData = updateGridState(dataItem);
      setSiteData(newData);
    }
  };

  const updateItemData = async (item) => {
    const dataItem = item;
    setCurrentSiteItem(dataItem);
    if (
      !dataItem?.OperatingHours &&
      !dataItem?.TankInfo &&
      !dataItem?.NozzleInfo
    ) {
      setFetchingDetails(true);
      await fetchSiteDetails(dataItem);
      setFetchingDetails(false);
    }
    return dataItem;
  };

  const handleDetailsEdit = async (props) => {
    if (regionList?.length === 0 || siteStatuses?.length === 0) {
      await fetchOtherData();
    }
    let item = props;
    if (!props?.OperatingHours && !props?.TankInfo && !props?.NozzleInfo) {
      item = await updateItemData({ ...props });
    }
    setCurrentSiteItem(item);
    setShowSiteDetails(true);
  };

  const handleTankGroupEdit = (item) => {
    setCurrentTankItem(item);
    const updatedSiteData = siteData?.map((data) => {
      if (data?.GlobalSiteId === item?.GlobalSiteId) {
        data.expanded = true;
      }
      return data;
    });
    setSiteData(updatedSiteData);
    setShowTankDetails(true);
  };

  const tankPumpDeleteAction = async () => {
    setShowConfirmationPopup(false);
    const siteItem = siteData?.find(
      (data) => data?.GlobalSiteId === selectedTankItem?.GlobalSiteId
    );

    const tankDetails = siteItem?.TankInfo?.map((tank) => {
      const tankDetailsInfo = tank;
      if (
        tankDetailsInfo?.deviceID === selectedTankItem?.deviceID &&
        tankDetailsInfo?.tankName === selectedTankItem?.tankName
      ) {
        const { [selectedTankPumpItem?.pump]: _, ...pumpNozzle } =
          tankDetailsInfo.PumpNozzle;
        tankDetailsInfo.PumpNozzle = pumpNozzle;
      }
      return tankDetailsInfo;
    });
    siteItem.TankInfo = tankDetails;
    setSubmitting(true);
    const response = await siteService.updateSiteInfo(
      siteItem?.Country,
      siteItem?.GlobalSiteId,
      siteItem
    );
    if (
      response?.status >= NUMBERS.TWO_HUNDRED &&
      response?.status < NUMBERS.THREE_HUNDRED
    ) {
      dispatch(
        triggerNotification({
          type: {
            style: UIText.success,
            icon: true,
          },
          message: response?.data?.message || response?.data,
        })
      );
    } else {
      dispatch(
        triggerNotification({
          type: {
            style: UIText.error,
            icon: true,
          },
          message: UIText.Failed_to_save_site_information,
        })
      );
    }
    setSubmitting(false);
  };

  const tankDeleteAction = async (props) => {
    setShowConfirmationPopup(false);
    const siteItem = siteData?.find(
      (data) => data?.GlobalSiteId === props?.GlobalSiteId
    );

    const tankDetails = siteItem?.TankInfo?.filter((tank) => {
      const same1 =
        tank?.deviceID === props?.deviceID &&
        tank?.tankName === props?.tankName &&
        tank?.additionalProductInfo === props?.additionalProductInfo;

      const same2 =
        tank?.capacity === props?.capacity &&
        tank?.materialNo === props?.materialNo &&
        tank?.productCode === props?.productCode;

      const same3 =
        tank?.tankGroup === props?.tankGroup &&
        tank?.workCapacity === props?.workCapacity;

      const sameTank = same1 && same2 && same3;
      return !sameTank;
    });
    siteItem.TankInfo = tankDetails;
    setSubmitting(true);
    const response = await siteService.updateSiteInfo(
      siteItem?.Country,
      siteItem?.GlobalSiteId,
      siteItem
    );
    if (
      response?.status >= NUMBERS.TWO_HUNDRED &&
      response?.status < NUMBERS.THREE_HUNDRED
    ) {
      dispatch(
        triggerNotification({
          type: {
            style: UIText.success,
            icon: true,
          },
          message: response?.data?.message || response?.data,
        })
      );
    } else {
      dispatch(
        triggerNotification({
          type: {
            style: UIText.error,
            icon: true,
          },
          message: UIText.Unable_to_delete,
        })
      );
    }
    setSubmitting(false);
  };
  const handleTankGroupDelete = async (item, offset) => {
    const updatedSiteData = siteData?.map((data) => {
      if (data?.GlobalSiteId === item?.GlobalSiteId) {
        data.expanded = true;
      }
      return data;
    });
    setSiteData(updatedSiteData);
    setCurrentOffSet(offset);
    setConfirmationTitle(UIText.Confirmation);
    setConfirmationMessage(
      UIText.Are_you_sure_you_want_to_delete_this_Tank_Group
    );
    setCurrentOperation(CONFIRMATION_OPTION.TANK_DELETE);
    setSelectedTankItem(item);
    setShowConfirmationPopup(true);
  };

  const handleTankPumpDelete = async (tankItem, item, offset) => {
    setCurrentOffSet(offset);
    setConfirmationTitle(UIText.Confirmation);
    setConfirmationMessage(
      UIText.Are_you_sure_you_want_to_delete_this_Tank_Pump
    );
    setCurrentOperation(CONFIRMATION_OPTION.PUMP_DELETE);
    setSelectedTankItem(tankItem);
    setSelectedTankPumpItem(item);
    setShowConfirmationPopup(true);
    const updatedSiteData = siteData?.map((data) => {
      if (data?.GlobalSiteId === tankItem?.GlobalSiteId) {
        data.expanded = true;
        const tankDetails = data?.TankInfo?.map((tank) => {
          if (
            tank?.deviceID === tankItem?.deviceID &&
            tank?.tankName === tankItem?.tankName
          ) {
            tank.expanded = true;
          }
          return tank;
        });
        data.TankInfo = tankDetails;
      }
      return data;
    });
    setSiteData(updatedSiteData);
  };

  const handleTankGroupAdd = (props) => {
    setCurrentSiteItem(props?.siteItem);
    setCurrentTankItem(null);
    setShowTankDetails(true);
    setAddTankDetails(true);
  };

  const fetchSiteStatuses = async () => {
    const response = await siteService.fetchSiteStatuses();
    if (response) {
      setSiteStatuses(response);
    } else {
      dispatch(
        triggerNotification({
          type: {
            style: UIText.error,
            icon: true,
          },
          message: UIText.Unable_to_fetch_the_data,
        })
      );
    }
  };

  const fetchRegionData = async () => {
    try {
      const response = await reportsService.fetchRegionsData({
        country: currentCountryCode,
      });
      if (response) {
        setRegionList(response);
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: UIText.error,
              icon: true,
            },
            message: UIText.Unable_to_fetch_the_data,
          })
        );
      }
    } catch (error1) {
      dispatch(
        triggerNotification({
          type: {
            style: UIText.error,
            icon: true,
          },
          message: UIText.Server_error_Unable_to_submit_the_request,
        })
      );
    }
  };

  const updateSiteStatusCount = (item, siteStatusObj) => {
    const status = item?.SiteStatus;
    siteStatusObj.total += 1;
    if (status?.toLowerCase() === "active") {
      siteStatusObj.active += 1;
    } else if (status?.toLowerCase()?.includes("closed")) {
      siteStatusObj.closed += 1;
    } else {
      siteStatusObj.others += 1;
    }
  };

  const getWSMASupportedSiteFlag = (item) => {
    return item?.IsWSMASupportedSite === false ? "N" : "Y";
  };

  const getTimeStampValue = (item) => {
    return item?.ModifiedOn
      ? getFormattedDateAndTime(new Date(item?.ModifiedOn))
      : "";
  };

  const getExpandState = (shouldExpand, expanded) => {
    return shouldExpand === false ? false : expanded;
  };

  const getFormatSiteItem = (item, shouldExpand, expanded) => {
    return {
      ...item,
      Country: getEmptyStrForNull(item?.PartitionKey),
      PartitionKey: getEmptyStrForNull(item?.PartitionKey),
      RowKey: getEmptyStrForNull(item?.RowKey),
      Region: getEmptyStrForNull(item?.Region),
      City: getEmptyStrForNull(item?.City),
      IsRKSite: getEmptyStrForNull(item?.IsRKSite),
      Plant: getEmptyStrForNull(item?.PLANT),
      Sloc: getEmptyStrForNull(item?.SLOC),
      GlobalSiteId: getEmptyStrForNull(item?.RowKey),
      OBN: getEmptyStrForNull(item?.OBN),
      SiteId: getEmptyStrForNull(item?.SiteId),
      Name: getEmptyStrForNull(item?.Name),
      Remarks: getEmptyStrForNull(item?.Remarks),
      SiteStatus: getEmptyStrForNull(item?.SiteStatus),
      TankInfo: item?.TankInfo,
      NozzleInfo: item?.NozzleInfo,
      OperatingHours: item?.OperatingHours,
      IsWSMASupportedSite: getWSMASupportedSiteFlag(item),
      Timestamp: getTimeStampValue(item),
      expanded: getExpandState(shouldExpand, expanded),
    };
  };

  const fetchSiteData = async (shouldExpand = true) => {
    try {
      setLoading(true);
      const response = await siteService.fetchSiteList(currentCountryCode);
      if (response) {
        const siteStatusObj = {
          total: 0,
          active: 0,
          closed: 0,
          others: 0,
        };
        const data = response?.reduce((info, item) => {
          const expanded = currentSiteItem?.RowKey === item?.RowKey;
          updateSiteStatusCount(item, siteStatusObj);
          const formatterItem = getFormatSiteItem(item, shouldExpand, expanded);
          return [...info, formatterItem];
        }, []);

        data?.sort((a, b) => (a.Name > b.Name ? 1 : -1));
        setSiteStatus(siteStatusObj);
        setSiteData(data);
        dispatch(persistSiteData(data));
        setLoading(false);
        return data;
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: UIText.error,
              icon: true,
            },
            message: UIText.Unable_to_fetch_the_data,
          })
        );
      }
      return [];
    } catch (error1) {
      dispatch(
        triggerNotification({
          type: {
            style: UIText.error,
            icon: true,
          },
          message: UIText.Server_error_Unable_to_submit_the_request,
        })
      );
      setError(UIText.Server_error_Unable_to_submit_the_request);
      setLoading(false);
      setError("");
    }
    return [];
  };

  const exportData = async () => {
    setExporting(true);
    await siteService.downloadSiteExcel(currentCountryCode);
    setExporting(false);
  };

  const size = submitting ? "large" : "small";
  const showHeaderBox = () => {
    return (
      <div className="header-box-container">
        <BoxItem
          item={{
            title: UIText.Total_Sites,
            count: siteStatus?.total || "0",
          }}
        />
        <BoxItem
          item={{
            title: UIText.Total_Active_Sites,
            count: siteStatus?.active || "0",
          }}
        />
        <BoxItem
          item={{
            title: UIText.Total_Closed_Sites,
            count: siteStatus?.closed || "0",
          }}
        />
      </div>
    );
  };

  const exportHeader = () => {
    return (
      <span className="exportContainer">
        <Button
          className="exportButton"
          onClick={exportData}
          disabled={exporting}
        >
          {exporting ? UIText.Exporting : UIText.Export}
        </Button>
        <Button
          className="refreshButton"
          onClick={() => {
            fetchSiteData(false);
          }}
          disabled={loading}
        >
          {UIText.Refresh}
        </Button>
      </span>
    );
  };

  const handleShipToLinkAction = (siteId, country) => {
    setSelectedSite({ siteId: siteId, country: country });
    setShowSiteInfoModal(true);
  };

  const closeModal = () => {
    setShowSiteInfoModal(false);
  };

  const showSiteTankDetailsController = (props) => {
    return (
      <SiteTankDetailsController
        {...props}
        currentSiteItem={currentSiteItem}
        selectedTankItem={selectedTankItem}
        selectedTankPumpItem={selectedTankPumpItem}
        siteData={siteData}
        setSubmitting={setSubmitting}
        setSelectedTankPumpItem={setSelectedTankPumpItem}
        setSiteData={setSiteData}
        handleTankPumpDelete={handleTankPumpDelete}
        readOnly={readOnly}
        handleTankGroupEdit={handleTankGroupEdit}
        handleTankGroupDelete={handleTankGroupDelete}
        handleTankGroupAdd={handleTankGroupAdd}
        currentTankItem={currentTankItem}
      />
    );
  };

  return (
    <div className="container data-grid site-info-container">
      <div className="row ">
        {error && <span>{error}</span>}
        <div className="data-grid-container">
          <div className="header-container">
            {showHeaderBox()}
            {exportHeader()}
          </div>
          {showSiteInfoModal && (
            <SiteInfoScreen
              globalSiteId={selectedSite?.siteId}
              countryCode={selectedSite?.country || currentCountryCode}
              close={closeModal}
            />
          )}

          <div className="siteList-grid-container">
            <AppDataGrid
              style={{ height: "66vh" }}
              pageable={true}
              take={DEFAULT_INITIAL_PAGE_SIZE}
              filterable={true}
              fixedScroll={true}
              expandable={true}
              sortable={true}
              data={siteData}
              detailsContainer={(propValues) =>
                showSiteTankDetailsController(propValues)
              }
              columnsToShow={colsToShow({
                onEdit: handleDetailsEdit,
                handleShipToLinkAction: handleShipToLinkAction,
              })}
              columnsToExport={columnsToExport}
              updateItemData={updateItemData}
              expandChange={(data) => {
                setSiteData(data);
              }}
            />
          </div>
          {showSiteDetails && (
            <SiteBasicDetails
              selectedItem={currentSiteItem}
              cancel={cancelDetailsModal}
              save={saveDetails}
              readOnly={readOnly}
              siteStatuses={siteStatuses}
              regionList={regionList}
            />
          )}
          {!showSiteDetails && showTankDetails && (
            <SiteTankPopupDetails
              currentSiteItem={currentSiteItem}
              selectedItem={addTankDetails ? null : currentTankItem}
              cancel={cancelTankDetailsModal}
              save={saveTankDetails}
              isAddTank={addTankDetails}
            />
          )}
          {(loading || fetchingDetails) && (
            <LoadingPanel message={UIText.Fetching_the_data} />
          )}
          {(submitting || exporting) && (
            <span className="remarks-loader-container">
              <Loader
                size={size}
                themeColor="warning"
                type="converging-spinner"
              />
              {exporting && (
                <p className="loader-description">
                  {UIText.Exporting_the_data}
                </p>
              )}
            </span>
          )}
          {showConfirmationPopup && (
            <ConfirmPopup
              offset={currentOffSet}
              position={
                currentOperation === CONFIRMATION_OPTION.SAVE_PUMP_DATA
                  ? "top"
                  : "left"
              }
              isOpen={showConfirmationPopup}
              title={confirmationTitle}
              message={confirmationMessage}
              saveAction={() => {
                if (currentOperation === CONFIRMATION_OPTION.TANK_DELETE) {
                  tankDeleteAction(selectedTankItem);
                } else if (
                  currentOperation === CONFIRMATION_OPTION.PUMP_DELETE
                ) {
                  tankPumpDeleteAction();
                }
              }}
              cancelAction={(e) => {
                if (e.target.textContent !== "YES") {
                  setShowConfirmationPopup(false);
                }
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { SiteInformationScreen };
