import React from "react";
import { EOMSummaryScreen } from "./eom-summary-screen/eom-summary-screen";

const EOMSummaryContainer = () => {
  return (
    <>
      <div className="container user-management-screen-container eod-container">
        <div className="row content-container">
          <EOMSummaryScreen />
        </div>
      </div>
    </>
  );
};

export { EOMSummaryContainer };
