import { useEffect, useState } from "react";
import i18n from "../../../configs/i18n.json";
import { store } from "../../../state-management/store";

const Label = ({ i18nKey }) => {
  const [language, setLanguage] = useState(
    store.getState()?.user?.["language"] || "english"
  );

  const unsubscribe = store.subscribe(() => {
    const currentLanguage = store.getState()?.user?.["language"];
    setLanguage(currentLanguage);
  });
  useEffect(() => {
    return () => {
      unsubscribe();
    };
  }, [language, unsubscribe]);

  const getTranslation = () => {
    switch (language) {
      case "japanese":
        return i18n.japanese[i18nKey] ? i18n.japanese[i18nKey] : i18nKey;
      default:
        return i18n.english[i18nKey] ? i18n.english[i18nKey] : i18nKey;
    }
  };

  return <>{getTranslation()}</>;
};

export { Label };
