import { CustomHeaderCell } from "../account-for-deliveries/account-for-deliveries-utils";
import { ActionButtonCell, ActionType, ShipToLinkCell, ViewDetailCell } from "../common-grid-cells";
import { DECIMAL } from "../common-utils";
import { UIText } from "../label-constants";

export const colsToShow = (
  handleShipToLinkAction,
  handleRetryAction,
  setShowDetailsModal,
  setModalContent
) => {
  const columns = [
    {
      title: UIText.Shipment_Id,
      field: "ShipmentId",
      filterable: true,
      sortable: true,
      width: window.innerWidth * DECIMAL.POINT_ZERO_SEVEN,
      headerCell: CustomHeaderCell,
    },
    {
      title: UIText.ShipTo,
      field: "GlobalSiteId",
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return (
          <ShipToLinkCell {...props} linkAction={handleShipToLinkAction} />
        );
      },
      width: window.innerWidth * DECIMAL.POINT_ZERO_FIVE,
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Received_On,
      field: "receivedOn",
      filterable: true,
      sortable: true,
      width: window.innerWidth * DECIMAL.POINT_ONE,
      headerCell: CustomHeaderCell,
    },
    {
      title: UIText.Error,
      field: "ErrorReason",
      filterable: true,
      sortable: true,
      headerCell: CustomHeaderCell,
    },
    {
      title: UIText.Message,
      field: "Message",
      filterable: false,
      sortable: false,
      width: window.innerWidth * DECIMAL.POINT_ZERO_SIX,
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return (
          <ViewDetailCell
            {...props}
            title={UIText.View}
            setShowDetailsModal={setShowDetailsModal}
            setModalContent={setModalContent}
          />
        );
      },
    },
    {
      title: UIText.Description,
      field: "ErrorDescription",
      filterable: false,
      sortable: false,
      width: window.innerWidth * DECIMAL.POINT_ZERO_SIX,
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return (
          <ViewDetailCell
            {...props}
            title={UIText.View}
            setShowDetailsModal={setShowDetailsModal}
            setModalContent={setModalContent}
          />
        );
      },
    },
    {
      title: UIText.Action,
      field: "",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_SIX,
      cell: (props) => {
        return (
          <ActionButtonCell
            {...props}
            handleAction={handleRetryAction}
            title={UIText.Click_here_to_retry_the_failed_delivery}
            actionType={ActionType.Retry}
          />
        );
      },
    },
  ];
  return columns;
};
