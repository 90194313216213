import React, { useEffect, useState } from "react";
import { userService } from "../../../../../services/user";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { Loader } from "@progress/kendo-react-indicators";
import { DropdownFilterCell } from "../../../../components/dropdown-filter/dropdown-filter";
import { DEFAULT_PAGE_SIZE } from "../common-utils";
import { UIText } from "../label-constants";

let isMounted = false;

const UserLoginHistoryScreen = () => {
  const [loginHistory, setLoginHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  async function fetchLoginHistory() {
    const response = await userService.fetchLoginHistory();
    if (isMounted) {
      setLoginHistory(response ? response : []);
    }
    setLoading(false);
  }

  useEffect(() => {
    isMounted = true;
    fetchLoginHistory();
    return () => {
      isMounted = false;
    };
  }, []);

  const userRoles = Array.from(
    new Set(
      loginHistory.map((data) =>
        data.RoleName ? data.RoleName.toUpperCase() : ""
      )
    )
  );

  const userRoleFilterCell = (props) => {
    return (
      <DropdownFilterCell
        {...props}
        data={userRoles}
        defaultItem={UIText.Select_Role}
      />
    );
  };

  const colsToShow = [
    {
      title: UIText.First_Name,
      field: "FirstName",
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Last_Name,
      field: "LastName",
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Unique_User_ID,
      field: "UniqueUserId",
      sortable: true,
    },
    {
      title: UIText.User_Role,
      field: "RoleName",
      filterable: true,
      sortable: true,
      filterCell: userRoleFilterCell,
    },
    {
      title: UIText.Last_Login_Date,
      field: "LastLogInTime",
      sortable: true,
    },
  ];

  return (
    <>
      <div className="container data-grid">
        <div className="row ">
          {loading ? (
            <div className="loader-container">
              <Loader
                size="medium"
                themeColor="warning"
                type="converging-spinner"
              />
              <br />
              <h4>{UIText.Fetching_the_data}</h4>
            </div>
          ) : (
            <div className="data-grid-container">
              <AppDataGrid
                style={{ height: "71.5vh" }}
                exportable={true}
                take={DEFAULT_PAGE_SIZE}
                exportFileName={UIText.User_Login_History}
                filterable={true}
                pageable={true}
                data={loginHistory}
                columnsToShow={colsToShow}
                sortable={true}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export { UserLoginHistoryScreen };
