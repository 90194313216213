import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import "./tab-component.scss";

const TabComponent = (props) => {
  const selectedIndex = props?.activeIndex || 0;
  return (
    <div className="modeContainer">
      <ButtonGroup>
        {props?.items?.map((item) => {
          const selected = selectedIndex === item?.key;
          return (
            <Button
              togglable={true}
              key={item?.key || item.label}
              selected={selected}
              onClick={() => {
                props?.onChange(item?.key);
              }}
            >
              {item.label}
              {selected ? (
                <div className="tab-selected" />
              ) : (
                <div className="tabItem" />
              )}
            </Button>
          );
        })}
      </ButtonGroup>
    </div>
  );
};

export { TabComponent };
