import { Button } from "@progress/kendo-react-buttons";
import { CustomHeaderCell } from "../account-for-deliveries/account-for-deliveries-utils";
import { DECIMAL, isBFXCluster, isDachCluster } from "../common-utils";
import { ShipToLinkCell } from "../common-grid-cells";
import { UIText } from "../label-constants";

export const pumpValidator = (value) => {
  if (value === null || value?.length === 0) {
    return UIText.Pump_number_is_required;
  } else if (parseInt(value) < 1) {
    return UIText.Pump_number_should_be_greater_than_1;
  }
  return "";
};

export const nozzleValidator = (value) => {
  if (value === null || value?.length === 0) {
    return UIText.Nozzle_number_is_required;
  } else if (parseInt(value) < 1) {
    return UIText.Nozzle_number_should_be_greater_than_1;
  }
  return "";
};

const ViewSiteDetailsCell = (props) => {
  const { dataItem } = props;
  return (
    <td style={{ paddingTop: "0.8vh" }}>
      <Button
        className="cancel-btn"
        onClick={() => {
          props?.onEdit(dataItem);
        }}
      >
        {UIText.View_Details}
      </Button>
    </td>
  );
};

const EditTankGroupCell = (props) => {
  const { dataItem } = props;
  const EditIcon = require("../../../../../assets/edit-icon.png");
  return (
    <td style={{ paddingTop: "0.8vh" }}>
      <Button
        className="edit-btn"
        disabled={props?.readOnly}
        onClick={() => {
          props?.onEdit(dataItem);
        }}
      >
        <img
          className="editIcon"
          src={EditIcon}
          alt={UIText.Edit}
          title={UIText.Edit_Details}
        />
      </Button>
    </td>
  );
};

const DeleteTankGroupCell = (props) => {
  const { dataItem } = props;
  const DeleteIcon = require("../../../../../assets/delete-icon.png");
  return (
    <td style={{ paddingTop: "0.8vh" }}>
      <Button
        className="edit-btn"
        disabled={props?.readOnly}
        onClick={(event) => {
          const x = event.clientX;
          const y = event.clientY;
          const offset = {
            left: x,
            top: y,
          };
          props?.onDelete(dataItem, offset);
        }}
      >
        <img
          className="editIcon"
          src={DeleteIcon}
          alt={UIText.Delete}
          title={UIText.Delete_Details}
        />
      </Button>
    </td>
  );
};

const EditTankPumpCell = (props) => {
  const { pumpData, dataItem, tankItem, field } = props;
  const title = dataItem?.[field];
  const disabled =
    (props?.disabled && title !== UIText.Save) ||
    !!dataItem?.pumpValid ||
    !!dataItem?.nozzleValid;
  const EditIcon = require("../../../../../assets/edit-icon.png");
  const className = title === UIText.Edit ? "edit-btn" : "cancel-btn";
  return (
    <td style={{ paddingTop: "0.8vh" }}>
      <Button
        className={className}
        disabled={disabled || props?.readOnly}
        onClick={(event) => {
          if (title === UIText.Save) {
            const x = event.clientX;
            const y = event.clientY;
            const offset = {
              left: x,
              top: y,
            };
            props?.onSave(pumpData, offset);
          } else {
            props?.onEdit(tankItem, dataItem);
          }
        }}
      >
        {title === UIText.Edit ? (
          <img
            className="editIcon"
            src={EditIcon}
            alt={UIText.Edit}
            title={UIText.Edit_Details}
          />
        ) : (
          title
        )}
      </Button>
    </td>
  );
};

const DeleteTankPumpCell = (props) => {
  const { pumpData, dataItem, tankItem, field, disabled } = props;
  const title = dataItem?.[field];
  const DeleteIcon = require("../../../../../assets/delete-icon.png");
  const className = title === UIText.Delete ? "edit-btn" : "cancel-btn";
  return (
    <td style={{ paddingTop: "0.8vh" }}>
      <Button
        className={className}
        disabled={(disabled && title !== UIText.Cancel) || props?.readOnly}
        onClick={(event) => {
          const x = event.clientX;
          const y = event.clientY;
          const offset = {
            left: x,
            top: y,
          };

          if (title === UIText.Cancel) {
            props?.onCancel(pumpData, tankItem, disabled);
          } else {
            props?.onDelete(tankItem, dataItem, offset);
          }
        }}
      >
        {title === UIText.Delete ? (
          <img
            className="editIcon"
            src={DeleteIcon}
            alt={UIText.Delete}
            title={UIText.Delete_Details}
          />
        ) : (
          title
        )}
      </Button>
    </td>
  );
};

const AddTankGroupHeaderCell = (props) => {
  return (
    <Button
      className="add_tg-btn"
      disabled={props?.readOnly}
      onClick={() => {
        props?.onAdd(props);
      }}
    >
      {UIText.Add}
    </Button>
  );
};

const AddTankPumpHeaderCell = (props) => {
  const { disabled } = props;
  return (
    <Button
      className="add_tg-btn"
      disabled={disabled || props?.readOnly}
      onClick={() => {
        props?.onAdd();
      }}
    >
      {UIText.Add}
    </Button>
  );
};

export const tankPumpColsToShow = ({
  tankItem,
  pumpData,
  onEdit,
  onDelete,
  onSave,
  onCancel,
  onAdd,
  disableAdd: addingPump = false,
  readOnly,
}) => {
  let columns = [
    {
      title: UIText.Pump_No,
      field: "pump",
      filterable: true,
      sortable: true,
      editable: true,
      editor: "numeric",
      className: "numericInput",
      width: window.innerWidth * DECIMAL.POINT_ZERO_EIGHT,
    },
    {
      title: UIText.Nozzle_No,
      field: "nozzle",
      editable: true,
      sortable: true,
      editor: "numeric",
      className: "numericInput",
      width: window.innerWidth * DECIMAL.POINT_ZERO_EIGHT,
    },
  ];

  if (!readOnly) {
    columns = [
      ...columns,
      {
        title: "",
        field: "title",
        width: window.innerWidth * DECIMAL.POINT_ZERO_FOUR,
        headerCell: () => <></>,
        cell: (props) => {
          return (
            <EditTankPumpCell
              {...props}
              tankItem={tankItem}
              pumpData={pumpData}
              onEdit={onEdit}
              onSave={onSave}
              disabled={addingPump}
              readOnly={readOnly}
            />
          );
        },
      },
      {
        title: "",
        field: "subTitle",
        width: window.innerWidth * DECIMAL.POINT_ZERO_FOUR,
        headerCell: (props) => {
          return (
            <AddTankPumpHeaderCell
              {...props}
              onAdd={onAdd}
              tankItem={tankItem}
              pumpData={pumpData}
              disabled={addingPump}
              readOnly={readOnly}
            />
          );
        },
        cell: (props) => {
          return (
            <DeleteTankPumpCell
              {...props}
              tankItem={tankItem}
              pumpData={pumpData}
              onDelete={onDelete}
              onCancel={onCancel}
              disabled={addingPump}
              readOnly={readOnly}
            />
          );
        },
      },
    ];
  }

  return columns;
};

export const tankGroupColsToShow = ({
  onEdit,
  onDelete,
  onAdd,
  selectedItem,
  siteItem,
  readOnly,
}) => {
  let columns = [
    {
      title: UIText.Tank_Group,
      field: "tankGroup",
      headerCell: CustomHeaderCell,
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Tank,
      field: "TankNo",
      headerCell: CustomHeaderCell,
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Material_No,
      field: "materialNo",
      headerCell: CustomHeaderCell,
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Product,
      field: "productCode",
      headerCell: CustomHeaderCell,
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Product_Name,
      field: "additionalProductInfo",
      headerCell: CustomHeaderCell,
      filterable: true,
      sortable: true,
    },
  ];

  if (!readOnly) {
    columns = [
      ...columns,
      {
        title: "",
        field: "",
        width: window.innerWidth * DECIMAL.POINT_ZERO_THREE,
        cell: (props) => {
          return (
            <EditTankGroupCell {...props} onEdit={onEdit} readOnly={readOnly} />
          );
        },
      },
      {
        title: "",
        field: "deviceID",
        headerCell: (props) => {
          return (
            <AddTankGroupHeaderCell
              {...props}
              onAdd={onAdd}
              selectedItem={selectedItem}
              siteItem={siteItem}
              readOnly={readOnly}
            />
          );
        },
        width: window.innerWidth * DECIMAL.POINT_ZERO_FOUR,
        cell: (props) => {
          return (
            <DeleteTankGroupCell
              {...props}
              onDelete={onDelete}
              readOnly={readOnly}
            />
          );
        },
      },
    ];
  }

  return columns;
};

export const colsToShow = ({ onEdit, handleShipToLinkAction }) => {
  let columns = [
    {
      title: UIText.Site_Name,
      field: "Name",
      headerCell: CustomHeaderCell,
      filterable: true,
      width: window.innerWidth * DECIMAL.POINT_ONE,
      sortable: true,
    },
    {
      title: UIText.ShipTo,
      field: "GlobalSiteId",
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return (
          <ShipToLinkCell {...props} linkAction={handleShipToLinkAction} />
        );
      },
      filterable: true,
      sortable: true,
      width: window.innerWidth * DECIMAL.POINT_ZERO_SIX,
    },
    {
      title: UIText.OBN,
      field: "OBN",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_FIVE,
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Site_Id,
      field: "SiteId",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_FIVE,
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Plant,
      field: "Plant",
      width: window.innerWidth * DECIMAL.POINT_ZERO_FIVE,
      headerCell: CustomHeaderCell,
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.SLOC,
      field: "Sloc",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_FIVE,
      filterable: true,
      sortable: true,
    },
  ];
  if (isBFXCluster()) {
    columns = [
      ...columns,
      {
        title: UIText.Country,
        field: "PartitionKey",
        headerCell: CustomHeaderCell,
        width: window.innerWidth * DECIMAL.POINT_ZERO_FIVE,
        filterable: true,
        sortable: true,
      },
    ];
  }
  if (isDachCluster()) {
    columns = [
      ...columns,
      {
        title: UIText.Region,
        field: "Region",
        headerCell: CustomHeaderCell,
        width: window.innerWidth * DECIMAL.POINT_ZERO_FIVE,
        filterable: true,
        sortable: true,
      },
    ];
  }
  columns = [
    ...columns,
    {
      title: UIText.Status,
      field: "SiteStatus",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_FIVE,
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Last_Updated_On,
      field: "Timestamp",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_EIGHT,
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Modified_By,
      field: "ModifiedBy",
      headerCell: CustomHeaderCell,
      filterable: true,
      sortable: true,
    },
    {
      title: "",
      field: "",
      width: window.innerWidth * DECIMAL.POINT_ZERO_SEVEN,
      cell: (props) => {
        return <ViewSiteDetailsCell {...props} onEdit={onEdit} />;
      },
    },
  ];

  return columns;
};

export const LabelField = (props) => {
  return (
    <div className="deliveryRow">
      <span className="dateLbl">{props?.label}</span>
      <span className="dateVal">{props?.value}</span>
    </div>
  );
};

export const columnsToExport = [
  {
    title: UIText.Country,
    field: "Country",
    filterable: true,
  },
  {
    title: UIText.Region,
    field: "Region",
    filterable: true,
  },
  {
    title: UIText.City,
    field: "City",
    filterable: true,
  },
  {
    title: UIText.Plant,
    field: "Plant",
    filterable: true,
  },
  {
    title: UIText.SLOC,
    field: "Sloc",
    filterable: true,
  },
  {
    title: UIText.ShipTo,
    field: "GlobalSiteId",
    filterable: true,
  },
  {
    title: UIText.Site_Id,
    field: "SiteId",
    filterable: true,
  },
  {
    title: UIText.Site_Name,
    field: "Name",
    filterable: true,
  },
  {
    title: UIText.Status,
    field: "SiteStatus",
    filterable: true,
  },
  {
    title: UIText.Material_No,
    field: "materialNo",
    filterable: true,
  },
  {
    title: UIText.Tank_Code,
    field: "deviceID",
    filterable: true,
  },
  {
    title: UIText.Max_Fuel,
    field: "capacity",
    filterable: true,
  },
];
