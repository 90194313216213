import React from "react";
import { SiteInformationScreen } from "./site-information-screen/site-information-screen";

const SiteInformationScreenContainerV2 = () => {
  return (
    <>
      <div className="container site-info-container">
        <div className="row content-container">
          <SiteInformationScreen />
        </div>
      </div>
    </>
  );
};

export { SiteInformationScreenContainerV2 };
