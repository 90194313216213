import "./report-list.scss";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { triggerNotification } from "../../../../../state-management/actions";
import { Loader } from "@progress/kendo-react-indicators";
import { reportsService } from "../../../../../services/reports";
import { DatePicker, Button } from "@progress/kendo-react-all";
import { readOnlyUser } from "../../../../../services/utils";
import { NUMBERS } from "../common-utils";
import { UIText } from "../label-constants";

const IN_PROCESS_STATUS = "In Process";

const showReport = (
  reportsData,
  readOnly,
  reportLoading,
  setReportsData,
  handleSubmitAction
) => {
  const handleStartDateChange = (e, report) => {
    const updateDate = new Date(e?.value);

    const updateReports = reportsData?.map((item) => {
      if (item?.reportCode === report?.reportCode) {
        return {
          ...item,
          restoreStartDate: updateDate,
        };
      }
      return item;
    });

    setReportsData(updateReports);
  };

  const handleEndDateChange = (e, report) => {
    const updateDate = new Date(e?.value);
    const updateReports = reportsData?.map((item) => {
      if (item?.reportCode === report?.reportCode) {
        return {
          ...item,
          restoreEndDate: updateDate,
        };
      }
      return item;
    });

    setReportsData(updateReports);
  };

  if (reportsData && reportsData.length > 0) {
    const max = new Date();
    return (
      <table className="report-table">
        <thead className="table-header">
          <tr className="header-row">
            <td style={{ minWidth: "20vw", width: "30vw" }}>
              {UIText.Report_Name}
            </td>
            <td style={{ minWidth: "6vw", width: "30vw" }}>
              {UIText.Allowed_Maximum_History_Data_Duration_Days}
            </td>
            <td style={{ minWidth: "6vw" }}>{UIText.Allowed_Duration_Days}</td>
            <td style={{ minWidth: "11vw" }}>{UIText.Start_Date}</td>
            <td style={{ minWidth: "11vw" }}>{UIText.End_Date}</td>
            <td style={{ minWidth: "10vw" }}>
              {UIText.Historical_Data_Status}
            </td>
            {!readOnly && <td style={{ minWidth: "6vw" }}></td>}
          </tr>
        </thead>
        <tbody className="table-body">
          {reportsData.map((report) => {
            return (
              <tr
                className="report-row"
                key={`${report?.reporName}-${report?.reportCode}`}
              >
                <td>{report?.reporName}</td>
                <td>{report?.maxHistoryDataLimitInDays}</td>
                <td>{report?.thresholdDays}</td>
                <td>
                  <DatePicker
                    disabled={
                      report?.status === IN_PROCESS_STATUS || reportLoading
                    }
                    placeholder="dd/MMM/yyyy"
                    format={"dd-MMM-yyyy"}
                    value={new Date(report?.restoreStartDate)}
                    max={max}
                    onChange={(e) => {
                      handleStartDateChange(e, report);
                    }}
                  />
                </td>
                <td>
                  <DatePicker
                    disabled={
                      report?.status === IN_PROCESS_STATUS || reportLoading
                    }
                    placeholder="dd/MMM/yyyy"
                    format={"dd-MMM-yyyy"}
                    max={max}
                    value={new Date(report?.restoreEndDate)}
                    onChange={(e) => {
                      handleEndDateChange(e, report);
                    }}
                  />
                </td>
                <td>{report?.status}</td>
                {!readOnly && (
                  <td>
                    <Button
                      className="update-btn"
                      disabled={
                        report?.status === IN_PROCESS_STATUS || reportLoading
                      }
                      onClick={() => handleSubmitAction(report)}
                    >
                      {UIText.Submit}
                    </Button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
  return (
    <div className="no-report-container">
      <h3>{UIText.No_report_request_found}</h3>
    </div>
  );
};

const ReportRequestScreen = () => {
  const dispatch = useDispatch();

  const [reportsData, setReportsData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [reportLoading, setReportLoading] = useState(false);

  const [readOnly, setReadOnly] = useState(false);
  useEffect(() => {
    setReadOnly(readOnlyUser());
  }, []);

  const fetchReportData = async () => {
    try {
      const response = await reportsService.getRestoreData();
      setLoading(false);
      if (response) {
        setReportsData(response);
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: UIText.error,
              icon: true,
            },
            message: UIText.Unable_to_fetch_the_data,
          })
        );
      }
    } catch (error1) {
      dispatch(
        triggerNotification({
          type: {
            style: UIText.error,
            icon: true,
          },
          message: UIText.Server_error_Unable_to_submit_the_request,
        })
      );
      setError(UIText.Server_error_Unable_to_submit_the_request);
      setLoading(false);
      setError("");
    }
  };

  useEffect(() => {
    fetchReportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitRequest = async (report) => {
    setReportLoading(true);
    try {
      const status = await reportsService.saveRestoreData(report);
      setReportLoading(false);
      if (status >= NUMBERS.TWO_HUNDRED && status < NUMBERS.THREE_HUNDRED) {
        const data = reportsData?.map((item) => {
          if (item?.reportCode === report?.reportCode) {
            return {
              ...item,
              status: IN_PROCESS_STATUS,
            };
          }
          return item;
        });
        setReportsData(data);
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: UIText.error,
              icon: true,
            },
            message: UIText.Unable_to_submit_the_request,
          })
        );
      }
    } catch (error1) {
      dispatch(
        triggerNotification({
          type: {
            style: UIText.error,
            icon: true,
          },
          message: UIText.Server_error_Unable_to_submit_the_request,
        })
      );
      setReportLoading(false);
      setError(UIText.Server_error_Unable_to_submit_the_request);
      setError("");
    }
  };

  const handleSubmitAction = (report) => {
    const diffInMs =
      new Date(report?.restoreEndDate) - new Date(report?.restoreStartDate);
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    let message = "";
    if (diffInDays < 0) {
      message = UIText.End_Date_should_be_greater_than_Start_Date;
    } else if (diffInDays === 0) {
      message = UIText.End_Date_Start_Date_should_not_be_same;
    } else if (diffInDays > report?.maxHistoryDataLimitInDays) {
      message = UIText.Duration_should_not_be_greater_than_allowed_duration;
    }

    if (message?.length > 0) {
      dispatch(
        triggerNotification({
          type: {
            style: UIText.error,
            icon: true,
          },
          message: message,
        })
      );
      return;
    }
    submitRequest(report);
  };

  return (
    <div className="container data-grid site-info-container">
      <div className="row ">
        {error && <span>{error}</span>}
        {loading ? (
          <div className="centralized-loader-container">
            <Loader
              size="medium"
              themeColor="warning"
              type="converging-spinner"
            />
            <br />
            <h5 className="loader-text">{UIText.Fetching_the_data}</h5>
            <p className="loader-description">
              {UIText.This_might_take_a_minute_or_two}
            </p>
          </div>
        ) : (
          <div className="data-grid-container">
            {showReport(
              reportsData,
              readOnly,
              reportLoading,
              setReportsData,
              handleSubmitAction
            )}
            {reportLoading && (
              <Loader
                style={{ position: "absolute", left: "50%", top: "30%" }}
                size="large"
                themeColor="warning"
                type="converging-spinner"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export { ReportRequestScreen };
