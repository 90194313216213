import { Outlet } from "react-router-dom";
import { NavigationBar } from "../navbar/navbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import InactivityPopup from "../inactivity-popup/InactivityPopup";
import { manualDataService } from "../../../services/manual-data";
import { setClusterList } from "../../../state-management/actions";
import { THIRTY_MINS } from "../../pages/reports/components/common-utils";

const ProtectedRoutes = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [clusterDetails, setClusterDetails] = useState([]);
  const dispatch = useDispatch();
  const [lastActivity, setLastActivity] = useState(new Date().getTime());
  const handleActivity = () => {
    setLastActivity(new Date().getTime());
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const currentTime = new Date().getTime();
      const inactivityTime = currentTime - lastActivity;
      if (inactivityTime > THIRTY_MINS) {
        setIsPopupOpen(true);
      }
    }, 1000);

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);

    return () => {
      clearInterval(timer);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
    };
  }, [lastActivity]);

  const store = useStore();
  const [authenticated, setAuthenticated] = useState(
    store.getState()?.user?.user ? true : false
  );

  const getCountryList = (clusters) => {
    let itemId = 0;
    return Object?.keys(clusters)?.map((region) => {
      itemId += 1;
      return{
        text: region,
        id: itemId,
        parent: true,
        items: clusters?.[region]?.map((countryName) => {
          itemId += 1;
          return {
            id: itemId,
            text: countryName,
          };
        }),
      };
    });
  };

  async function fetchClusterDetails() {
    const response = await manualDataService.fetchClusterDetails();
    setClusterDetails(getCountryList(response));
    dispatch(setClusterList(response));
  }

  useEffect(() => {
    const authStatus = store.getState()?.user?.user ? true : false;
    setAuthenticated(authStatus);
    fetchClusterDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return authenticated ? (
    <>
      {isPopupOpen && (
        <InactivityPopup isOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} />
      )}
      <NavigationBar clusterDetails={clusterDetails} />
      <Outlet />
    </>
  ) : (
    <>
    {isPopupOpen && (
      <InactivityPopup isOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} />
    )}
    <NavigationBar clusterDetails={clusterDetails} />
    <Outlet />
  </>
  );
};

export { ProtectedRoutes };
