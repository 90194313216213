import { Button } from "@progress/kendo-react-all";
import { calculateWidth } from "../../eod-summary-screen/eod-summary-utils";
import { CustomHeaderCell } from "../../account-for-deliveries/account-for-deliveries-utils";
import { DECIMAL } from "../../common-utils";
import { ShipToLinkCell } from "../../common-grid-cells";
import { UIText } from "../../label-constants";

export const getActionParties = () => {
  return [
    {
      id: UIText.All,
      text: UIText.All,
    },
    {
      id: UIText.RSAA_WSFP,
      text: UIText.RSAA_WSFP,
    },
    {
      id: UIText.CWR,
      text: UIText.CWR,
    },
    {
      id: UIText.OTD,
      text: UIText.OTD,
    },
    {
      id: UIText.BTC,
      text: UIText.BTC,
    },
    {
      id: UIText.WS,
      text: UIText.WS,
    },
  ];
};

export const getStatusList = () => {
  return [
    {
      id: UIText.All,
      text: UIText.All,
    },
    {
      id: UIText.Open,
      text: UIText.Open,
    },
    {
      id: UIText.Closed,
      text: UIText.Closed,
    },
  ];
};

const ButtonCell = (props) => {
  const { dataItem, handleIssueUpdate } = props;
  return (
    <td className="editableCell">
      <span className="filter-button">
        <Button
          className="get-data-btn"
          onClick={() => {
            handleIssueUpdate(dataItem);
          }}
        >
          {UIText.Update}
        </Button>
      </span>
    </td>
  );
};

export const colsToShow = (
  issues,
  handleIssueUpdate,
  handleShipToLinkAction
) => {
  const columns = [
    {
      title: UIText.Incident_Id,
      field: "incidentId",
      filterable: true,
      sortable: true,
      headerCell: CustomHeaderCell,
      width: calculateWidth("incidentId", issues) * DECIMAL.POINT_NINE,
    },
    {
      title: UIText.ShipTo,
      field: "globalSiteId",
      filterable: true,
      sortable: true,
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return (
          <ShipToLinkCell {...props} linkAction={handleShipToLinkAction} />
        );
      },
      width: calculateWidth("globalSiteId", issues) * DECIMAL.ONE_POINT_TWO,
    },
    {
      title: UIText.Transaction_Date,
      field: "transactionDate",
      filterable: true,
      sortable: true,
      headerCell: CustomHeaderCell,
      width: calculateWidth("transactionDate", issues) * DECIMAL.ONE_POINT_TWO,
    },
    {
      title: UIText.Raised_Date,
      field: "raisedDate",
      filterable: true,
      sortable: true,
      headerCell: CustomHeaderCell,
      width: calculateWidth("raisedDate", issues) * DECIMAL.ONE_POINT_TWO,
    },
    {
      title: UIText.Nature_of_issue,
      field: "natureOfOpenIssue",
      filterable: true,
      sortable: true,
      headerCell: CustomHeaderCell,
    },
    {
      title: UIText.Status,
      field: "status",
      filterable: true,
      sortable: true,
      headerCell: CustomHeaderCell,
      width: calculateWidth("status", issues),
    },
    {
      title: UIText.Description,
      field: "issueDescription",
      filterable: true,
      sortable: true,
      headerCell: CustomHeaderCell,
    },
    {
      title: UIText.Action_Party,
      field: "actionParty",
      filterable: true,
      sortable: true,
      headerCell: CustomHeaderCell,
      width: calculateWidth("actionParty", issues) * DECIMAL.ONE_POINT_TWO,
    },
    {
      title: UIText.Actions,
      field: "",
      filterable: true,
      sortable: true,
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return <ButtonCell {...props} handleIssueUpdate={handleIssueUpdate} />;
      },
      width: window.innerWidth * DECIMAL.POINT_ZERO_EIGHT,
    },
  ];

  return columns;
};
