import {
  DECIMAL,
  isBFXCluster,
} from "../common-utils";
import { Button } from "@progress/kendo-react-buttons";
import { TrendViewMode } from "./out-of-tolerance-trend-analysis-utils";
import { getFormattedDateAndTime } from "../../../../../services/utils";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { UIText } from "../label-constants";

const getCumColumnWidth = (selectedTab) => {
  let width =
    selectedTab === TrendViewMode.MATERIAL
      ? DECIMAL.THREE_POINT_FOUR
      : DECIMAL.THREE_POINT_TWO;
  width = isBFXCluster() ? width * DECIMAL.POINT_EIGHT_FIVE : width;
  return `${width}vw`;
};

const getCumFlagWidth = (selectedTab) => {
  let width =
    selectedTab === TrendViewMode.MATERIAL
      ? DECIMAL.SIX_POINT_EIGHT
      : DECIMAL.SIX_POINT_FOUR;
  width = isBFXCluster() ? width * DECIMAL.POINT_EIGHT_FIVE : width;
  return `${width}vw`;
};

export const getOOTAHeaderColumns = (selectedTab, countValues) => (
  <div className="rightContainer">
    <div className="countContainer">
      <div
        className="counter"
        style={{
          width: getCumColumnWidth(selectedTab),
        }}
      >
        {countValues.gainCount}
      </div>
      <div
        className="counter"
        style={{
          width: getCumColumnWidth(selectedTab),
        }}
      >
        {countValues.lossCount}
      </div>
      <div
        className="counter"
        style={{
          width: getCumColumnWidth(selectedTab),
        }}
      >
        {countValues.fiveCumVariance}
      </div>
      <div
        className="counter"
        style={{
          width: getCumColumnWidth(selectedTab),
        }}
      >
        {countValues.fiveCumThruPut}
      </div>
      {isBFXCluster() && (
        <span className="countContainer">
          <div
            className="counter"
            style={{
              width: getCumColumnWidth(selectedTab),
            }}
          >
            {countValues.thirtyCumVariance}
          </div>
          <div
            className="counter"
            style={{
              width: getCumColumnWidth(selectedTab),
            }}
          >
            {countValues.thirtyCumThruPut}
          </div>
        </span>
      )}
    </div>
    <div className="labelContainer">
      <span
        className="labelFlag"
        style={{
          width: getCumColumnWidth(selectedTab),
        }}
      >
        {UIText.day_Gain_flag}
      </span>
      <span
        className="labelFlag"
        style={{
          width: getCumColumnWidth(selectedTab),
        }}
      >
        {UIText.day_Loss_flag}
      </span>
      <span className="cumulativeContainer">
        <span
          className="cumulativeFlag"
          style={{
            width: getCumFlagWidth(selectedTab),
          }}
        >
          {UIText.Five_Day_Cumulative}
        </span>
        <span className="labelContainer">
          <span
            className="cumulativeVar"
            style={{
              width: getCumColumnWidth(selectedTab),
            }}
          >
            {isBFXCluster()
              ? UIText.ONE_FIVE_ZERO_Cum_Var
              : UIText.TWO_FIVE_ZERO_Cum_Var}
          </span>
          <span
            className="cumulativeVar"
            style={{
              width: getCumColumnWidth(selectedTab),
            }}
          >
            {isBFXCluster()
              ? UIText.TWO_Percent_of_Throughput
              : UIText.THREE_Percent_of_Throughput}
          </span>
        </span>
      </span>
      {isBFXCluster() && (
        <span className="cumulativeContainer">
          <span
            className="cumulativeFlag"
            style={{
              width: getCumFlagWidth(selectedTab),
            }}
          >
            {UIText.THREE_ZERO_Day_Cumulative}
          </span>
          <span className="labelContainer">
            <span
              className="cumulativeVar"
              style={{
                width: getCumColumnWidth(selectedTab),
              }}
            >
              {UIText.SEVEN_FIVE_ZERO_Cum_Var}
            </span>
            <span
              className="cumulativeVar"
              style={{
                width: getCumColumnWidth(selectedTab),
              }}
            >
              {UIText.TWO_Percent_of_Throughput}
            </span>
          </span>
        </span>
      )}
    </div>
  </div>
);

export const getOOTAHeaderButtons = ({
  changedItems,
  fetchData,
  fetchingData,
  fetchTrendAnalysisReport,
  appliedFilters,
  exportData,
  exporting,
  trendAnalysisData,
  selectedTab,
  saveChanges,
  cancelChanges,
}) => {
  const saveColor = changedItems?.length > 0 && "#0092a5";
  const cancelColor = changedItems?.length > 0 && "#fbce07";
  const borderWidth = changedItems?.length > 0 && "0.15vw";
  return (
    <div className="buttonsContainer">
      <div className="buttonsLeftContainer">
        <Button
          className="get-data-btn"
          onClick={fetchData}
          disabled={fetchingData}
        >
          {fetchingData ? UIText.Fetching : UIText.Get_data}
        </Button>
        <Button
          className="refresh-btn"
          onClick={() => {
            fetchTrendAnalysisReport(true);
          }}
          disabled={!appliedFilters}
        >
          {UIText.Refresh}
        </Button>
        {selectedTab === TrendViewMode.MATERIAL && (
          <Button
            className="exportButton"
            onClick={exportData}
            disabled={exporting || !trendAnalysisData?.length}
          >
            {exporting ? UIText.Exporting : UIText.Export}
          </Button>
        )}
      </div>
      {selectedTab === TrendViewMode.MATERIAL && (
        <div className="submitButtonContainer">
          <button
            title={UIText.Save}
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base toolbar-button"
            onClick={saveChanges}
            style={{ borderColor: saveColor, borderWidth: borderWidth }}
            disabled={!changedItems || changedItems?.length === 0}
          >
            {UIText.Save}
          </button>
          <button
            title={UIText.Cancel}
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base toolbar-button"
            onClick={cancelChanges}
            style={{ borderColor: cancelColor, borderWidth: borderWidth }}
            disabled={!changedItems || changedItems?.length === 0}
          >
            {UIText.Cancel}
          </button>
        </div>
      )}
    </div>
  );
};

const DropDownCell = (props) => {
  const {
    dataItem,
    field,
    onChange,
    commentsOptions,
    selectedMode,
    disabled = false,
  } = props;

  const handleDropDownChange = (event) => {
    onChange({
      dataItem: { ...dataItem, [field]: event.target.value },
      field: field,
      syntheticEvent: event,
    });
  };
  const value = dataItem?.[field];
  const commentBy = dataItem?.commentBy;
  const commentOn = getFormattedDateAndTime(new Date(dataItem?.commentOn));
  const title = `${UIText.Commented_by} "${commentBy}" \n on ${commentOn}`;
  const userIcon =
    commentBy?.toLowerCase() === "wsma system"
      ? require("../../../../../assets/user-yellow.png")
      : require("../../../../../assets/user-green.png");

  const userIconClassName =
    commentBy?.toLowerCase() === "wsma system" ? "userWarningIcon" : "userIcon";
  return (
    <td style={{ paddingTop: "0.8vh" }}>
      {selectedMode === TrendViewMode.MATERIAL ? (
        <span className="commentsIconContainer">
          <DropDownList
            data={commentsOptions}
            value={value || UIText.Select}
            onChange={handleDropDownChange}
            disabled={disabled}
          />
          {value?.length > 0 && value !== UIText.Select ? (
            <Tooltip openDelay={100} position="left" anchorElement="target">
              <span className="userIconContainer">
                <img
                  className={userIconClassName}
                  src={userIcon}
                  alt="User"
                  title={title}
                />
              </span>
            </Tooltip>
          ) : (
            <span className="emptyItem"></span>
          )}
        </span>
      ) : (
        <div className="commentsContainer">{dataItem?.[field]}</div>
      )}
    </td>
  );
};

export const CommentsCell = (props) => {
  const { dataItem, field } = props;
  const value = dataItem?.[field];
  const historicalViewEnabled = props?.historicalViewEnabled;
  if (historicalViewEnabled) {
    return <td>{value}</td>;
  }
  return (
    <DropDownCell
      {...props}
      commentsOptions={props?.commentsOptions}
      selectedMode={props?.selectedMode}
      requestDate={props?.requestDate}
    />
  );
};

export const DateHeaderCell = (props) => {
  const columnTitle = props?.columnMenuWrapperProps?.column?.title;
  const field = props?.field;
  const blankCount = props?.blankCount?.[field];
  const blankValue = props?.index === 0 && blankCount;
  const className = props?.index === 0 && blankCount > 0 && "blankValue";

  return (
    <th {...props}>
      <div className="dateHeader">
        {columnTitle}
        {props.children}
        <Tooltip openDelay={100} position="left" anchorElement="target">
          <span title={UIText.Missing_Data} className={className}>
            {blankValue}
          </span>
        </Tooltip>
      </div>
    </th>
  );
};

export const CommentHeaderCell = (props) => {
  const columnTitle = props?.columnMenuWrapperProps?.column?.title;
  const missingOutOfToleranceCount = props?.missingOutOfTolerance?.["day0"];
  const missingCommentsCount = props?.missingCommentsCount;
  const className = missingOutOfToleranceCount > 0 && "missingValue";
  const missingCommentClass = missingCommentsCount > 0 && "missingValue";
  return (
    <th {...props}>
      <div className="dateHeader">
        {columnTitle}
        {props.children}
        <span className="comments-icon-container">
          {missingOutOfToleranceCount > 0 && (
            <Tooltip openDelay={100} position="left" anchorElement="target">
              <span title={UIText.Mismatch_in_gain_loss_site_configuration} className={className}>
                {missingOutOfToleranceCount}
              </span>
            </Tooltip>
          )}
          {missingCommentsCount > 0 && (
            <Tooltip openDelay={100} position="left" anchorElement="target">
              <span
                title={UIText.Total_uncommented_items}
                className={missingCommentClass}
              >
                {missingCommentsCount}
              </span>
            </Tooltip>
          )}
        </span>
      </div>
    </th>
  );
};

export const IconCell = (props) => {
  const { dataItem, field } = props;
  const value = Math.round(dataItem?.[field]);
  const dataVal = dataItem?.[field];
  const missingOutOfTolerance =
    dataItem?.dates?.[props?.index]?.missingOutOfTolerance;
  const iconClass = missingOutOfTolerance ? "errorIcon" : "warningIcon";
  const title = missingOutOfTolerance
    ? UIText.Mismatch_in_gain_loss_site_configuration
    : UIText.Missing_Data;
  const icon = missingOutOfTolerance
    ? require("../../../../../assets/error.png")
    : require("../../../../../assets/warning.png");
  return (
    <td style={{ paddingTop: "0.8vh" }}>
      {!dataVal && dataVal !== 0 ? (
        <Tooltip openDelay={100} position="left" anchorElement="target">
          <img className={iconClass} title={title} src={icon} alt="Warning" />
        </Tooltip>
      ) : (
        value
      )}
    </td>
  );
};

export const MissingToleranceIconCell = (props) => {
  const { dataItem, field } = props;
  const dataVal = dataItem?.[field];
  return (
    <td style={{ paddingTop: "0.8vh" }}>
      {!dataVal ? (
        <Tooltip openDelay={100} position="left" anchorElement="target">
          <img
            className="errorIcon"
            title={UIText.Mismatch_in_gain_loss_site_configuration}
            src={require("../../../../../assets/error.png")}
            alt="Warning"
          />
        </Tooltip>
      ) : (
        dataVal
      )}
    </td>
  );
};
