import { Button } from "@progress/kendo-react-all";
import { Popover } from "@progress/kendo-react-tooltip";
import "@progress/kendo-theme-default/dist/all.css";
import PropTypes from "prop-types";
import "./confirmation-popup.scss";
import { useEffect } from "react";

const defaultOffSet = {
  left: 100,
  top: 200,
};

const ConfirmPopup = ({
  offset = { defaultOffSet },
  position = "left",
  isOpen,
  title,
  message,
  saveAction,
  cancelAction,
  customStyle,
}) => {

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", cancelAction);
    } else {
      document.removeEventListener("mousedown", cancelAction);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
  
  return (
    <Popover
      offset={offset}
      show={isOpen}
      position={position}
      style={{ maxWidth: "20vw" }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span
          style={{
            ...customStyle,
            fontSize: "0.8vw",
            paddingBottom: "2vh",
            fontWeight: "bold",
            color: "#dd1d21",
          }}
        >
          {title}
        </span>
        {message?.length > 0 && (
          <span
            style={{
              fontSize: "0.8vw",
              paddingBottom: "2vh",
              textAlign: title?.length > 0 ? "left" : "center",
            }}
          >
            {message}
          </span>
        )}

        <div
          style={{
            display: "flex",
            paddingBottom: "1vh",
            justifyContent: "center",
          }}
        >
          <Button
            style={{
              fontSize: "0.8vw",
              marginRight: "0.5vw",
              padding: "0 1vw",
              background: "#198754",
              fontWeight: "bold",
              color: "white",
            }}
            onClick={saveAction}
          >
            YES
          </Button>
          <Button
            style={{
              fontSize: "0.8vw",
              marginRight: "0.5vw",
              padding: "0.5vh 1vw",
              fontWeight: "bold",
              background: "#dd1d21",
              color: "white",
            }}
            onClick={cancelAction}
          >
            No
          </Button>
        </div>
      </div>
    </Popover>
  );
};

ConfirmPopup.propTypes = {
  offset: PropTypes.object,
  position: PropTypes.string,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  saveAction: PropTypes.func,
  cancelAction: PropTypes.func,
};

export default ConfirmPopup;
