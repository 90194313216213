import { ShipToLinkCell } from "../common-grid-cells";
import { DECIMAL } from "../common-utils";
import { UIText } from "../label-constants";

export const getDeliveryColumns = (linkAction, deliveryCell, removeCell) => {
  return [
    {
      title: UIText.Delivery_Date,
      field: "DeliveryDate",
      filterable: true,
      width: window.innerWidth * DECIMAL.POINT_ONE,
    },
    {
      title: UIText.ShipTo,
      field: "ShipTo",
      filterable: false,
      cell: (props) => {
        return <ShipToLinkCell {...props} linkAction={linkAction} />;
      },
      width: window.innerWidth * DECIMAL.POINT_ONE,
    },
    {
      title: UIText.OutBound_Delivery_No,
      field: "ShipmentNumber",
      filterable: true,
      width: window.innerWidth * DECIMAL.POINT_ONE,
    },
    {
      title: UIText.Duplicate_Deliveries,
      filterable: false,
      cell: deliveryCell,
      width: window.innerWidth * DECIMAL.POINT_TWO,
    },
    {
      filterable: false,
      cell: removeCell,
    },
  ];
};
