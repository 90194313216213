// src/reducers/index.js

import { combineReducers } from 'redux';
import loginReducer from "./loginReducer";
import userReducer from "./userReducer";
import reportReducer from "./reportReducer";

const rootReducer = combineReducers({
  login: loginReducer,
  reports: reportReducer,
  user: userReducer,
});

export default rootReducer;
