import React from "react";
import "./editable-data-grid.scss";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { editableDataGridHelperService } from "./editable-data-grid-helper";
import { Button } from "@progress/kendo-react-buttons";
import PropTypes from "prop-types";

const editField = "inEdit";

const LinkCell = (props) => {
  const { field, dataItem } = props;
  return (
    <td>
      <a
        className="cell-link"
        target="_blank"
        rel="noopener noreferrer"
        href={dataItem[field]}
      >
        {"Link to SharePoint Site"}
      </a>
    </td>
  );
};

LinkCell.prototypes = {
  field: PropTypes.any,
  dataItem: PropTypes.any,
};

const EditableDataGrid = (props) => {
  const [data, setData] = React.useState([]);
  const [initialDataState, setInitialDataState] = React.useState([]);
  const [allowEditing, setAllowEditing] = React.useState(false);
  const [changesMade, setChangesMade] = React.useState(false);
  let payload = {};

  React.useEffect(() => {
    const dataWithId = props.gridData.map((item, index) => ({
      ...item,
      id: index,
      inEdit: true,
    }));
    setData(dataWithId);
    setInitialDataState(dataWithId);
    editableDataGridHelperService.setEditableDataGridData(dataWithId);
  }, [props]);

  React.useEffect(() => {
    if (JSON.stringify(data) === JSON.stringify(initialDataState)) {
      setChangesMade(false);
    } else {
      setChangesMade(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const itemChange = (event) => {
    const updatedData = data.map((item) =>
      item.id === event.dataItem.id
        ? { ...item, [event.field || ""]: event.value.toString() }
        : item
    );
    setData(updatedData);

    const {
      value,
      dataItem: { key },
    } = event;
    payload[key] = value;
  };

  const resetChanges = () => {
    setAllowEditing(false);
    payload = {};
    setData(initialDataState);
  };

  const startEditing = () => {
    setAllowEditing(true);
  };

  const saveUpdate = () => {
    setAllowEditing(false);
    const response = props.onDataUpdate(data);
    if (response) {
      setInitialDataState(data);
      setChangesMade(false);
    }
    payload = {};
  };

  const showLinkCell = (propValues, item) => (
    <LinkCell {...item} {...propValues} />
  );
  return (
    <>
      <div className="buttons-row">
        {!props?.nonEditable && (
          <Button className="button edit-button" onClick={startEditing}>
            Edit
          </Button>
        )}
      </div>
      <Grid
        style={props.style}
        data={data}
        onItemChange={itemChange}
        editField={editField}
      >
        {props?.fieldsToShow?.map((item) => {
          return (
            <Column
              key={item.field}
              field={item.field}
              title={item.title}
              editor={item.editor}
              editable={(item.editable && allowEditing) || false}
              cell={
                item.isLink && ((propValues) => showLinkCell(propValues, item))
              }
            />
          );
        })}
      </Grid>
      <div className="buttons-row">
        <Button
          disabled={!allowEditing}
          className="button data-grid-cancel-button"
          onClick={resetChanges}
        >
          Cancel
        </Button>
        <Button
          disabled={!changesMade}
          className="button data-grid-save-button"
          onClick={saveUpdate}
        >
          Save
        </Button>
      </div>
    </>
  );
};

LinkCell.prototypes = {
  gridData: PropTypes.array,
  map: PropTypes.func,
  onDataUpdate: PropTypes.any,
  nonEditable: PropTypes.bool,
  style: PropTypes.object,
  customCell: PropTypes.any,
  fieldsToShow: PropTypes.array,
};

export { EditableDataGrid };
