import "./failed-delivery.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { triggerNotification } from "../../../../../state-management/actions";
import { reportsService } from "../../../../../services/reports";
import { colsToShow } from "./failed-delivery-utils";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import {
  getDateString,
  getFormattedFullDate,
} from "../../../../../services/utils";
import { store } from "../../../../../state-management/store";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { SiteInfoScreen } from "../out-of-tolerance-trend-analysis/out-of-tolerance-site-info";
import { DEFAULT_PAGE_SIZE, NUMBERS } from "../common-utils";
import { DatePicker, Button } from "@progress/kendo-react-all";
import { FailedDeliveriesDetails } from "./failed-delivery-detail-ui";
import { UIText } from "../label-constants";

const FailedDeliveryScreen = () => {
  const dispatch = useDispatch();
  const [resetIndex, setResetIndex] = useState(false);
  const [selectedSite, setSelectedSite] = useState();
  const [filters, setFilters] = useState({
    selectedDate: new Date(),
    selectedDateStr: getDateString(new Date()),
  });
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [deliveriesData, setDeliveriesData] = useState([]);
  const [deliveriesDataLoading, setDeliveriesDataLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showSiteInfoModal, setShowSiteInfoModal] = useState(false);
  const defaultCountry = useSelector(
    (state) => state?.reports?.selectedCountry
  );
  const [currentCountry, setCurrentCountry] = useState(defaultCountry);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [modalContent, setModalContent] = useState();

  store.subscribe(() => {
    const { selectedCountry } = store.getState().reports;
    setCurrentCountry(selectedCountry);
  });

  useEffect(() => {
    fetchDeliveriesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilters((_prev) => ({
      selectedDate: new Date(),
      selectedDateStr: getDateString(new Date()),
    }));
    setDeliveriesData([]);
  }, [currentCountry]);

  const fetchDeliveriesData = async (refresh = false) => {
    const filter = refresh ? appliedFilters : filters;
    const valuesSelected = filter?.selectedDateStr;
    if (valuesSelected) {
      setDeliveriesDataLoading(true);
      try {
        const payload = {
          country: currentCountry,
          date: filter.selectedDateStr,
        };
        let response = await reportsService.fetchFailedDeliveries(payload);
        response = response?.map((item) => {
          const timeGenerated =
            item?.Timestamp && getFormattedFullDate(new Date(item?.Timestamp));
          return {
            ...item,
            receivedOn: timeGenerated,
          };
        });

        setDeliveriesData(response);
        if (!refresh) {
          setAppliedFilters(filter);
        }
      } catch (error1) {
        dispatch(
          triggerNotification({
            type: {
              style: UIText.error,
              icon: true,
            },
            message: UIText.Server_error_Unable_to_submit_the_request,
          })
        );
      }
      setDeliveriesDataLoading(false);
    }
  };

  const closeModal = () => {
    setShowSiteInfoModal(false);
  };

  const closeMessageModal = () => {
    setShowDetailsModal(false);
  };

  const onDateChange = (e) => {
    const selectedDate = new Date(e?.value);
    setFilters((prev) => {
      return {
        ...prev,
        selectedDate: selectedDate,
        selectedDateStr: getDateString(selectedDate),
      };
    });
  };

  const showHeaders = () => {
    return (
      <div className="header-container">
        <div className="filters-container">
          <span className="filter-date">
            <label htmlFor="date">{UIText.Date}</label>
            <span className="date-picker">
              <DatePicker
                format={"dd-MMM-yyyy"}
                value={filters.selectedDate}
                onChange={onDateChange}
              />
            </span>
          </span>

          <span className="filter-button">
            <Button
              className="get-data-btn"
              onClick={() => {
                fetchDeliveriesData();
                setResetIndex(!resetIndex);
              }}
            >
              {UIText.Get_data}
            </Button>
          </span>
          <span className="filter-button">
            <Button
              className="refresh-btn"
              onClick={() => {
                fetchDeliveriesData(true);
              }}
              disabled={!appliedFilters}
            >
              {UIText.Refresh}
            </Button>
          </span>
        </div>
      </div>
    );
  };

  const handleShipToLinkAction = (siteId) => {
    setSelectedSite({ siteId: siteId, country: currentCountry });
    setShowSiteInfoModal(true);
  };

  const handleRetryAction = async (item) => {
    setSubmitLoading(true);
    try {
      const payload = {
        country: currentCountry,
        rowKey: item.RowKey,
      };
      const response = await reportsService.retryFailedDelivery(payload);
      if (
        response?.status >= NUMBERS.TWO_HUNDRED &&
        response?.status < NUMBERS.THREE_HUNDRED
      ) {
        dispatch(
          triggerNotification({
            type: {
              style: UIText.success,
              icon: true,
            },
            message: UIText.Delivery_submitted_successfully,
          })
        );
        fetchDeliveriesData(true);
      }
    } catch (error1) {
      dispatch(
        triggerNotification({
          type: {
            style: UIText.error,
            icon: true,
          },
          message: UIText.Server_error_Unable_to_submit_the_request,
        })
      );
    }
    setSubmitLoading(false);
  };

  return (
    <div className="failed-delivery-screen-container data-grid">
      <div className="row ">
        <div className="data-grid-container">
          {showHeaders()}

          {showSiteInfoModal && (
            <SiteInfoScreen
              globalSiteId={selectedSite?.siteId}
              countryCode={selectedSite?.country}
              close={closeModal}
            />
          )}
          {showDetailsModal && (
            <FailedDeliveriesDetails
              data={modalContent}
              close={closeMessageModal}
            />
          )}
          <AppDataGrid
            resetIndex={resetIndex}
            setResetIndex={setResetIndex}
            take={DEFAULT_PAGE_SIZE}
            style={{ height: "73vh" }}
            pageable={true}
            filterable={true}
            data={deliveriesData}
            columnsToShow={colsToShow(
              handleShipToLinkAction,
              handleRetryAction,
              setShowDetailsModal,
              setModalContent
            )}
            sortable={true}
            expandable={false}
            noRecordsMessage={UIText.No_data_found_filter_text}
          />
          {deliveriesDataLoading && (
            <LoadingPanel message={UIText.Fetching_the_data} />
          )}
          {submitLoading && (
            <LoadingPanel message={UIText.Submitting_the_values} />
          )}
        </div>
      </div>
    </div>
  );
};

export { FailedDeliveryScreen };
