import "./eom-summary.scss";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { triggerNotification } from "../../../../../state-management/actions";
import { reportsService } from "../../../../../services/reports";
import { getMultiSelectTreeValue } from "@progress/kendo-react-dropdowns";
import { store } from "../../../../../state-management/store";
import {
  childColumnsToShow,
  colsToShow,
  LogDetails,
} from "./eom-summary-utils";
import {
  MultiSelectionDropDown,
  multiSelectionDropDownFields,
} from "../../../../components/multi-select-dropdown/multi-select-dropdown";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { SiteInfoScreen } from "../out-of-tolerance-trend-analysis/out-of-tolerance-site-info";
import {
  getAllSelectedOptions,
  getFilterRegions,
  getYearsFrom,
  getMonths,
  getTankGroupListForMaterial,
  getMonthsData,
} from "../../reportUtils";
import {
  DEFAULT_PAGE_SIZE,
  getSelectedCluster,
  isDachCluster,
  NUMBERS,
} from "../common-utils";
import { Button, DropDownList } from "@progress/kendo-react-all";
import {
  setProductDetailsWhenShipToChange,
  setShipToAndObnOnSiteNameChange,
  setShipToAndSiteNameOnOBNChange,
  setShipToDetailsWhenRegionChange,
  setSiteNameAndObnOnShipToChange,
} from "../filter-utils";
import ConfirmPopup from "../../../../components/confirmation-popup/confirmation-popup";
import { UIText } from "../label-constants";

const DropDownSelection = {
  ShipTo: 0,
  SiteName: 1,
  OBN: 2,
};

const EOMSummaryScreen = () => {
  const dispatch = useDispatch();
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [resetIndex, setResetIndex] = useState(false);
  const [currentField, setCurrentField] = useState(null);
  const [showSiteInfoModal, setShowSiteInfoModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState();
  const [masterData, setMasterData] = useState();
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [currentSubmitItem, setCurrentSubmitItem] = useState();
  const [currentLogItem, setCurrentLogItem] = useState();
  const [currentOffSet, setCurrentOffSet] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [modalContent, setModalContent] = useState();
  const [firstLoad, setFirstLoad] = useState(false);

  const getDefaultFilters = () => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1);
    return {
      selectedYear: getYearsFrom(NUMBERS.TWO_THOUSAND_TWENTY_THREE)?.[0],
      years: getYearsFrom(NUMBERS.TWO_THOUSAND_TWENTY_THREE),
      selectedMonth: {
        id: new Date().getMonth(),
        text: currentDate.toLocaleString("en-US", { month: "short" }),
      },
      months: getMonths(),
      selectedShipTo: null,
      selectedRegions: [],
      regions: getFilterRegions(masterData, false),
      selectedSiteName: null,
      selectedOBN: [],
      allOBNs: [],
      selectedProducts: [],
      products: [],
      selectedTanks: [],
      tankGroup: [],
      allSiteIds: [],
      allSiteNames: [],
    };
  };

  const [filters, setFilters] = useState(getDefaultFilters());
  const [eomSummaryData, setEomSummaryData] = useState([]);
  const [showError, setShowError] = useState(false);
  const [exporting, setExporting] = useState(false);
  const exportGridRef = useRef(null);
  const [error, setError] = useState("");
  const [filterDataLoading, setFilterDataLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [logsLoading, setLogsLoading] = useState(false);
  const defaultCountry = useSelector(
    (state) => state?.reports?.selectedCountry
  );
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);
  const [regionSelectedValues, setRegionSelectedValues] = useState(
    filters.selectedRegions
  );

  store.subscribe(() => {
    const { selectedCountry } = store.getState().reports;
    setCurrentCountryCode(selectedCountry);
  });

  const [shipToSelectedValues, setShipToSelectedValues] = useState(
    filters.selectedShipTo
  );

  const [siteNameSelectedValues, setSiteNameSelectedValues] = useState(
    filters.selectedSiteName
  );
  const [obnSelectedValues, setObnSelectedValues] = useState(
    filters.selectedOBN
  );

  const [productSelectedValues, setProductSelectedValues] = useState(
    filters?.selectedProducts
  );
  const [tankSelectedValues, setTankSelectedValues] = useState(
    filters?.selectedTanks
  );

  useEffect(() => {
    setFilters((_prev) => getDefaultFilters());
    setShipToSelectedValues([]);
    setRegionSelectedValues([]);
    fetchMasterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountryCode]);

  useEffect(() => {
    if (!firstLoad && filters.selectedTanks?.length > 0) {
      fetchEomSummaryData();
      setFirstLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedTanks]);

  useEffect(() => {
    const year = parseInt(filters?.selectedYear?.text);
    const months = getMonthsData(year, filters, false);
    const currentYear = new Date().getFullYear();

    let selectedMonth = filters.selectedMonth;
    const currentMonth = new Date().getMonth();
    if (year === currentYear && selectedMonth.id > currentMonth + 1) {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 1);
      selectedMonth = {
        id: currentDate.getMonth() + 1,
        text: currentDate.toLocaleString("en-US", { month: "short" }),
      };
    }

    setFilters((prev) => {
      return {
        ...prev,
        months: months,
        selectedMonth: selectedMonth,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedYear]);

  useEffect(() => {
    const regions = getFilterRegions(masterData, false, currentCountryCode);
    const selectedRegions = regions?.length > 0 ? [regions?.[0]] : [];

    setFilters((prev) => {
      return {
        ...prev,
        selectedRegions: selectedRegions,
        regions: regions,
      };
    });
    setRegionSelectedValues(selectedRegions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterData]);

  useEffect(() => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedRegions: regionSelectedValues,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionSelectedValues]);

  useEffect(() => {
    setShipToDetailsWhenRegionChange(
      filters,
      masterData,
      setFilters,
      setShipToSelectedValues,
      setSiteNameSelectedValues,
      setObnSelectedValues
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedRegions]);

  useEffect(() => {
    setProductDetailsWhenShipToChange(
      masterData,
      filters,
      setFilters,
      setProductSelectedValues
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.selectedShipTo]);

  useEffect(() => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedProducts: productSelectedValues,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSelectedValues]);

  const setTankValuesOnProductChange = () => {
    const tankGroupsData = getTankGroupListForMaterial(
      masterData,
      filters,
      true
    );
    const selectedTanks = tankGroupsData?.length > 0 ? [tankGroupsData[0]] : [];
    setFilters((prev) => {
      return {
        ...prev,
        tankGroup: tankGroupsData,
        selectedTanks: selectedTanks,
      };
    });

    setTankSelectedValues(selectedTanks);
  };

  useEffect(() => {
    setTankValuesOnProductChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedProducts]);

  useEffect(() => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedTanks: tankSelectedValues,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tankSelectedValues]);

  useEffect(() => {
    if (currentField === DropDownSelection.ShipTo) {
      setSiteNameAndObnOnShipToChange(
        filters,
        shipToSelectedValues,
        setFilters,
        setSiteNameSelectedValues,
        setObnSelectedValues
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipToSelectedValues]);

  useEffect(() => {
    if (currentField === DropDownSelection.SiteName) {
      setShipToAndObnOnSiteNameChange(
        filters,
        siteNameSelectedValues,
        setFilters,
        setShipToSelectedValues,
        setObnSelectedValues
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteNameSelectedValues]);

  useEffect(() => {
    if (currentField === DropDownSelection.OBN) {
      setShipToAndSiteNameOnOBNChange(
        filters,
        obnSelectedValues,
        setFilters,
        setShipToSelectedValues,
        setSiteNameSelectedValues
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obnSelectedValues]);

  const onShipToSelection = (event) => {
    setCurrentField(DropDownSelection.ShipTo);
    if (event.operation === "delete") {
      setShipToSelectedValues([]);
    } else {
      setShipToSelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.allSiteIds, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const onSiteNameSelection = (event) => {
    setCurrentField(DropDownSelection.SiteName);
    if (event.operation === "delete") {
      setSiteNameSelectedValues([]);
    } else {
      setSiteNameSelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.allSiteNames, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const onOBNSelection = (event) => {
    setCurrentField(DropDownSelection.OBN);
    if (event.operation === "delete") {
      setObnSelectedValues([]);
    } else {
      setObnSelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.allOBNs, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const onProductSelection = (event) => {
    setCurrentField(DropDownSelection.SiteName);
    if (event.operation === "delete") {
      setProductSelectedValues([]);
    } else {
      setProductSelectedValues((prev) => {
        const selectedValues = getAllSelectedOptions(event, prev);
        return getMultiSelectTreeValue(filters?.products, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        });
      });
    }
  };

  const onTankSelection = (event) => {
    if (event.operation === "delete") {
      setTankSelectedValues([]);
    } else {
      setTankSelectedValues((prev) => {
        const selectedValues = getAllSelectedOptions(event, prev);
        return getMultiSelectTreeValue(filters?.tankGroup, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        });
      });
    }
  };
  const onYearSelection = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedYear: event,
      };
    });
  };

  const onMonthSelection = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        selectedMonth: event,
      };
    });
  };

  const fetchMasterData = async () => {
    try {
      setEomSummaryData([]);
      setShowError(false);
      setFilterDataLoading(true);
      const response = await reportsService.fetchToleranceTrendMasterData({
        country: currentCountryCode,
      });
      setFilterDataLoading(false);
      if (response) {
        setMasterData(response);
      } else {
        dispatch(
          triggerNotification({
            type: {
              style: UIText.error,
              icon: true,
            },
            message: UIText.Unable_to_fetch_the_data,
          })
        );
      }
    } catch (error1) {
      dispatch(
        triggerNotification({
          type: {
            style: UIText.error,
            icon: true,
          },
          message: UIText.Server_error_Unable_to_submit_the_request,
        })
      );
      setError(UIText.Server_error_Unable_to_submit_the_request);
      setFilterDataLoading(false);
      setError("");
    }
  };

  const onRegionSelection = (event) => {
    if (event.operation === "delete") {
      setRegionSelectedValues([]);
    } else {
      setRegionSelectedValues((prev) => {
        const selectedValues = getAllSelectedOptions(event, prev);
        return getMultiSelectTreeValue(filters?.regions, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        });
      });
    }
  };

  const showHideLoaders = (show = false) => {
    setExporting(show);
  };
  const exportData = async () => {
    showHideLoaders(true);
    exportGridRef?.current?.exportDataInExcel &&
      (await exportGridRef.current.exportDataInExcel());

    setTimeout(showHideLoaders, 1000);
  };

  const validateResponse = (responseData) => {
    const response = responseData?.map((item) => {
      const eomStock = parseInt(item?.eomStock);
      const stockAt1145PM = parseInt(item?.stockAt1145PM);
      const stockDiff = parseInt(item?.stockDiff);
      const eomSales = parseInt(item?.eomSales);
      const cummSalesBy15min = parseInt(item?.cummSalesBy15min);
      const salesDiff = parseInt(item?.salesDiff);
      const eomDeliveries = parseInt(item?.eomDeliveries);
      const cummDeliveriesBy15min = parseInt(item?.cummDeliveriesBy15min);
      const deliveryDiff = parseInt(item?.deliveryDiff);
      const eomVariance = parseInt(item?.eomVariance);
      const eomStockOld = parseInt(item?.eomStockOld);
      const eomSalesOld = parseInt(item?.eomSalesOld);
      const eomDeliveryOld = parseInt(item?.eomDeliveryOld);
      const totalTestVolume = parseInt(item?.totalTestVolume);
      const hasTestSales = totalTestVolume > 0 ? "Y" : "N";

      return {
        ...item,
        eomStock: !Number.isNaN(eomStock) ? eomStock : "",
        eomStockOld: !Number.isNaN(eomStockOld) ? eomStockOld : "",
        stockAt1145PM: !Number.isNaN(stockAt1145PM) ? stockAt1145PM : "",
        stockDiff: !Number.isNaN(stockDiff) ? stockDiff : "",
        eomSales: !Number.isNaN(eomSales) ? eomSales : "",
        eomSalesOld: !Number.isNaN(eomSalesOld) ? eomSalesOld : "",
        cummSalesBy15min: !Number.isNaN(cummSalesBy15min)
          ? cummSalesBy15min
          : "",
        salesDiff: !Number.isNaN(salesDiff) ? salesDiff : "",
        eomDeliveries: !Number.isNaN(eomDeliveries) ? eomDeliveries : "",
        eomDeliveryOld: !Number.isNaN(eomDeliveryOld) ? eomDeliveryOld : "",
        cummDeliveriesBy15min: !Number.isNaN(cummDeliveriesBy15min)
          ? cummDeliveriesBy15min
          : "",
        deliveryDiff: !Number.isNaN(deliveryDiff) ? deliveryDiff : "",
        eomVariance: !Number.isNaN(eomVariance) ? eomVariance : "",
        totalTestVolume: !Number.isNaN(totalTestVolume) ? totalTestVolume : "",
        hasTestSales: hasTestSales,
      };
    });
    return response;
  };

  const getSelectedFilter = (refresh) => {
    return refresh ? appliedFilters : filters;
  };

  const getSelectedShipTo = (filter) => {
    return filter?.selectedShipTo?.[0]?.id === "all"
      ? "ALL"
      : filter?.selectedShipTo?.[0]?.localSiteId;
  };

  const getFilteredProducts = (selectedProducts) => {
    return selectedProducts?.length > 0 ? selectedProducts : ["ALL"];
  };

  const getFilteredTanks = (selectedTankIds) => {
    return selectedTankIds?.length > 0 ? selectedTankIds : ["ALL"];
  };

  const checkMandatoryValues = (
    filteredTanks,
    filteredProducts,
    selectedShipTo,
    selectedRegions
  ) => {
    const filterSelected =
      selectedRegions && selectedShipTo && filteredTanks && filteredProducts;

    const valuesSelected = getSelectedCluster() && filterSelected;

    return valuesSelected;
  };

  const getDataPayload = (
    selectedShipTo,
    filteredTanks,
    filteredProducts,
    selectedYear,
    selectedMonth
  ) => {
    let payload = {};
    if (currentCountryCode) {
      payload = { ...payload, CountryCode: currentCountryCode };
    }
    if (selectedYear) {
      payload = { ...payload, Year: selectedYear };
    }
    if (selectedMonth) {
      payload = { ...payload, Month: selectedMonth };
    }
    if (selectedShipTo && selectedShipTo !== "ALL") {
      payload = { ...payload, LegacySiteIds: [selectedShipTo] };
    }
    if (filteredTanks && filteredTanks?.[0] !== "ALL") {
      payload = { ...payload, TankGroupIds: filteredTanks };
    }
    if (filteredProducts && filteredProducts?.[0] !== "ALL") {
      payload = { ...payload, MaterialNumbers: filteredProducts };
    }

    return payload;
  };

  const getStockParentItem = (item) => {
    return {
      childItems: item?.childItems,
      globalSiteId: item?.globalSiteId,
      obn: item?.obn,
      partitionKey: item?.partitionKey,
      plant: item?.plant,
      requestDateUTC: item?.requestDateUTC,
      rowKey: item?.rowKey,
      siteId: item?.siteId,
      sloc: item?.sloc,
      totalStock: item?.totalStock > 0 ? Math.round(item.totalStock) : null,
    };
  };

  const getSummaryResponse = async (payload) => {
    const response = await reportsService.fetchEomSummaryData(payload);
    let stockData = [];
    response?.forEach((item) => {
      let childItems = [];
      const itemExist = stockData?.find(
        (stock) => stock.globalSiteId === item.globalSiteId
      );
      if (itemExist) {
        childItems = itemExist?.childItems ? itemExist?.childItems : [];
      }
      childItems = [
        ...childItems,
        {
          ...item,
          currentStock: item?.currentStock
            ? Math.round(item?.currentStock)
            : null,
        },
      ];
      item.childItems = childItems;
      item.totalStock = childItems.reduce(
        (total, childItem) => total + childItem.currentStock,
        0
      );
      if (itemExist) {
        stockData = stockData.map((stockItem) =>
          stockItem.globalSiteId === item.globalSiteId
            ? getStockParentItem(item)
            : stockItem
        );
      } else {
        stockData = [...stockData, getStockParentItem(item)];
      }
    });
    return stockData;
  };

  const fetchEomSummaryData = async (refresh = false) => {
    const filter = getSelectedFilter(refresh);
    const selectedShipTo = getSelectedShipTo(filter);
    const selectedYear = filter.selectedYear?.id;
    const selectedMonth = filter.selectedMonth?.id;

    const selectedRegions = filter?.selectedRegions?.map((region) =>
      region?.id === "all" ? "ALL" : region?.id
    );
    const selectedTankIds = filter?.selectedTanks?.map((tank) => {
      return tank?.id === "all" ? "ALL" : tank?.id;
    });
    const selectedProducts = filter?.selectedProducts?.map((product) =>
      product?.id === "all" ? "ALL" : product?.id
    );
    const filteredProducts = getFilteredProducts(selectedProducts);
    const filteredTanks = getFilteredTanks(selectedTankIds);

    const valuesSelected = checkMandatoryValues(
      filteredTanks,
      filteredProducts,
      selectedShipTo,
      selectedRegions
    );

    if (valuesSelected) {
      const payload = getDataPayload(
        selectedShipTo,
        filteredTanks,
        filteredProducts,
        selectedYear,
        selectedMonth
      );
      setShowError(false);
      setSubmitLoading(true);
      if (!refresh) {
        setAppliedFilters(filter);
      }
      try {
        let response = await getSummaryResponse(payload);
        if (response instanceof Array) {
          response?.sort((a, b) => new Date(b.date) - new Date(a.date));
          response = validateResponse(response);
        }

        setSubmitLoading(false);
        setEomSummaryData(response);
      } catch (error1) {
        dispatch(
          triggerNotification({
            type: {
              style: UIText.error,
              icon: true,
            },
            message: UIText.Server_error_Unable_to_submit_the_request,
          })
        );
        setError(UIText.Server_error_Unable_to_submit_the_request);
        setSubmitLoading(false);
        setError("");
      }
    } else {
      setShowError(true);
    }
  };

  const submitGSAPRequest = async () => {
    setSendLoading(true);
    try {
      const selectedYear = filters.selectedYear?.id;
      const selectedMonth = filters.selectedMonth?.id;
      const payload = {
        country: currentCountryCode,
        globalSiteId: currentSubmitItem?.globalSiteId,
        targetMonth: selectedMonth,
        targetYear: selectedYear,
      };
      const response = await reportsService.sendToGSAPStock(payload);
      if (
        response?.status >= NUMBERS.TWO_HUNDRED &&
        response?.status < NUMBERS.THREE_HUNDRED
      ) {
        dispatch(
          triggerNotification({
            type: {
              style: UIText.success,
              icon: true,
            },
            message: UIText.Stock_level_accepted_submitted_for_processing,
          })
        );
        fetchEomSummaryData(true);
      }
    } catch (error1) {
      dispatch(
        triggerNotification({
          type: {
            style: UIText.error,
            icon: true,
          },
          message: UIText.Server_error_Unable_to_submit_the_request,
        })
      );
    }
    setSendLoading(false);
  };

  const sendToGSAPAction = async (item, offset) => {
    setCurrentOffSet(offset);
    setShowConfirmationPopup(true);
    setCurrentSubmitItem(item);
  };

  const viewLogAction = async (item) => {
    setCurrentLogItem(item);
    setLogsLoading(true);
    try {
      const payload = {
        country: currentCountryCode,
        rowKey: item.rowKey,
      };
      const response = await reportsService.getMonthEndStockLogs(payload);
      if (
        response &&
        response?.status >= NUMBERS.TWO_HUNDRED &&
        response?.status < NUMBERS.THREE_HUNDRED
      ) {
        if (response?.status === NUMBERS.TWO_ZERO_FOUR) {
          dispatch(
            triggerNotification({
              type: {
                style: UIText.error,
                icon: true,
              },
              message: UIText.No_logs_available,
            })
          );
        } else {
          const logContent = {
            MessageId: response?.data?.MessageId,
            Timestamp: response?.data?.Timestamp,
            Message: response?.data?.Message,
            ApiResponse: response?.data?.ApiResponse,
          };
          setModalContent(logContent);
          setShowDetailsModal(true);
        }
      }
      setLogsLoading(false);
    } catch (error1) {
      dispatch(
        triggerNotification({
          type: {
            style: UIText.error,
            icon: true,
          },
          message: UIText.Server_error_Unable_to_submit_the_request,
        })
      );
      setLogsLoading(false);
    }
  };

  const showMaterialGrid = (details) => {
    const item = details.dataItem;
    const data = item?.childItems;
    return (
      <AppDataGrid
        style={{ maxHeight: "50vh", width: "40vw", borderColor: "#b8e2ea" }}
        take={10000000}
        pageable={false}
        filterable={true}
        data={data}
        columnsToShow={childColumnsToShow()}
        sortable={true}
      />
    );
  };

  const showGridDataView = () => (
    <AppDataGrid
      resetIndex={resetIndex}
      setResetIndex={setResetIndex}
      take={DEFAULT_PAGE_SIZE}
      style={{ height: "63vh", margin: "0 0.5vw 0.5vw 0.5vw" }}
      pageable={true}
      filterable={true}
      data={eomSummaryData}
      columnsToShow={colsToShow(
        handleShipToLinkAction,
        sendToGSAPAction,
        viewLogAction
      )}
      sortable={true}
      ref={exportGridRef}
      expandable={true}
      detailsContainer={(detail) => showMaterialGrid(detail)}
      exportFileName="EOM Summary"
    />
  );

  const getConfirmationMessage = () => {
    let message = UIText.Are_you_sure_you_want_to_send_the_month_end_stocks;
    if (currentSubmitItem?.totalStock) {
      message = `${message} (${currentSubmitItem?.totalStock}) to GSAP`;
    }
    if (currentSubmitItem?.globalSiteId) {
      message = `${message} for ShipTo ${currentSubmitItem?.globalSiteId}`;
    }
    return message;
  };

  const showTabContent = () => (
    <div>
      {showHeaderFilters()}
      {showHeaderButtons()}
      {showGridDataView()}
      {showConfirmationPopup && (
        <ConfirmPopup
          offset={currentOffSet}
          position="top"
          isOpen={showConfirmationPopup}
          title={""}
          customStyle={{ maxWidth: "20vw" }}
          message={getConfirmationMessage()}
          saveAction={() => {
            setShowConfirmationPopup(false);
            submitGSAPRequest();
          }}
          cancelAction={(e) => {
            if (e.target.textContent !== 'YES') {
              setShowConfirmationPopup(false);
            }
          }}
        />
      )}
    </div>
  );

  const showHeaderFilters = () => {
    return (
      <div className="header-container">
        <div className="filters-container">
          <span className="filter">
            <label htmlFor="year">{UIText.Year}</label>
            <DropDownList
              data={filters.years}
              textField="text"
              dataItemKey="id"
              onChange={(event) => {
                onYearSelection(event.target.value);
              }}
              value={filters.selectedYear}
              style={{
                width: "5vw",
                marginRight: "1vw",
                fontSize: "10px",
              }}
            />
          </span>
          <span className="filter">
            <label htmlFor="month">{UIText.Month}</label>
            <DropDownList
              data={filters.months}
              textField="text"
              filterable={true}
              dataItemKey="id"
              onChange={(event) => {
                onMonthSelection(event.target.value);
              }}
              value={filters.selectedMonth}
              style={{
                width: "5vw",
                marginRight: "1vw",
                fontSize: "10px",
              }}
            />
          </span>
          {filters.regions?.length > 1 && (
            <span className="filter">
              <label htmlFor="region">{UIText.Region}</label>
              <MultiSelectionDropDown
                data={filters.regions}
                values={regionSelectedValues}
                onChange={onRegionSelection}
                maxSelection={1}
                filterable={false}
                showError={showError || !filterDataLoading}
                customStyle={{
                  width: isDachCluster() ? "9vw" : "7vw",
                  minHeight: "3vh",
                }}
              />
            </span>
          )}

          <span className="filter">
            <label htmlFor="shipTo">{UIText.ShipTo}</label>
            <MultiSelectionDropDown
              data={filters.allSiteIds}
              values={shipToSelectedValues}
              onChange={onShipToSelection}
              filterable={true}
              showError={showError || !filterDataLoading}
              customStyle={{ width: "7vw", minHeight: "3vh" }}
              maxSelection={1}
            />
          </span>
          <span className="filter">
            <label htmlFor="siteName">{UIText.Site_Name}</label>
            <MultiSelectionDropDown
              data={filters.allSiteNames}
              values={siteNameSelectedValues}
              onChange={onSiteNameSelection}
              filterable={true}
              showError={showError || !filterDataLoading}
              customStyle={{
                width: "15vw",
                minHeight: "3vh",
              }}
              maxSelection={1}
            />
          </span>
          <span className="filter">
            <label htmlFor="obn">{UIText.OBN}</label>
            <MultiSelectionDropDown
              data={filters.allOBNs}
              values={obnSelectedValues}
              onChange={onOBNSelection}
              filterable={true}
              showError={showError || !filterDataLoading}
              customStyle={{ width: "6vw", minHeight: "3vh" }}
              maxSelection={1}
            />
          </span>
          <span className="filter">
            <label htmlFor="materialNo">{UIText.Material_No}</label>
            <MultiSelectionDropDown
              data={filters.products}
              values={productSelectedValues}
              onChange={onProductSelection}
              filterable={false}
              placeholder="Select ..."
              customStyle={{ width: "10vw", minHeight: "3vh" }}
            />
          </span>
          <span className="filter">
            <label htmlFor="tankGroup">{UIText.Tank_Group}</label>
            <MultiSelectionDropDown
              data={filters.tankGroup}
              values={tankSelectedValues}
              onChange={onTankSelection}
              filterable={false}
              placeholder="Select ..."
              customStyle={{ width: "7vw", minHeight: "3vh" }}
            />
          </span>
        </div>
      </div>
    );
  };

  const handleShipToLinkAction = (siteId, country) => {
    setSelectedSite({ siteId: siteId, country: country });
    setShowSiteInfoModal(true);
  };

  const closeModal = () => {
    setShowSiteInfoModal(false);
  };

  const closeMessageModal = () => {
    setShowDetailsModal(false);
  };

  const showHeaderButtons = () => {
    return (
      <div className="buttonsContainer">
        <div>
          <span className="filter-button">
            <Button
              className="get-data-btn"
              onClick={() => {
                fetchEomSummaryData();
                setResetIndex(!resetIndex);
              }}
              disabled={filterDataLoading}
            >
              {filterDataLoading ? UIText.Fetching : UIText.Get_data}
            </Button>
          </span>
          <span className="filter-button">
            <Button
              className="refresh-btn"
              onClick={() => {
                fetchEomSummaryData(true);
              }}
              disabled={!appliedFilters}
            >
              {UIText.Refresh}
            </Button>
          </span>
        </div>
        <div>
          <span className="filter-button">
            <Button
              className="get-data-btn"
              onClick={exportData}
              disabled={exporting || !eomSummaryData?.length}
            >
              {exporting ? UIText.Exporting : UIText.Export}
            </Button>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="eom-summary-screen-container data-grid">
      <div className="row ">
        {error && <span>{error}</span>}
        {showDetailsModal && (
          <LogDetails
            content={modalContent}
            close={closeMessageModal}
            globalSiteId={currentLogItem?.globalSiteId}
          />
        )}
        <div className="data-grid-container">
          {showTabContent()}
          {showSiteInfoModal && (
            <SiteInfoScreen
              globalSiteId={selectedSite?.siteId}
              countryCode={selectedSite?.country || currentCountryCode}
              close={closeModal}
            />
          )}
          {submitLoading && (
            <LoadingPanel message={UIText.Fetching_the_data} />
          )}
          {sendLoading && (
            <LoadingPanel message={UIText.Sending_to_GSAP} />
          )}
          {filterDataLoading && (
            <LoadingPanel message={UIText.Fetching_the_config_data} />
          )}
          {exporting && (
            <LoadingPanel message={UIText.Exporting_the_data} />
          )}
          {logsLoading && (
            <LoadingPanel message={UIText.Fetching_the_data}/>
          )}
        </div>
      </div>
    </div>
  );
};

export { EOMSummaryScreen };
