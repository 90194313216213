export const UIText = {
  Save: "Save",
  Cancel: "Cancel",
  Back: "Back",
  Add: "Add",
  Edit: "Edit",
  Remove: "Remove",
  No_Action_Needed: "No Action Needed",
  Delete: "Delete",
  Delete_Details: "Delete Details",
  Details: "Details",
  Edit_the_user_and_click_Save_to_consolidate_your_changes: "Edit the user and click 'Save' to consolidate your changes.",
  Delivery_updated_successfully: "Delivery updated successfully.",
  success: "success",
  info: "info",
  Delivery_update_failed: "Delivery update failed",
  Confirmed_Delivery_is_required: "Confirmed Delivery is required",
  Outbound_Delivery_is_required: "Outbound Delivery is required",
  Unable_to_submit_the_comments: "Unable to submit the comments.",
  AFD_Report: "AFD Report",
  No_data_found_filter_text:
    "No data found! Please try with different filter selections.",
  AFD_Audit_Report: "AFD Audit Report",
  Delivery_View: "Delivery View",
  Change_History: "Change History",
  Exporting: "Exporting...",
  Export: "Export",
  Export_to_PDF: "Export to PDF",
  OOATT: "OOATT",
  Add_New_Delivery: "Add New Delivery",
  Total_uncommented_items: "Total uncommented items",
  Select_date_range: "Select date range...",
  Region: "Region",
  ShipTo: "Ship To",
  OBN: "OBN",
  Tank_Code: "Tank Code",
  Max_Fuel: "Max. Fuel",
  Material_No: "Material No",
  Product_Name: "Product Name",
  Product: "Product Code",
  TG: "TG",
  Five_Days_Total: "5 Days Total",
  Ten_Days_Total: "10 Days Total",
  Three_zero_Days_Total: "30 Days Total",
  Missing_Data: "Missing Data",
  SLOC: "SLOC",
  Source: "Source",
  Commented_by: "Commented by",
  Last_Updated_On: "Last Updated On",
  Site_ID_OBN: "Site ID/OBN",
  Plant: "Plant",
  Threshold: "Threshold",
  Hours_Type: "Hours Type",
  Fetching: "Fetching...",
  Get_data: "Get data",
  Refresh: "Refresh",
  More: "More...",
  Select: "---- select ----",
  Document_Date: "Document Date",
  OutBound_Delivery_No: "OutBound Delivery No",
  Calculated_Delivery: "Calculated Delivery",
  Detected_Delivery: "Detected Delivery",
  Confirmed_Delivery: "Confirmed Delivery",
  Difference: "Difference",
  Comment: "Comment",
  Tank: "Tank",
  Fuel_Grade: "Fuel Grade",
  Entity: "Entity",
  Capacity: "Capacity",
  Operational_Capacity: "Operational Capacity",
  Commented_On: "Commented On",
  Modified_By: "Modified By",
  Modified_On: "Modified On",
  OutBound_Delivery_Ids: "OutBound Delivery Id's",
  Tank_Material_Level: "Tank /Material Level",
  Delivery_Start_Date: "Delivery Start Date",
  Start_Temperature: "Start Temperature",
  Delivery_End_Date: "Delivery End Date",
  End_Temperature: "End Temperature",
  Edit_Details: "Edit Details",
  Delivery_Start_Time: "Delivery Start Time",
  Delivery_End_Time: "Delivery End Time",
  Material_Level: "Material Level",
  Tank_Level: "Tank Level",
  Close: "Close",
  Delivery_Date: "Delivery Date",
  Submitting: "Submitting...",
  Submit_Delivery: "Submit Delivery",
  New_delivery_created_successfully: "New delivery created successfully",
  Unable_to_submit_the_delivery: "Unable to submit the delivery.",
  Gain_Loss_Overview: "Gain Loss Overview",
  Material_View: "Material View",
  Tank_Group_View: "Tank Group View",
  Site_Name: "Site Name",
  Tank_Group: "Tank Group",
  Search: "Search",
  Opening_Stock: "Opening Stock",
  Closing_Stock: "Closing Stock",
  Sales: "Sales",
  Loading: "Loading...",
  Deliveries: "Deliveries",
  Variance: "Variance",
  Fetch_site_data: "Fetch site data",
  STD_DEV_of_all_Variance: "STD DEV of all Variance",
  Half_of_STD_DEV_of_all_Variance: "Half of STD DEV of all Variance",
  STD_DEV_ignoring_high_Variance: "STD DEV ignoring high Variance",
  Half_of_STD_DEV_ignoring_high_Variance: "Half of STD DEV ignoring high Variance",
  Mean: "Mean",
  Others: "Others",
  Variance_Percent: "Variance%",
  All: "All",
  Open: "Open",
  Date_Closed: "Date Closed",
  View_History: "View History",
  Monitoring: "Monitoring",
  Closed: "Closed",
  Date: "Date",
  Stock: "Stock",
  Existing_Stock: "Existing Stock",
  Stock_in_liters: "Stock in liters",
  Sales_in_liters: "Sales in liters",
  Deliveries_in_liters: "Deliveries in liters.",
  Variance_in_liters: "Variance in liters.",
  Nature_Root_Cause_of_Issue: "Nature/Root Cause of Issue",
  Missing_Stock_variance_calculated:
    "Missing Stock, variance is calculated using current book stock.",
  Cum_Variance: "Cum. Variance",
  Cum_Sales: "Cum. Sales",
  Cumulative_variance_in_liters: "Cumulative variance in liters.",
  Cumulative_sales: "Cumulative sales",
  Variance_in_percent: "Variance in %.",
  Cum_Variance_percent: "Cum. Variance%",
  Cumulative_variance_in_percent: "Cumulative variance in %.",
  Delivery_IDs: "Delivery ID's",
  Data_not_received: "Data not received!",
  Removed_successfully: "Removed successfully",
  error: "error",
  Total_Sites: "Total Sites",
  Total_Active_Sites: "Total Active Sites",
  Total_Closed_Sites: "Total Closed Sites",
  Unable_to_fetch_user: "Unable to fetch user...",
  Something_went_wrong: "Something went wrong.",
  Unable_to_fetch_shell_token: "Unable to fetch shell token",
  Unable_to_fetch_non_shell_token: "Unable to fetch non-shell token",
  Unable_to_decode_the_token: "Unable to decode the token.",
  Unable_to_onboard_user: "Unable to onboard user",
  You_will_be_taken_to_different_website:
    "⚠️ You will be taken to a different website. Are you sure you wish to continue ?",
  Unable_to_identify_Shell_user: "Unable to identify Shell user.",
  Unable_to_identify_Non_Shell_user: "Unable to identify Non Shell user.",
  I_have_a_Shell_login_ID: "I have a Shell login ID",
  I_don_have_a_Shell_login_ID: "I don't have a Shell login ID",
  Showing_previously_added_comments: "Showing previously added comments",
  Please_select_a_range_to_view_comments_within_range:
    "Please select a range to view comments within a specific date range.",
  The_dates_represent_the_delivery_date:
    "The dates represent the delivery date( for accounting for deliveries ) or record date ( for out of tolerance trend report)",
  Get_Comments: "Get Comments",
  No_comments_to_display: "No comments to display",
  Please_select_a_date_range: "Please select a date range",
  Comment_Date: "Comment Date",
  History: "History",
  View: "View",
  Comments: "Comments",
  User_Email: "User Email",
  All_Tanks_for_this_Ship_To_number:
    "All Tanks for this Ship To number and this Material will be updated with your comment by the system. Click Reset if you want to select Tank instead of Material",
  Material_number_will_be_automatically:
    "Material number will be automatically updated by the system based on Ship To and Tank. Click Reset if you want to select Material instead of Tank",
  DELIVERY_DATE: "DELIVERY DATE",
  Record_Updated_Successfully: "Record Updated Successfully",
  Add_new_comment: "Add new comment",
  Please_use_the_form_below_to_add_new_comment:
    "Please use the form below to add a new comment.",
  Other_Comment: "Other Comment",
  Submit: "Submit",
  Reset: "Reset",
  Submit_and_Exit: "Submit and Exit",
  Please_select_date_range_to_see_the_graph:
    "Please select a date range for which you wish to see the graph",
  Start_Date: "Start Date",
  End_Date: "End Date",
  Generate_Chart: "Generate Chart",
  File_uploaded_successfully: "File uploaded successfully",
  Unable_to_upload_the_file: "Unable to upload the file",
  Add_Comment: "Add Comment",
  Show_previous_comments: "Show previous comments",
  View_Chart: "View Chart",
  Upload_a_file: "Upload a file",
  Uploading: "Uploading...",
  Records: "Records",
  Unit: "Unit",
  Unable_to_update_the_records: "Unable to update the records.",
  records_updated_successfully: "records updated successfully!",
  Unable_to_submit_the_request: "Unable to submit the request.",
  Server_error_Unable_to_submit_the_request:
    "Server error. Unable to submit the request",
  Processed_successfully: "Processed successfully",
  No_duplicate_deliveries_found: "No duplicate deliveries found",
  Duplicate_Deliveries: "Duplicate Deliveries",
  EOD_Summary: "EOD Summary",
  Tank_View: "Tank View",
  Information: "Information",
  You_have_unsaved_comments:
    "You have unsaved comments.. please save (or) cancel.",
  Edited_by: "Edited by",
  Previous_Value: "Previous Value",
  EOD_Stock: "EOD Stock",
  Stock_at_eleven_forty_five: "Stock at 11.45PM",
  Stock_Diff: "Stock Diff",
  EOD_Sales: "EOD Sales",
  Cumm_Sales_by_15Min: "Cumm Sales by 15Min",
  Sales_Diff: "Sales Diff",
  EOD_Deliveries: "EOD Deliveries",
  Has_Test_Sales: "Has Test Sales",
  Test_Sales: "Test Sales",
  Test_Sales_Volume: "Test Sales Volume",
  Deliveries_Diff: "Deliveries Diff",
  Remarks: "Remarks",
  Country: "Country",
  Internal_UID: "Internal UID",
  CIPM_Activation: "CIPM Activation",
  UUID: "UUID",
  Is_Active: "Is Active",
  Role: "Role",
  City: "City",
  Requested_Date: "Requested Date",
  Current_Value: "Current Value",
  Stock_level_accepted_submitted_for_processing:
    "Stock level accepted, submitted for processing.",
  No_logs_available: "No logs available!",
  Are_you_sure_you_want_to_send_the_month_end_stocks:
    "Are you sure you want to send the month end stocks",
  Year: "Year",
  Month: "Month",
  Failed_Delivery: "Failed Delivery",
  Delivery_submitted_successfully: "Delivery submitted successfully",
  Shipment_Id: "Shipment Id",
  Received_On: "Received On",
  Error: "Error",
  Message: "Message",
  Description: "Description",
  Click_here_to_retry_the_failed_delivery:
    "Click here to retry the failed delivery",
  Action: "Action",
  Ship_To_is_mandatory_field: "Ship To is mandatory field",
  MaterialCode_is_mandatory_field: "MaterialCode is mandatory field",
  Variance_is_mandatory_field: "Variance is mandatory field",
  Transaction_Date_is_mandatory_field: "Transaction Date is mandatory field",
  Issue_added_successfully: "Issue added successfully!",
  Add_New_Issue: "Add New Issue",
  Raised_Date: "Raised Date",
  Action_Party: "Action Party",
  CWR_Status: "CWR Status",
  Transaction_Date: "Transaction Date",
  Batch: "Batch",
  GSAP: "GSAP",
  Nature_of_scope: "Nature of scope",
  Issue_Description_Updates_250_Chars_Max:
    "Issue Description or Updates (250 Chars Max)",
  RSAA_WSFP: "RSAA/WSFP",
  CWR: "CWR",
  Area_of_Issue: "Area of Issue",
  OTD: "OTD",
  BTC: "BTC",
  WS: "WS",
  DFT: "DFT",
  Update: "Update",
  Incident_Id: "Incident Id",
  Nature_of_issue: "Nature of issue",
  Status: "Status",
  Closed_On: "Closed On",
  Actions: "Actions",
  Add_a_new_issue: "Add a new issue",
  From_Date: "From Date",
  To_Date: "To Date",
  Opening_Date: "Opening Date",
  Closing_Date: "Closing Date",
  Opening_Hours: "Opening Hours",
  CENTRAL_WET_STOCK: "CENTRAL WET STOCK",
  Issue_updated_successfully: "Issue updated successfully!",
  Issue_Description_or_Updates: "Issue Description or Updates",
  Material_Name: "Material Name",
  Site_Data_CWR: "Site Data/CWR",
  Name: "Name",
  Dates: "Dates",
  Updates: "Updates",
  DFT_Area_of_Issue: "DFT Area of Issue",
  DFT_Remarks: "DFT Remarks",
  DFT_Reference: "DFT Reference",
  DFT_Status: "DFT Status",
  DFT_Nature_Root_Cause_of_Issue: "DFT Nature/Root Cause of Issue",
  BTC_Area_of_Issue: "BTC Area of Issue",
  BTC_Remarks: "BTC Remarks",
  BTC_Reference: "BTC Reference",
  BTC_Status: "BTC Status",
  BTC_Nature_Root_Cause_of_Issue: "BTC Nature/Root Cause of Issue",
  Issue_description_not_available: "Issue description not available",
  Not_Available: "Not Available",
  Unexpected_error_occurred_Please_try_after_some_time:
    "Unexpected error occurred. Please try after some time. If error persists, please contact administrator.",
  Correlation_Id: "Correlation Id",
  Missing_Sites: "Missing Sites",
  Percent_of_Items_Investigated: "% of Items Investigated",
  Enable_Historical_View: "Enable Historical View",
  Site_Summary: "Site Summary",
  Investigation_Summary: "Investigation Summary",
  TOTAL: "TOTAL",
  Reconciliation_Issues: "Reconciliation Issues",
  Total: "Total",
  Basic_Information: "Basic Information",
  Tank_Details: "Tank Details",
  Site_Id: "Site Id",
  day_Gain_flag: "1-day Gain flag?",
  day_Loss_flag: "1-day Loss flag?",
  Five_Day_Cumulative: "5-Day Cumulative?",
  ONE_FIVE_ZERO_Cum_Var: "+/- 150 Cum. Var",
  TWO_FIVE_ZERO_Cum_Var: "+/- 250 Cum. Var",
  TWO_Percent_of_Throughput: "2% of Throughput",
  THREE_Percent_of_Throughput: "3% of Throughput",
  THREE_ZERO_Day_Cumulative: "30-Day Cumulative?",
  SEVEN_FIVE_ZERO_Cum_Var: "+/- 750 Cum. Var",
  One_day250: "1 day +250",
  One_day150: "1 day +150",
  One_day_minus_250: "1 day -250",
  One_day_minus_150: "1 day -150",
  Five_Day_Cumul_250_minus_250: "5D Cumul +250/-250",
  Five_Day_Cumul_Percent: "5D Cumul%",
  Three_Zero_D_Cumul_750_minus_750: "30D Cumul +750/-750",
  Three_Zero_D_Cumul_Percent: "30D Cumul%",
  Comments_saved_successfully: "Comments saved successfully.",
  Five_Day_Cumul250: "5D Cumul +/-250",
  Five_Day_Cumul_3_Th: "5D Cumul 3% Th",
  Three_Zero_D_Cumul_750: "30D Cumul +/-750",
  Three_Zero_D_Cumul_2_Percent_Th: "30D Cumul 2% Th",
  Highlights_Disclosures_500_Chars: "Highlights/Disclosures ( 500 Chars )",
  Could_not_get_records_for_the_selected_date:
    "Couldn't get records for the selected date",
  Mail_sent_successfully: "Mail sent successfully!",
  Trigger_Email_Monthly_MEC_Report: "Trigger Email (Monthly MEC Report)",
  Cannot_display_the_page_at_this_resolution:
    "Cannot display the page at this resolution",
  Either_zoom_out_to_100_or_switch_to_bigger_screen:
    "Either zoom out to 100% or switch to a bigger screen",
  Pre_Posting_Activities: "Pre Posting Activities",
  Pump_Register_Monthly_Exception_Report:
    "Pump Register Monthly Exception Report",
  Pump: "Pump",
  Dutch: "Dutch",
  French: "French",
  Comments_updated_successfully: "Comments updated successfully.",
  Show_only_sales_differences: "Show only sales differences",
  Sample_PDF_Report: "Sample PDF Report",
  Pump_Register_Report: "Pump Register Report",
  No_data_received: "No data received!",
  Meter_Reading: "Meter Reading",
  Quantity: "Quantity",
  Quantity_in_litres_at_15_C: "Quantity in litres at 15 °C",
  testing: "testing",
  Date_of_Pump_Index_Reading: "Date of Pump Index Reading",
  Date_Time_of_Fuel_Delivery: "Date Time of Fuel Delivery",
  Fuel_Quantity_Delivered_in_litres_at_15_C:
    "Fuel Quantity Delivered in litres at 15 °C",
  Reading: "Reading",
  Pump_Sales: "Pump Sales",
  Overall_Pump_Sales: "Overall Pump Sales",
  POS_Sales: "POS Sales",
  Sales_Quantity_from_POS: "Sales Quantity from POS",
  Sales_Difference: "Sales Difference",
  Variance_Pump_Sales: "Variance [Pump Sales]",
  Current_Date_Stock_Prev_Date_Stock_Overall_Pump_Sales_Deliveries:
    "Current Date Stock- Prev Date Stock+ Overall Pump Sales-Deliveries",
  Delivered_Quantity: "Delivered Quantity",
  Delivered_Quantity_in_litres_at_15_C: "Delivered - Quantity in litres at 15 °C",
  Overall_Pump_Sales_Quantity: "Overall Pump Sales Quantity",
  Overall_Pump_Sales_Quantity_in_litres_at_15_C: "Overall Pump Sales - Quantity in litres at 15 °C",
  Existing_Stock_Quantity: "Existing Stock Quantity",
  Existing_Stock_Quantity_in_litres_at_15_C: "Existing Stock - Quantity in litres at 15 °C",
  Monthly_Variance_Gain_Loss: "Monthly Variance (Gain/Loss)",
  Report_Name: "Report Name",
  Allowed_Maximum_History_Data_Duration_Days: "Allowed Maximum History Data Duration (Days)",
  Allowed_Duration_Days: "Allowed Duration (Days)",
  Historical_Data_Status: "Historical Data Status",
  No_report_request_found: "No report request found",
  End_Date_should_be_greater_than_Start_Date: "End Date should be greater than Start Date",
  End_Date_Start_Date_should_not_be_same: "End Date & Start Date should not be same",
  Duration_should_not_be_greater_than_allowed_duration: "Duration should not be greater than allowed duration. Please adjust Start/End date",
  This_might_take_a_minute_or_two: "This might take a minute or two, due to large amounts of data",
  Data_Completeness_Configuration_Check: "Data Completeness & Configuration Check",
  Fetching_the_config_data: "Fetching the config data.. please wait..",
  Exporting_the_data: "Exporting the data.. please wait..",
  Fetching_the_data: "Fetching the data.. please wait..",
  Updating_the_values: "Updating the values.. please wait..",
  Submitting_the_values: "Submitting the values.. please wait..",
  Sending_to_GSAP: "Sending to GSAP.. please wait..",
  Sunday: "Sunday",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  Active: 'Active',
  In_Active: "In-Active",
  Failed_to_save_site_information: "Failed to save site information!",
  Are_you_sure_you_want_to_update_the_site_information: "Are you sure you want to update the site information?",
  This_change_would_impact_the_existing_report_data: "This change would impact the existing report data and the report changes can’t be reverted.",
  Unable_to_delete: "Unable to delete.",
  Confirmation: "Confirmation",
  Are_you_sure_you_want_to_delete_this_Tank_Group: "Are you sure you want to delete this Tank Group?",
  Are_you_sure_you_want_to_delete_this_Tank_Pump: "Are you sure you want to delete this Tank Pump?",
  Unable_to_fetch_the_data: "Unable to fetch the data.",
  Pump_number_is_required: "Pump number is required",
  Pump_number_should_be_greater_than_1: "Pump number should be greater than 1",
  Nozzle_number_is_required: "Nozzle number is required",
  Nozzle_number_should_be_greater_than_1: "Nozzle number should be greater than 1",
  View_Details: "View Details",
  Pump_No: "Pump No",
  Nozzle_No: "Nozzle No",
  Add_a_New_Tank_Details: "Add a New Tank Details",
  Edit_Tank_Details: "Edit Tank Details",
  The_Pump_Nozzle_is_already_exist: "The Pump & Nozzle is already exist.",
  DAILY_RECONCILIATION_REPORT: "DAILY RECONCILIATION REPORT",
  ONE_FIVE_MINS_RECONCILIATION_REPORT: "15MINS RECONCILIATION REPORT",
  Tank_Leaks_Report: "Tank Leaks Report",
  Tank_Leaks_Daily_Report: "Tank Leaks Daily Report",
  Tank_Leaks_15_Mins_Report: "Tank Leaks 15 Mins Report",
  Tank_Leaks: "Tank Leaks",
  Daily_Report: "Daily Report",
  One_Five_Mins_Report: "15 Mins Report",
  Request_Date: "Request Date",
  Select_Role: "Select Role",
  First_Name: "First Name",
  Last_Name: "Last Name",
  Email: "Email",
  Unique_User_ID: "Unique User ID",
  User_Role: "User Role",
  Access: "Access",
  User_Audit_List: "User Audit List",
  Site_Access: "Site Access",
  This_user_has_all_access: "This user has all access.",
  Last_Login_Date: "Last Login Date",
  User_Login_History: "User Login History",
  Please_select_a_country: "Please select a country.",
  NO_DATA_FOUND: "NO DATA FOUND.",
  Shell_User: "Shell User",
  Non_Shell_User: "Non-Shell User",
  Registering_user_to_console: "Registering user to console...",
  Onboarding_user: "Onboarding user...",
  User_on_boarded_successfully: "User onboarded successfully",
  User_onboarding_failed: "User onboarding failed",
  Please_select_User_Type_you_want_to_onboard: "Please select User Type you want to onboard",
  User_Type_Mandatory: "User Type (Mandatory)",
  First_Name_Mandatory: "First Name (Mandatory)",
  First_Name_Should_Be_Less_Than_30: "First Name Should Be Less Than 30.",
  This_field_cant_be_empty: "This field can't be empty.",
  Last_Name_Mandatory: "Last Name (Mandatory)",
  Last_Name_Should_Be_Less_Than_30: "Last Name Should Be Less Than 30.",
  Email_Mandatory: "Email (Mandatory)",
  Please_enter_valid_email: "Please enter valid email.",
  Unique_User_ID_Mandatory: "Unique User ID (Mandatory)",
  Unique_Id_Should_Be_Less_Than_50: "Unique Id Should Be Less Than 50.",
  User_Role_Mandatory: "User Role (Mandatory)",
  Note: "Note",
  Site_Access_Mandatory: "Site Access (Mandatory)",
  Please_select_at_least_one_site: "Please select at least one site.",
  Enter_Wet_Stock_Data_for_Site_ID: "Enter Wet Stock Data for Site ID",
  Please_select_a_Site_ID_and_Date_manually: "Please select a Site ID and Date, for which you need to add data manually.",
  Loading_please_wait: "Loading please wait...",
  Changes_Saved_Successfully: "Changes Saved Successfully!",
  Manual_Data_Upload: "Manual Data Upload",
  Site_ID_Mandatory: "Site ID (Mandatory)",
  Date_Mandatory: "Date (Mandatory)",
  Please_select_a_site_id: "Please select a site id",
  Mismatch_in_gain_loss_site_configuration: "Mismatch in gain loss & site configuration",
  Take_a_tour: "Take a tour",
  The_WSM_analytics_application_comprises: "The WSM analytics application comprises of three main pages as described below.",
  One_Login_Page: "1. Login Page",
  The_login_page_lets_you_login_to_you_account: "The login page lets you login to your account. Based on your role at Shell you can choose the appropriate login option.",
  Two_Dashboard: "2. Dashboard",
  Once_logged_in_you_will_see_the_dashboard: "Once logged in, you'll see the dashboard. From here your can select the report you're interested in.",
  Three_Report_Page: "3. Report Page",
  The_report_page_gives_you_a_detailed_view: "The report page gives you a detailed view of the selected report. You can switch reports from the side panel on the left.",
  Preferences_saved_successfully: "Preferences saved successfully.",
  Error_occurred_while_saving_the_preference: "Error occurred while saving the preference.",
  User_Preferences: "User Preferences",
};
