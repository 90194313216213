import { triggerNotification } from "../../../../../state-management/actions";
import { store } from "../../../../../state-management/store";
import { NUMBERS } from "../common-utils";
import { UIText } from "../label-constants";

export const handleErrors = (response) => {
  const errorResponse = response?.response;
  const status = errorResponse?.data?.status;
  const errorDetail = errorResponse?.data?.detail;
  const errorObj = errorResponse?.data?.errors;
  if (status >= NUMBERS.FOUR_HUNDRED && status < NUMBERS.FIVE_HUNDRED) {
    if (errorDetail) {
      store.dispatch(
        triggerNotification({
          type: {
            style: UIText.error,
            icon: true,
          },
          message: errorDetail,
        })
      );
    } else if (errorObj) {
      Object.keys(errorObj)?.forEach((errorKey) => {
        const message = errorObj?.[errorKey]?.[0];
        if (message?.length > 0) {
          store.dispatch(
            triggerNotification({
              type: {
                style: UIText.error,
                icon: true,
              },
              message: message,
            })
          );
        }
      });
    }
  } else {
    const message = UIText.Unexpected_error_occurred_Please_try_after_some_time;
    store.dispatch(
      triggerNotification({
        type: {
          style: UIText.error,
          icon: true,
        },
        message: message,
      })
    );
  }
};
