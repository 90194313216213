import React from "react";
import {
  Grid,
  GridCell,
  GridColumn,
  GridToolbar,
} from "@progress/kendo-react-grid";
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup,
} from "@progress/kendo-react-excel-export";
import { GridPDFExport } from "@progress/kendo-react-pdf";

const data = [
  {
    Title: "Net Posted Deliveries",
    Rolling12monthCount: " ",
    Rolling12monthValue: 2686747539,
    yearToDateCount: " ",
    yearToDateVolume: 2237946044,
    octoberCount: " ",
    octoberVolume: 2237946044,
    SeptemberCount: " ",
    SeptemberVolume: 2237946044,
    augustCount: " ",
    augustVolume: 2237946044,
    julyCount: " ",
    julyVolume: 2237946044,
    juneCount: " ",
    juneVolume: 2237946044,
    mayCount: " ",
    mayVolume: 2237946044,
    aprilCount: " ",
    aprilVolume: 2237946044,
    marchCount: " ",
    marchVolume: 2237946044,
    febCount: " ",
    febVolume: 2237946044,
    janCount: " ",
    janVolume: 2237946044,
  },
  {
    Title: "Net Posted Sales",
    Rolling12monthCount: " ",
    Rolling12monthValue: 2686747539,
    yearToDateCount: " ",
    yearToDateVolume: 2237946044,
    octoberCount: " ",
    octoberVolume: 2237946044,
    SeptemberCount: " ",
    SeptemberVolume: 2237946044,
    augustCount: " ",
    augustVolume: 2237946044,
    julyCount: " ",
    julyVolume: 2237946044,
    juneCount: " ",
    juneVolume: 2237946044,
    mayCount: " ",
    mayVolume: 2237946044,
    aprilCount: " ",
    aprilVolume: 2237946044,
    marchCount: " ",
    marchVolume: 2237946044,
    febCount: " ",
    febVolume: 2237946044,
    janCount: " ",
    janVolume: 2237946044,
  },
  {
    Title: "Net Adjusted Sales",
    Rolling12monthCount: " ",
    Rolling12monthValue: 2686747539,
    yearToDateCount: " ",
    yearToDateVolume: 2237946044,
    octoberCount: " ",
    octoberVolume: 2237946044,
    SeptemberCount: " ",
    SeptemberVolume: 2237946044,
    augustCount: " ",
    augustVolume: 2237946044,
    julyCount: " ",
    julyVolume: 2237946044,
    juneCount: " ",
    juneVolume: 2237946044,
    mayCount: " ",
    mayVolume: 2237946044,
    aprilCount: " ",
    aprilVolume: 2237946044,
    marchCount: " ",
    marchVolume: 2237946044,
    febCount: " ",
    febVolume: 2237946044,
    janCount: " ",
    janVolume: 2237946044,
  },
  {
    Title: "Physical Stock	",
    Rolling12monthCount: " ",
    Rolling12monthValue: 2686747539,
    yearToDateCount: " ",
    yearToDateVolume: 2237946044,
    octoberCount: " ",
    octoberVolume: 2237946044,
    SeptemberCount: " ",
    SeptemberVolume: 2237946044,
    augustCount: " ",
    augustVolume: 2237946044,
    julyCount: " ",
    julyVolume: 2237946044,
    juneCount: " ",
    juneVolume: 2237946044,
    mayCount: " ",
    mayVolume: 2237946044,
    aprilCount: " ",
    aprilVolume: 2237946044,
    marchCount: " ",
    marchVolume: 2237946044,
    febCount: " ",
    febVolume: 2237946044,
    janCount: " ",
    janVolume: 2237946044,
  },
  {
    Title: "Book Stock",
    Rolling12monthCount: " ",
    Rolling12monthValue: 2686747539,
    yearToDateCount: " ",
    yearToDateVolume: 2237946044,
    octoberCount: " ",
    octoberVolume: 2237946044,
    SeptemberCount: " ",
    SeptemberVolume: 2237946044,
    augustCount: " ",
    augustVolume: 2237946044,
    julyCount: " ",
    julyVolume: 2237946044,
    juneCount: " ",
    juneVolume: 2237946044,
    mayCount: " ",
    mayVolume: 2237946044,
    aprilCount: " ",
    aprilVolume: 2237946044,
    marchCount: " ",
    marchVolume: 2237946044,
    febCount: " ",
    febVolume: 2237946044,
    janCount: " ",
    janVolume: 2237946044,
  },
  {
    id: "netGain/Loss",
    Title: "Net Posted Gain/Loss",
    Rolling12monthCount: " ",
    Rolling12monthValue: 2686747539,
    yearToDateCount: " ",
    yearToDateVolume: 2237946044,
    octoberCount: " ",
    octoberVolume: 2237946044,
    SeptemberCount: " ",
    SeptemberVolume: 2237946044,
    augustCount: " ",
    augustVolume: 2237946044,
    julyCount: " ",
    julyVolume: 2237946044,
    juneCount: " ",
    juneVolume: 2237946044,
    mayCount: " ",
    mayVolume: 2237946044,
    aprilCount: " ",
    aprilVolume: 2237946044,
    marchCount: " ",
    marchVolume: 2237946044,
    febCount: " ",
    febVolume: 2237946044,
    janCount: " ",
    janVolume: 2237946044,
  },
  {
    Title: "% of Net Posted Gain/Loss to Net Posted Sales",
    Rolling12monthCount: " ",
    Rolling12monthValue: 2686747539,
    yearToDateCount: " ",
    yearToDateVolume: 2237946044,
    octoberCount: " ",
    octoberVolume: 1395946,
    SeptemberCount: " ",
    SeptemberVolume: 5664849,
    augustCount: " ",
    augustVolume: 2237946044,
    julyCount: " ",
    julyVolume: 2237946044,
    juneCount: " ",
    juneVolume: 2237946044,
    mayCount: " ",
    mayVolume: 2237946044,
    aprilCount: " ",
    aprilVolume: 2237946044,
    marchCount: " ",
    marchVolume: 2237946044,
    febCount: " ",
    febVolume: 2237946044,
    janCount: " ",
    janVolume: 2237946044,
  },
];

const ExporToExcel = () => {
  let gridPDFExport;
  const exportPDF = () => {
    if (gridPDFExport !== null) {
      gridPDFExport.save();
    }
  };

  const renderGridCell = (props) => {
    if (props.field === "Title") {
      return (
        <td
          style={{
            fontWeight: "bolder",
            padding: "  20px 20px",
            textAlign: "center",
          }}
        >
          {props.dataItem[props.field] || "--"}
        </td>
      );
    }
    if (
      props.dataItem.Title === "% of Net Posted Gain/Loss to Net Posted Sales"
    ) {
      return (
        <td
          style={{
            fontWeight: "bolder",
            padding: "  20px 20px",
            textAlign: "center",
            color: "red",
          }}
        >
          {props.dataItem[props.field]}
        </td>
      );
    }
    if (props.dataItem.Title === "Net Adjusted Sales") {
      return (
        <td
          style={{
            fontWeight: "bolder",
            padding: "  20px 20px ",
            textAlign: "center",
            color: "red",
          }}
        >
          {props.dataItem[props.field]}
        </td>
      );
    }
    if (props.dataItem.Title === "Net Posted Sales") {
      return (
        <td
          style={{
            fontWeight: "bolder",
            padding: "  20px 20px",
            textAlign: "center",
            color: "red",
          }}
        >
          {props.dataItem[props.field]}
        </td>
      );
    }
    return (
      <td
        title={props.dataItem[props.field]}
        className="grid-container-td"
        style={{ padding: "  20px 20px", textAlign: "center" }}
      >
        {props.dataItem[props.field] || "--"}
      </td>
    );
  };
  const _export = React.useRef(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };
  const grid = (
    <Grid
      style={{
        height: "720px",
      }}
      sortable={true}
      pageable={{
        buttonCount: 4,
        pageSizes: true,
      }}
      data={data}
    >
      <GridToolbar>
        <button
          title="Export Excel"
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
          onClick={excelExport}
        >
          Export to Excel
        </button>
        <button
          title="Export PDF"
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
          onClick={exportPDF}
        >
          Export PDF
        </button>
      </GridToolbar>
      <GridColumn>
        <GridCell
          className="grid-cotainer"
          width={"150px"}
          style={{ height: "25px" }}
          field="Title"
          title="Title"
          cell={(props) => renderGridCell(props)}
        />
      </GridColumn>
      <GridColumn
        style={{ padding: 0, paddingLeft: "58px" }}
        className="grid-cotainer-main-colomn"
        title="Rolling 12 month"
      >
        <GridCell
          className="grid-cotainer"
          width={"150px"}
          style={{ height: "25px" }}
          field="Rolling12monthCount"
          title="count"
          cell={(props) => renderGridCell(props)}
        />{" "}
        <GridCell
          className="grid-cotainer"
          width={"150px"}
          style={{ height: "25px" }}
          field="Rolling12monthValue"
          title="volume"
          cell={(props) => renderGridCell(props)}
        />
      </GridColumn>
      <GridColumn
        title="Rolling 12 month"
        style={{ padding: 0, paddingLeft: "58px" }}
      >
        <GridCell
          className="grid-cotainer"
          width={"150px"}
          style={{ height: "25px" }}
          field="Rolling12monthCount"
          title="count"
          cell={(props) => renderGridCell(props)}
        />{" "}
        <GridCell
          className="grid-cotainer"
          width={"150px"}
          style={{ height: "25px" }}
          field="Rolling12monthValue"
          title="volume"
          cell={(props) => renderGridCell(props)}
        />
      </GridColumn>
      <GridColumn
        title="Rolling 12 month"
        style={{ padding: 0, paddingLeft: "58px" }}
      >
        <GridCell
          className="grid-cotainer"
          width={"150px"}
          style={{ height: "25px" }}
          field="Rolling12monthCount"
          title="count"
          cell={(props) => renderGridCell(props)}
        />{" "}
        <GridCell
          className="grid-cotainer"
          width={"150px"}
          style={{ height: "25px" }}
          field="Rolling12monthValue"
          title="volume"
          cell={(props) => renderGridCell(props)}
        />
      </GridColumn>
      <GridColumn title="Rolling 12 month">
        <GridCell
          className="grid-cotainer"
          width={"150px"}
          style={{ height: "25px" }}
          field="Rolling12monthCount"
          title="count"
          cell={(props) => renderGridCell(props)}
        />{" "}
        <GridCell
          className="grid-cotainer"
          width={"150px"}
          style={{ height: "25px" }}
          field="Rolling12monthValue"
          title="volume"
          cell={(props) => renderGridCell(props)}
        />
      </GridColumn>
      <GridColumn title="Rolling 12 month">
        <GridCell
          className="grid-cotainer"
          width={"150px"}
          style={{ height: "25px" }}
          field="Rolling12monthCount"
          title="count"
          cell={(props) => renderGridCell(props)}
        />{" "}
        <GridCell
          className="grid-cotainer"
          width={"150px"}
          style={{ height: "25px" }}
          field="Rolling12monthValue"
          title="volume"
          cell={(props) => renderGridCell(props)}
        />
      </GridColumn>
      <GridColumn title="Rolling 12 month">
        <GridCell
          className="grid-cotainer"
          width={"150px"}
          style={{ height: "25px" }}
          field="Rolling12monthCount"
          title="count"
          cell={(props) => renderGridCell(props)}
        />{" "}
        <GridCell
          className="grid-cotainer"
          width={"150px"}
          style={{ height: "25px" }}
          field="Rolling12monthValue"
          title="volume"
          cell={(props) => renderGridCell(props)}
        />
      </GridColumn>
      <GridColumn
        style={{ padding: 0, paddingLeft: "58px" }}
        title="Rolling 12 month"
      >
        <GridCell
          className="grid-cotainer"
          width={"150px"}
          style={{ height: "25px" }}
          field="Rolling12monthCount"
          title="count"
          cell={(props) => renderGridCell(props)}
        />{" "}
        <GridCell
          className="grid-cotainer"
          width={"150px"}
          style={{ height: "25px" }}
          field="Rolling12monthValue"
          title="volume"
          cell={(props) => renderGridCell(props)}
        />
      </GridColumn>
    </Grid>
  );
  return (
    <div className="container-fluid">
      <div className="row profile-page">
        <div className="container user-details-container">
          <ExcelExport data={data} ref={_export}>
            <ExcelExportColumn
              field="Title"
              title="Title"
              locked={true}
              width={50}
            />

            <ExcelExportColumnGroup
              title="Rolling 12-Months"
              headerCellOptions={{
                textAlign: "center",
              }}
            >
              <ExcelExportColumn
                field="Rolling12monthCount"
                title="Count"
                cellOptions={{
                  format: "$#,##0.00",
                }}
                width={150}
                footerCellOptions={{
                  wrap: true,
                  textAlign: "center",
                }}
                groupFooterCellOptions={{
                  textAlign: "right",
                }}
              />
              <ExcelExportColumn
                field="Rolling12monthValue"
                filter="numeric"
                title="Volume"
                cellOptions={{
                  format: "$#,##0.00",
                }}
                width={150}
                footerCellOptions={{
                  wrap: true,
                  textAlign: "center",
                }}
                groupFooterCellOptions={{
                  textAlign: "right",
                }}
              />
            </ExcelExportColumnGroup>
            <ExcelExportColumnGroup
              title="Year-To-Date	"
              headerCellOptions={{
                textAlign: "center",
              }}
            >
              <ExcelExportColumn
                field="yearToDateCount"
                filter="numeric"
                title="count"
              />
              <ExcelExportColumn
                field="yearToDateVolume"
                filter="numeric"
                title="Volume"
              />
            </ExcelExportColumnGroup>

            <ExcelExportColumnGroup
              title="October 2022"
              headerCellOptions={{
                textAlign: "center",
              }}
            >
              <ExcelExportColumn field="octoberCount" />
              <ExcelExportColumn
                field="octoberVolume"
                filter="numeric"
                title="Volume"
                cellOptions={{
                  background: "#ff0000",
                }}
              />
            </ExcelExportColumnGroup>
            <ExcelExportColumnGroup
              title="September 2022	"
              headerCellOptions={{
                textAlign: "center",
              }}
            >
              <ExcelExportColumn
                field="SeptemberCount"
                filter="numeric"
                title="Count"
              />
              <ExcelExportColumn
                field="SeptemberVolume"
                filter="numeric"
                title="Count"
              />
            </ExcelExportColumnGroup>
            <ExcelExportColumnGroup
              title="August 2022"
              headerCellOptions={{
                textAlign: "center",
              }}
            >
              <ExcelExportColumn
                field="augustCount"
                filter="numeric"
                title="Count"
              />
              <ExcelExportColumn
                field="augustVolume"
                filter="numeric"
                title="Volume"
              />
            </ExcelExportColumnGroup>
            <ExcelExportColumnGroup
              title="July 2022"
              headerCellOptions={{
                textAlign: "center",
              }}
            >
              <ExcelExportColumn
                field="julyCount"
                filter="numeric"
                title="count"
              />
              <ExcelExportColumn
                field="julyVolume"
                filter="numeric"
                title="Volume"
              />
            </ExcelExportColumnGroup>
            <ExcelExportColumnGroup
              title="June 2022"
              headerCellOptions={{
                textAlign: "center",
              }}
            >
              <ExcelExportColumn
                field="juneCount"
                filter="numeric"
                title="count"
              />
              <ExcelExportColumn
                field="juneVolume"
                filter="numeric"
                title="Volume"
              />
            </ExcelExportColumnGroup>
            <ExcelExportColumnGroup
              title="May 2022"
              headerCellOptions={{
                textAlign: "center",
              }}
            >
              <ExcelExportColumn
                field="mayCount"
                filter="numeric"
                title="count"
              />
              <ExcelExportColumn
                field="mayVolume"
                filter="numeric"
                title="Volume"
              />
            </ExcelExportColumnGroup>
          </ExcelExport>

          {grid}
          <GridPDFExport ref={(pdfExport) => (gridPDFExport = pdfExport)}>
            {grid}
          </GridPDFExport>
        </div>
      </div>
    </div>
  );
};

export default ExporToExcel;
