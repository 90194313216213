import {
  getMaterials,
  getShipTos,
  getSiteNames,
  getOBNs,
  getTankList,
} from "../reportUtils";

export const setShipToDetailsWhenRegionChange = (
  filters,
  masterData,
  setFilters,
  setShipToSelectedValues,
  setSiteNameSelectedValues,
  setObnSelectedValues
) => {
  const siteIds =
    filters.selectedRegions?.length > 0
      ? getShipTos(masterData, filters.selectedRegions)
      : [];

  const selectedShipTo =
    filters.selectedRegions?.length > 0 && siteIds?.length > 0
      ? [siteIds?.[0]]
      : [];

  const siteNames =
    filters.selectedRegions?.length > 0
      ? getSiteNames(masterData, filters.selectedRegions)
      : [];

  const selectedSiteName = siteNames?.length > 0 ? [siteNames?.[0]] : [];
  const obns =
    filters.selectedRegions?.length > 0
      ? getOBNs(masterData, filters.selectedRegions, true)
      : [];
  const selectedOBN = obns?.length > 0 ? [obns?.[0]] : [];

  setFilters((prev) => {
    return {
      ...prev,
      selectedShipTo: selectedShipTo,
      selectedSiteName: selectedSiteName,
      allSiteIds: siteIds,
      allSiteNames: siteNames,
      allOBNs: obns,
      selectedOBN: selectedOBN,
    };
  });
  setShipToSelectedValues(selectedShipTo);
  setSiteNameSelectedValues(selectedSiteName);
  setObnSelectedValues(selectedOBN);
};

export const setProductDetailsWhenShipToChange = (
  masterData,
  filters,
  setFilters,
  setProductSelectedValues
) => {
  const productsData = getMaterials(masterData, filters, true);
  const selectedProducts = productsData?.length > 0 ? [productsData[0]] : [];

  setFilters((prev) => {
    return {
      ...prev,
      products: productsData,
      selectedProducts: selectedProducts,
    };
  });
  setProductSelectedValues(selectedProducts);
};

export const setTankValuesOnProductChange = (
  masterData,
  filters,
  setFilters,
  setTankSelectedValues
) => {
  const tankGroupsData = getTankList(masterData, filters);
  const selectedTanks = tankGroupsData?.length > 0 ? [tankGroupsData[0]] : [];
  setFilters((prev) => {
    return {
      ...prev,
      tankGroup: tankGroupsData,
      selectedTanks: selectedTanks,
    };
  });

  setTankSelectedValues(selectedTanks);
};

export const setSiteNameAndObnOnShipToChange = (
  filters,
  shipToSelectedValues,
  setFilters,
  setSiteNameSelectedValues,
  setObnSelectedValues
) => {
  const siteData = filters?.allSiteIds?.find(
    (siteInfo) => siteInfo?.id === shipToSelectedValues?.[0]?.id
  );

  const site = filters?.allSiteNames?.find(
    (siteInfo) => siteInfo?.siteName === siteData?.siteName
  );
  const siteNames = site && siteData ? [site] : [];
  const obn = filters?.allOBNs?.find(
    (siteInfo) => siteInfo?.obn === siteData?.obn
  );
  const obns = obn && siteData ? [obn] : [];
  setFilters((prev) => {
    return {
      ...prev,
      selectedShipTo: shipToSelectedValues || [],
      selectedSiteName: siteNames,
      selectedOBN: obns,
    };
  });
  setSiteNameSelectedValues(siteNames);
  setObnSelectedValues(obns);
};

export const setShipToAndObnOnSiteNameChange = (
  filters,
  siteNameSelectedValues,
  setFilters,
  setShipToSelectedValues,
  setObnSelectedValues
) => {
  const siteData = filters?.allSiteNames?.find(
    (siteInfo) => siteInfo?.id === siteNameSelectedValues?.[0]?.id
  );

  const site = filters?.allSiteIds?.find(
    (siteInfo) => siteInfo?.siteName === siteData?.siteName
  );

  const shipTos = site && siteData ? [site] : [];

  const obn = filters?.allOBNs?.find(
    (siteInfo) => siteInfo?.obn === siteData?.obn
  );
  const obns = obn && siteData ? [obn] : [];
  setFilters((prev) => {
    return {
      ...prev,
      selectedShipTo: shipTos,
      selectedSiteName: siteNameSelectedValues,
      selectedOBN: obns,
    };
  });
  setShipToSelectedValues(shipTos);
  setObnSelectedValues(obns);
};

export const setShipToAndSiteNameOnOBNChange = (
  filters,
  obnSelectedValues,
  setFilters,
  setShipToSelectedValues,
  setSiteNameSelectedValues
) => {
  const siteData = filters?.allOBNs?.find(
    (siteInfo) => siteInfo?.id === obnSelectedValues?.[0]?.id
  );
  const site = filters?.allSiteIds?.find(
    (siteInfo) => siteInfo?.globalSiteId === siteData?.globalSiteId
  );
  const selectedShipTo = site && siteData ? [site] : [];

  const siteName = filters?.allSiteNames?.find(
    (siteInfo) => siteInfo?.siteName === siteData?.siteName
  );
  const siteNames = siteName && siteData ? [siteName] : [];
  setFilters((prev) => {
    return {
      ...prev,
      selectedShipTo: selectedShipTo,
      selectedSiteName: siteNames,
      selectedOBN: obnSelectedValues,
    };
  });

  setShipToSelectedValues(selectedShipTo);
  setSiteNameSelectedValues(siteNames);
};
