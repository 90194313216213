import React from "react";
import "./failed-delivery.scss";
import { Button } from "@progress/kendo-react-buttons";
import { UIText } from "../label-constants";

const showHeader = (props) => {
  const deliveryId = props?.data?.RowKey;
  return (
    <div className="deliveryHeader">
      <div className="title">{`${UIText.Failed_Delivery}: ${deliveryId}`}</div>
      <div className="close-btn-container">
        <Button
          className="close-btn"
          onClick={() => {
            props?.close();
          }}
        >
          {UIText.Close}
        </Button>
      </div>
    </div>
  );
};

const showErrorContent = (content) => {
  return <span className="error-content">{content}</span>;
};

const FailedDeliveriesDetails = (props) => {
  const { type, content } = props.data;
  return (
    <div className="deliveryContainer">
      <div className="deliveryForm">
        {showHeader(props)}
        {type === "Message" ? <pre>{content}</pre> : showErrorContent(content)}
      </div>
    </div>
  );
};

export { FailedDeliveriesDetails };
