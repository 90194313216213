const initialState = {
  refresh_token: null,
  access_token: null,
  id_token: null,
  UniqueUserId: null,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {

    case "SET_REFRESH_TOKEN":
      if (!action.payload) {
        return state;
      }
      return { ...state, refresh_token: action.payload };

    case "SET_ACCESS_TOKEN":
      if (!action.payload) {
        return state;
      }
      return { ...state, access_token: action.payload };

    case "SET_ID_TOKEN":
      if (!action.payload) {
        return state;
      }
      return { ...state, id_token: action.payload };

      case "SET_UUID":
        if (!action.payload) {
          return state;
        }
        return { ...state, UniqueUserId: action.payload };

    default:
      return state;
  }
};

export default loginReducer;
