import { UIText } from "../reports/components/label-constants";
import "./tour.scss";

const TourPage = () => {
  return (
    <div className="tour-container">
      <div className="content">
        <img src={require("../../../assets/logo.png")} alt="shell" />
        <h1>{UIText.Take_a_tour}</h1>
        <p>{UIText.The_WSM_analytics_application_comprises}</p>
      </div>
      <div className="row pages-thumbnail-row">
        <div className="col-md-4 col-lg-4 col-sm-4 thumb">
          <img
            className="thumb-img"
            src={require("../../../assets/login.png")}
            alt="login page"
          />
          <h5>{UIText.One_Login_Page}</h5>
          <p>{UIText.The_login_page_lets_you_login_to_you_account}</p>
        </div>
        <div className="col-md-4 col-lg-4 col-sm-4 thumb">
          <img
            className="thumb-img"
            src={require("../../../assets/dashboard.png")}
            alt="dashboard page"
          />
          <h5>{UIText.Two_Dashboard}</h5>
          <p>{UIText.Once_logged_in_you_will_see_the_dashboard}</p>
        </div>
        <div className="col-md-4 col-lg-4 col-sm-4 thumb">
          <img
            className="thumb-img"
            src={require("../../../assets/reports.png")}
            alt="report page"
          />
          <h5>{UIText.Three_Report_Page}</h5>
          <p>{UIText.The_report_page_gives_you_a_detailed_view}</p>
        </div>
      </div>
    </div>
  );
};
export { TourPage };
