/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, DatePicker, Loader } from "@progress/kendo-react-all";
import { TextArea } from "@progress/kendo-react-inputs";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reportsService } from "../../../../../services/reports";
import { triggerNotification } from "../../../../../state-management/actions";
import { store } from "../../../../../state-management/store";
import { BarChart } from "../../../../components/bar-chart/bar-chart";
import { ContentWindow } from "../../../../components/content-window/content-window";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { SearchableDropdown } from "../../../../components/searchable-dropdown/searchable-dropdown";
import {
  error,
  getDaysBeforeDate,
  readOnlyUser,
} from "../../../../../services/utils";
import { OutOfToleranceTrendAnalysisContainer } from "../out-of-tolerance-trend-analysis/out-of-tolerance-trend-analysis-container";
import { SCREEN_IDS } from "../../reportUtils";
import { loggerService } from "../../../../../services/logger";
import { isDachCluster, NUMBERS } from "../common-utils";
import { UIText } from "../label-constants";

const getFormattedDate = (d) => {
  const date = d.getDate().toString().padStart(2, "0");
  const month = (d.getMonth() + 1).toString().padStart(2, "0");
  const year = d.getFullYear();
  return `${year}-${month}-${date}`;
};

const CommentViewContainer = (props) => {
  const [oldComments, setOldComments] = useState([]);
  const [isOutOfTolerance, setIsOutOfTolerance] = useState(false);
  const [windowVisibility, setWindowVisibility] = useState(false);
  const [commentsHistory, setCommentsHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    setIsOutOfTolerance(
      props?.screenId === SCREEN_IDS.OUT_OF_TOLERANCE_TREND_ANALYSIS
    );
    setOldComments([]);
    fetchOldComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.report]);

  const fetchOldComments = async () => {
    setLoading(true);
    const response = await reportsService.fetchPreviousComments(
      props?.country,
      props?.report,
      startDate,
      endDate
    );
    const sanitized = response?.map((item, index) => {
      return { ...item, id: index };
    });
    setOldComments(sanitized);
    setLoading(false);
    return () => setOldComments([]);
  };

  const prepareAndOpenContentWindow = (recordId) => {
    const specificCommenthistory = oldComments.filter(
      (item) => item?.id === recordId
    );
    const commentsHistoryArr = [];
    specificCommenthistory?.forEach((comment) => {
      commentsHistoryArr.push({
        Comment: comment?.Comment,
        UserEmail: comment?.CommentBy,
        CommentDate: comment?.CommentedOn,
      });
      comment?.CommentHistoryList?.forEach((history) => {
        commentsHistoryArr.push({
          Comment: history?.Comment,
          UserEmail: history?.CommentBy,
          CommentDate: history?.CommentedOn,
        });
      });
    });

    if (specificCommenthistory && commentsHistoryArr.length > 0) {
      setCommentsHistory(commentsHistoryArr);
      setWindowVisibility(true);
    } else {
      setCommentsHistory([]);
      setWindowVisibility(false);
    }
  };

  const handleDateChange = (e) => {
    const name = e?.target?.name;
    const value = getFormattedDate(new Date(e?.target?.value));
    if (name === "start") {
      setStartDate(value);
    } else if (name === "end") {
      setEndDate(value);
    }
  };

  const inlineStyles =
    "col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-5 comments-form-container";
  const overlayStyles = isOutOfTolerance
    ? "overlay-comments-form-container"
    : "";
  const combinedClassName = `${inlineStyles} ${overlayStyles}`;

  return (
    <div className={combinedClassName}>
      <div className="row">
        <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 title">
          <b>{UIText.Showing_previously_added_comments}</b>
        </div>
        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 close-btn-container">
          <Button className="close-btn" onClick={props.onViewClose}>
            {UIText.Close}
          </Button>
        </div>
      </div>
      <p>
        {UIText.Please_select_a_range_to_view_comments_within_range}
      </p>
      <p>
        {UIText.The_dates_represent_the_delivery_date}
      </p>
      <DatePicker
        className="date-picker"
        name="start"
        onChange={handleDateChange}
        format="dd-MMMM-yyyy"
      />
      <DatePicker
        className="date-picker"
        name="end"
        onChange={handleDateChange}
        format="dd-MMMM-yyyy"
      />
      <Button onClick={fetchOldComments}>{UIText.Get_Comments}</Button>
      {loading && (
        <div className="loading-container">
          <Loader size="medium" />
          <h6>Loading please wait...</h6>
        </div>
      )}
      {!loading && oldComments?.length === 0 && (
        <div className="no-comments">
          <h6>{UIText.No_comments_to_display}</h6>
          <p>{UIText.Please_select_a_date_range}</p>
        </div>
      )}
      {!loading && oldComments?.length > 0 && (
        <table className="previous-comments-table">
          <thead>
            <tr className="table-row">
              <td>
                <b>{UIText.ShipTo}</b>
              </td>
              <td>
                <b>{props?.screenId === SCREEN_IDS.ACCOUNTING_FOR_DELIVERIES ? "Calculated Delivery" : "OBN"}</b>
              </td>
              <td>
                <b>{UIText.Tank}</b>
              </td>
              <td>
                <b>{props?.screenId === SCREEN_IDS.ACCOUNTING_FOR_DELIVERIES ? "Difference" : "Material No."}</b>
              </td>
              <td>
                <b>{UIText.Comment_Date}</b>
              </td>
              <td>
                <b>
                  {props?.screenId === SCREEN_IDS.ACCOUNTING_FOR_DELIVERIES ? "Confirmed Delivery" : "Variance"}
                </b>
              </td>
              <td>
                <b>{UIText.Comment}</b>
              </td>
              <td>
              <b>{UIText.History}</b>
              </td>
            </tr>
          </thead>
          <tbody>
            {oldComments?.length > 0 &&
              oldComments.map((comment) => {
                return (
                  <tr
                    className="table-row"
                    key={`${comment?.["ShipToId"]}-${comment?.["Comment"]}`}
                  >
                    <td>{comment["ShipToId"]}</td>
                    <td>
                      {props?.screenId === SCREEN_IDS.ACCOUNTING_FOR_DELIVERIES
                        ? comment["CalculatedDelivery"] || 0
                        : comment["OBN"]}
                    </td>
                    <td>{comment["Tank"]}</td>
                    <td>
                      {props?.screenId === SCREEN_IDS.ACCOUNTING_FOR_DELIVERIES
                        ? comment["ConfirmedDelivery"] -
                          comment["CalculatedDelivery"]
                        : comment["MaterialNo"]}
                    </td>
                    <td>{comment["CommentedOn"]}</td>
                    <td>
                      {props?.screenId === SCREEN_IDS.ACCOUNTING_FOR_DELIVERIES
                        ? comment["ConfirmedDelivery"] || 0
                        : comment["Variance"]}
                    </td>
                    <td>
                      {comment["Comment"].split("$")[
                        comment["Comment"].split("$").length - 1
                      ] || ""}
                    </td>
                    <td>
                      {
                        <a
                          role="button"
                          tabIndex={"0"}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            prepareAndOpenContentWindow(comment?.id)
                          }
                        >
                          {UIText.View}
                        </a>
                      }
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
      <ContentWindow
        title="Comments History"
        visible={windowVisibility}
        onClose={() => setWindowVisibility(false)}
      >
        <AppDataGrid
          take={4}
          pageable={true}
          filterable={false}
          expandable={false}
          sortable={false}
          data={commentsHistory}
          columnsToShow={[
            {
              title: UIText.Comments,
              field: "Comment",
              filterable: false,
            },
            {
              title: UIText.User_Email,
              field: "UserEmail",
              filterable: false,
            },
            {
              title: UIText.Comment_Date,
              field: "CommentDate",
              filterable: true,
            },
          ]}
        />
      </ContentWindow>
    </div>
  );
};

const AddCommentsForm = (props) => {
  const user = useSelector((state) => state?.user?.user);
  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disability, setDisability] = useState({
    TankId: false,
    MaterialNumer: false,
  });

  const [isOutOfTolerance, setIsOutOfTolerance] = useState(false);
  const reportDate = getFormattedDate(props?.reportDate);
  const [payload, setPayload] = useState({
    CountryCode: props?.country,
    UserEmail: user?.UserEmail,
    Comment: "",
    SiteId: "",
    RequestDate: isOutOfTolerance ? reportDate : "",
    TankNo: "",
    MaterialNumer: "",
    ShipmentId: "",
  });
  const [showOtherCommentTextbox, setShowOtherCommentTextbox] = useState(false);

  useEffect(() => {
    setIsOutOfTolerance(
      props?.screenId === SCREEN_IDS.OUT_OF_TOLERANCE_TREND_ANALYSIS
    );
    setFormData([]);
    async function fetchFormContent() {
      const form = await reportsService.fetchCommentsForm(
        props?.country,
        props?.report
      );
      setFormData(form);
      return () => setFormData([]);
    }
    fetchFormContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.report]);

  useEffect(() => {
    setPayload((prev) => {
      return {
        ...prev,
        RequestDate: isOutOfTolerance ? reportDate : "",
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOutOfTolerance]);

  const handleDropdownChange = (e) => {
    const value = e.value?.text || "";
    const name = e.target.name;
    switch (name) {
      case "ShipToId":
        setPayload((prev) => {
          return { ...prev, SiteId: value };
        });
        return;

      case "TankId":
        setPayload((prev) => {
          return { ...prev, TankNo: value };
        });
        setDisability((prev) => {
          return { ...prev, MaterialNumer: true, TankId: false };
        });
        return;

      case "MaterialNumer":
        setPayload((prev) => {
          return { ...prev, MaterialNumer: value };
        });
        setDisability((prev) => {
          return { ...prev, TankId: true, MaterialNumer: false };
        });
        return;
      case "ShipmentId":
        setPayload((prev) => {
          return { ...prev, ShipmentId: value };
        });
        return;

      case "Comments":
        if (value === "Others") {
          setShowOtherCommentTextbox(true);
          setPayload((prev) => {
            return { ...prev, Comment: "" };
          });
        } else {
          setShowOtherCommentTextbox(false);
          setPayload((prev) => {
            return { ...prev, Comment: value };
          });
        }
        return;

      default:
        break;
    }
  };

  const handleDateChange = (e) => {
    const dateInstance = new Date(e.value);
    const date = dateInstance.getDate().toString().padStart(2, "0");
    const month = (dateInstance.getMonth() + 1).toString().padStart(2, "0");
    const year = dateInstance.getFullYear();
    const sanitizedDate = `${year}-${month}-${date}`;
    setPayload((prev) => {
      return { ...prev, RequestDate: sanitizedDate };
    });
  };

  const checkValue = (fieldName) => {
    if (fieldName === "TankId" && payload["TankNo"]) {
      return { text: payload["TankNo"] };
    }

    if (fieldName === "MaterialNumer" && payload["MaterialNumer"]) {
      return { text: payload["MaterialNumer"] };
    }

    if (fieldName === "ShipToId" && payload["SiteId"]) {
      return { text: payload["SiteId"] };
    }

    if (fieldName === "ShipmentId" && payload["ShipmentId"]) {
      return { text: payload["ShipmentId"] };
    }

    if (
      fieldName === "Comments" &&
      payload["Comment"] &&
      !showOtherCommentTextbox
    ) {
      return { text: payload["Comment"] };
    }

    return { text: "" };
  };

  const renderDropDown = (disabilityItem, fieldName, displayName, source) => {
    return (
      <div
        className="field"
        style={{ color: disabilityItem[fieldName] && "grey" }}
      >
        <p htmlFor="Drowdown">{displayName?.toUpperCase()}</p>
        <SearchableDropdown
          onSelect={handleDropdownChange}
          name={fieldName}
          disabled={disabilityItem[fieldName]}
          data={source}
          filterable
          value={checkValue(fieldName)}
        />
        {fieldName === "TankId" && disabilityItem["TankId"] && (
          <p>
            {UIText.All_Tanks_for_this_Ship_To_number}
          </p>
        )}
        {fieldName === "MaterialNumer" && disabilityItem["MaterialNumer"] && (
          <p>
            {UIText.Material_number_will_be_automatically}
          </p>
        )}
      </div>
    );
  };

  const renderDateComponent = () => {
    return (
      <div className="field">
        <p
          htmlFor="Date"
          title="This date should match with the date in the record on the left hand side for which you are entering the comment."
        >
          {UIText.DELIVERY_DATE}<span class="k-icon k-i-info"></span>
        </p>
        <DatePicker
          format={"dd-MMM-yyyy"}
          onChange={handleDateChange}
          placeholder={"Please select a date"}
        />
      </div>
    );
  };

  const renderForm = (field) => {
    const fieldName = Object.keys(field)[0];

    const type = Object.values(field[fieldName][0])[0];
    const displayName = Object.values(field[fieldName][1])[0];
    const source = Object.values(field[fieldName][2])[0];

    switch (type) {
      case "DROP_DOWN":
        return renderDropDown(disability, fieldName, displayName, source);

      case "DATE":
        return !isOutOfTolerance && renderDateComponent();

      default:
        break;
    }
    return null;
  };

  const handleSubmit = async () => {
    setLoading(true);
    const response = await reportsService.saveReportComment(
      payload,
      props?.report
    );
    if (response["Error"]) {
      store.dispatch(
        triggerNotification({
          type: {
            style: error,
            icon: false,
          },
          message: response["Error"],
        })
      );
    }
    if (response?.Status === "Success") {
      store.dispatch(
        triggerNotification({
          type: {
            style: UIText.success,
            icon: false,
          },
          message: UIText.Record_Updated_Successfully,
        })
      );
    }
    setLoading(false);
  };

  const handleOtherComment = (e) => {
    const value = e.value;
    setPayload((prev) => {
      return {
        ...prev,
        Comment: `$Others$${value}`,
      };
    });
  };

  const handleReset = () => {
    setLoading(false);
    setDisability((prev) => {
      return { ...prev, MaterialNumer: false, TankId: false };
    });
    setPayload((prev) => {
      return {
        ...prev,
        Comment: "",
        SiteId: "",
        RequestDate: isOutOfTolerance ? payload?.RequestDate : "",
        TankNo: "",
        MaterialNumer: "",
        ShipmentId: "",
      };
    });
    setShowOtherCommentTextbox(false);
  };

  return (
    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 comments-form-container">
      <div className="row">
        <div className="col-md-9 title">
          <b>{UIText.Add_new_comment}</b>
          <p>{UIText.Please_use_the_form_below_to_add_new_comment}</p>
        </div>
        <div className="col-md-3 close-btn-container">
          <Button className="close-btn" onClick={props.onViewClose}>
            {UIText.Close}
          </Button>
        </div>
      </div>
      <hr />
      {formData && formData.length > 0 ? (
        formData[0]?.Fields?.map((field) => renderForm(field))
      ) : (
        <p>Loading the form please wait...</p>
      )}
      {formData && showOtherCommentTextbox && (
        <>
          <p>{UIText.Other_Comment}</p>
          <TextArea maxLength={500} onChange={handleOtherComment} />
        </>
      )}
      <div className="submit-btn-container">
        {!loading ? (
          <Button className="submit-btn" onClick={handleSubmit}>
            {UIText.Submit}
          </Button>
        ) : (
          <p>Updating please wait...</p>
        )}
        &nbsp;
        <Button className="submit-btn" onClick={handleReset}>
          {UIText.Reset}
        </Button>
      </div>
    </div>
  );
};

const ChartsContainer = (props) => {
  const [loading, setLoading] = useState(false);
  const [chart, setChart] = useState({ xAxis: [], yAxis: [] });
  const [chartPayload, setChartPayload] = useState({
    CountryCode: props?.country,
    FromDate: "",
    ToDate: "",
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    const dateInstance = new Date(value);
    const date = dateInstance.getDate().toString().padStart(2, "0");
    const month = (dateInstance.getMonth() + 1).toString().padStart(2, "0");
    const year = dateInstance.getFullYear();
    const sanitizedDate = `${year}-${month}-${date}`;

    setChartPayload((prev) => {
      return { ...prev, [name]: sanitizedDate };
    });
  };

  const handleGenerateChart = async () => {
    setLoading(true);

    let response = null;
    if (props?.screenId === SCREEN_IDS.ACCOUNTING_FOR_DELIVERIES) {
      response = await reportsService.getChartData(chartPayload, props?.report);
    } else {
      response = await reportsService.getOOTAChartData(
        chartPayload?.CountryCode,
        chartPayload?.FromDate,
        chartPayload?.ToDate
      );
    }

    if (response) {
      const xAxisData = Object.keys(response);
      const yAxisData = Object.values(response);
      setChart((prev) => {
        return { ...prev, xAxis: xAxisData, yAxis: yAxisData };
      });
    }

    setLoading(false);
  };

  const [isOutOfTolerance, setIsOutOfTolerance] = useState(false);
  useEffect(() => {
    setIsOutOfTolerance(
      props?.screenId === SCREEN_IDS.OUT_OF_TOLERANCE_TREND_ANALYSIS
    );
  }, [props?.screenId]);

  const title = isOutOfTolerance
    ? "Out of tolerance trend analysis"
    : "Accounting for Deliveries";

  return (
    <div className="charts-parent-container">
      <div className="row">
        <div className="col-md-9 charts-container">
          <BarChart
            className="chart"
            xAxisData={chart.xAxis}
            yAxisData={chart.yAxis}
            title={title}
          />
        </div>
        <div className="col-md-3 filter-container">
          <b>{UIText.Please_select_date_range_to_see_the_graph}</b>
          <p>{UIText.Start_Date}</p>
          <DatePicker onChange={handleChange} name="FromDate" />
          <p>{UIText.End_Date}</p>
          <DatePicker onChange={handleChange} name="ToDate" />
          <hr />
          <div className="footer-btn">
            {!loading ? (
              <Button
                onClick={handleGenerateChart}
                className="generate-chart-btn"
              >
                {UIText.Generate_Chart}
              </Button>
            ) : (
              <p>Generating chart, please wait...</p>
            )}
            <Button onClick={props.onClose} className="close-btn">
              {UIText.Close}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommentableReportsContainer = ({ powerBi, report, screenId }) => {
  const hiddenFileInput = useRef();
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [showCommentsForm, setShowCommentsForm] = useState(false);
  const [showPrevComments, setShowPrevComments] = useState(false);
  const [showChart, setShowChart] = useState(false);
  const [historicalViewEnabled, setHistoricalViewEnabled] = useState(false);
  const isOutOfTolerance =
    screenId === SCREEN_IDS.OUT_OF_TOLERANCE_TREND_ANALYSIS;
  const defaultCountry = useSelector(
    (state) => state?.reports?.selectedCountry
  );
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);

  const reportMaxDate = isDachCluster()
    ? getDaysBeforeDate(2)
    : getDaysBeforeDate(1);
  const [reportDate, setReportDate] = useState(reportMaxDate);

  const [readOnly, setReadOnly] = useState(false);
  useEffect(() => {
    setReadOnly(readOnlyUser());
  }, []);

  store.subscribe(() => {
    const { selectedCountry } = store.getState().reports;
    setCurrentCountryCode(selectedCountry);
  });

  useEffect(() => {
    setReportDate(reportMaxDate);
    setShowCommentsForm(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountryCode]);

  const historyViewAction = (enabled) => {
    loggerService.dev("enabled", enabled);
    setHistoricalViewEnabled(enabled);
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const uploadFile = async (data) => {
    setUploading(true);
    try {
      const date = getFormattedDate(reportDate);
      const headers = {
        RequestDate: date,
        Market: currentCountryCode,
      };
      const response = await reportsService.uploadFile(data, headers);
      setUploading(false);
      if (
        response.status >= NUMBERS.THREE_HUNDRED &&
        response.status < NUMBERS.THREE_HUNDRED
      ) {
        const message = response.hasOwnProperty("data")
          ? response.data
          : UIText.File_uploaded_successfully;
        dispatch(
          triggerNotification({
            type: {
              style: UIText.success,
              icon: true,
            },
            message: message,
          })
        );
      } else {
        const message = response.response.hasOwnProperty("data")
          ? response?.response?.data?.errors?.["Data Error"]?.[0]
          : UIText.Unable_to_upload_the_file;
        dispatch(
          triggerNotification({
            type: {
              style: error,
              icon: true,
            },
            message: message,
          })
        );
      }
    } catch (error1) {
      dispatch(
        triggerNotification({
          type: {
            style: error1,
            icon: true,
          },
          message: UIText.Unable_to_upload_the_file,
        })
      );
      setUploading(false);
    }
  };

  const renderCommentsFormContainer = (reportedDate) => {
    if (showPrevComments) {
      return isOutOfTolerance ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10,
          }}
        >
          <CommentViewContainer
            report={report}
            screenId={screenId}
            onViewClose={() => setShowPrevComments(false)}
            country={currentCountryCode}
          />
        </div>
      ) : (
        <div
          style={{
            position: "fixed",
            top: "10%",
            left: "42%",
            width: "80%",
            height: "85%",
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CommentViewContainer
            report={report}
            screenId={screenId}
            onViewClose={() => setShowPrevComments(false)}
          />
        </div>
      );
    }

    if (showCommentsForm) {
      return (
        <AddCommentsForm
          report={report}
          screenId={screenId}
          onViewClose={() => setShowCommentsForm(false)}
          reportDate={reportedDate}
          country={currentCountryCode}
        />
      );
    }
    return <></>;
  };

  const handleReportDateChange = (e) => {
    setReportDate(new Date(e?.target?.value));
  };

  const headerSection = () => {
    return readOnly ? (
      <></>
    ) : (
      <div className="actions-header">
        {/* <h4 className="title">{report}</h4> */}
        <div className="header-button-container">
          {!isOutOfTolerance && (
            <Button
              onClick={() => {
                setShowCommentsForm(true);
                setShowPrevComments(false);
              }}
            >
              <p>{UIText.Add_Comment}</p>
            </Button>
          )}
          {!historicalViewEnabled && (
            <>
              <Button
                className="previous_comments_btn"
                onClick={() => {
                  setShowCommentsForm(false);
                  setShowPrevComments(true);
                }}
              >
                <p>{UIText.Show_previous_comments}</p>
              </Button>
              <Button
                onClick={() => {
                  setShowCommentsForm(false);
                  setShowPrevComments(false);
                  setShowChart(true);
                }}
              >
                <p>{UIText.View_Chart}</p>
              </Button>
            </>
          )}
          {isOutOfTolerance && !historicalViewEnabled && (
            <span className="toggle-container">
              <span className="export-container">
                <DatePicker
                  format={"dd-MMM-yyyy"}
                  value={reportDate}
                  onChange={handleReportDateChange}
                  max={reportMaxDate}
                  min={getDaysBeforeDate(16)}
                />
                <Button className="button-upload" onClick={handleClick}>
                  {uploading ? UIText.Uploading : UIText.Upload_a_file}
                  <input
                    ref={hiddenFileInput}
                    type="file"
                    disabled={uploading}
                    style={{ display: "none" }}
                    onChange={(e) => {
                      const file = e?.currentTarget?.files?.[0];
                      const formData = new FormData();
                      formData.append("Report Data", file);
                      uploadFile(formData);
                      hiddenFileInput.current.value = null;
                    }}
                  />
                </Button>
              </span>
            </span>
          )}
        </div>
      </div>
    );
  };
  return (
    <div className="commentable-report-container">
      {!showChart && headerSection()}
      {showChart ? (
        <ChartsContainer
          report={report}
          screenId={screenId}
          onClose={() => setShowChart(false)}
          country={currentCountryCode}
        />
      ) : (
        <div className="power-bi-report">
          {isOutOfTolerance ? (
            <OutOfToleranceTrendAnalysisContainer
              historyViewAction={historyViewAction}
            />
          ) : (
            <div
              className={
                showCommentsForm || showPrevComments
                  ? "col-xl-7 col-lg-7 col-md-7 col-sm-7 col-xs-7 report-container"
                  : "col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 report-container"
              }
            >
              {powerBi}
            </div>
          )}
          {renderCommentsFormContainer(reportDate)}
        </div>
      )}
    </div>
  );
};

export { CommentableReportsContainer };
