import React from "react";
import { FailedDeliveryScreen } from "./failed-delivery/failed-delivery-screen";

const FailedDeliveryContainer = () => {
  return (
    <>
      <div className="container user-management-screen-container eod-container">
        <div className="row content-container">
          <FailedDeliveryScreen />
        </div>
      </div>
    </>
  );
};

export { FailedDeliveryContainer };
