const initialState = {
  selectedReport: null,
  siteData: null,
  notification: [],
  formData: null,
  selectedCountry: null,
  selectedCluster: null,
  clusters: [],
};

  const reportReducer = (state = initialState, action) => {
    switch (action.type) {
    
        case "SET_SELECTED_REPORT":
          return { ...state, selectedReport: action.payload };
    
        case "PERSIST_SITE_DATA":
          if (!action.payload) {
            return state;
          }
          return { ...state, siteData: action.payload };
    
        case "TRIGGER_NOTIFICATION":
         {
          if (!action.payload) {
            return state;
          }
          const notification = state.notification ? state.notification : []
          notification.push(action.payload);
          return { ...state, notification: notification };
         }
    
        case "CLOSE_NOTIFICATION":
          return { ...state, notification: [] };
    
        case "SAVE_FORM_DATA":
          return { ...state, formData: action.payload };
    
        case "SET_SELECTED_COUNTRY":
          return { ...state, selectedCountry: action.payload, siteData: [] };
    
        case "SET_SELECTED_CLUSTER":
          return { ...state, selectedCluster: action.payload };
    
        case "SET_CLUSTER_LIST":
          return { ...state, clusters: action.payload };
    
        case "CLEAR_SITE_DETAILS":
          return { ...state, siteData: [] };
    
        case "UPDATE_SITE_DETAILS": {
          if (!action.payload) {
            return state;
          }
          const siteData = state.siteData?.map(site => {
            return site?.GlobalSiteId === action.payload?.GlobalSiteId
              ? action.payload
              : site;
          });
          return { ...state, siteData: siteData };
        }
    
        default:
          return state;
      }
  };
  
  export default reportReducer;
  