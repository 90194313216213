import calculateSize from "calculate-size";
import { CustomHeaderCell } from "../account-for-deliveries/account-for-deliveries-utils";
import { DECIMAL } from "../common-utils";
import { Button } from "@progress/kendo-react-buttons";
import {
  ActionButtonCell,
  ActionType,
  MissingMandatoryCell,
  ShipToLinkCell,
} from "../common-grid-cells";

export const EOMViewOptions = {
  TANK: 0,
  AUDIT: 1,
};

export const isSame = (dataItem, selectedItem, field) => {
  const valuesSame =
    dataItem.product === selectedItem.product &&
    dataItem.tank === selectedItem.tank &&
    field === selectedItem?.editField;
  return (
    dataItem.date === selectedItem.date &&
    dataItem.globalSiteId === selectedItem.globalSiteId &&
    valuesSame
  );
};

export const calculateWidth = (field, data) => {
  let maxWidth = 0;
  data?.forEach((item) => {
    const fontSize = `${
      window.innerWidth * DECIMAL.POINT_ZERO_ONE_ZERO_FIVE
    }px`;
    // eslint-disable-next-line
    const size = calculateSize(item[field], {
      font: "Helvetica Neue",
      fontSize: fontSize,
    }); // pass the font properties based on the application
    if (size.width > maxWidth) {
      maxWidth = size.width;
    }
  });
  const minWidth = window.innerWidth * DECIMAL.POINT_ZERO_FIVE;
  return maxWidth < minWidth ? minWidth : maxWidth;
};

export const colsToShow = (
  handleShipToLinkAction,
  sendToGSAPAction,
  viewLogAction
) => {
  let columns = [];
  columns = [
    ...columns,
    {
      title: "ShipTo",
      field: "globalSiteId",
      filterable: true,
      editable: false,
      sortable: true,
      cell: (props) => {
        return (
          <ShipToLinkCell {...props} linkAction={handleShipToLinkAction} />
        );
      },
    },
    {
      title: "SLOC",
      field: "sloc",
      filterable: true,
      sortable: true,
    },
    {
      title: "OBN",
      field: "obn",
      filterable: true,
      sortable: true,
    },
    {
      title: "Stocks",
      field: "totalStock",
      headerCell: CustomHeaderCell,
      filterable: true,
      sortable: true,
      cell: (props) => {
        return <MissingMandatoryCell {...props} />;
      },
    },
    {
      title: "Actions",
      field: "",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_THREE,
      cell: (props) => {
        return (
          <ActionButtonCell
            {...props}
            shouldValidate={true}
            handleAction={sendToGSAPAction}
            title={"Click here to send stocks to GSAP"}
            actionType={ActionType.Send}
          />
        );
      },
    },
    {
      title: "",
      field: "",
      headerCell: CustomHeaderCell,
      width: window.innerWidth * DECIMAL.POINT_ZERO_SIX,
      cell: (props) => {
        return (
          <ActionButtonCell
            {...props}
            shouldValidate={true}
            handleAction={viewLogAction}
            title={"Click here to view logs"}
            actionType={ActionType.Logs}
          />
        );
      },
    },
  ];
  return columns;
};

export const childColumnsToShow = () => {
  return [
    {
      title: "Tank",
      field: "tankNumber",
      headerCell: CustomHeaderCell,
      filterable: false,
      editable: false,
      sortable: true,
    },
    {
      title: "Tank Group",
      field: "tankGroupId",
      headerCell: CustomHeaderCell,
      filterable: false,
      editable: false,
      sortable: true,
    },
    {
      title: "Material No",
      field: "materialNumber",
      filterable: false,
      editable: false,
      sortable: true,
      headerCell: CustomHeaderCell,
    },
    {
      title: "Stocks",
      field: "currentStock",
      headerCell: CustomHeaderCell,
      filterable: false,
      sortable: true,
      cell: (props) => {
        return <MissingMandatoryCell {...props} />;
      },
    },
  ];
};

const showHeader = (props) => {
  const shipTo = props?.globalSiteId;
  return (
    <div className="deliveryHeader">
      <div className="title">{`Ship To: ${shipTo}`}</div>
      <div className="close-btn-container">
        <Button
          className="close-btn"
          onClick={() => {
            props?.close();
          }}
        >
          Close
        </Button>
      </div>
    </div>
  );
};

const showContent = (content) => {
  return (
    <div className="message-container">
      {Object.keys(content)?.map((key) => {
        return (
          <div key={key}>
            <pre>
              <code>{key} : {content?.[key]}</code>
            </pre>
          </div>
        );
      })}
    </div>
  );
};

export const LogDetails = (props) => {
  const content = props?.content;
  return (
    <div className="deliveryContainer">
      <div className="deliveryForm">
        {showHeader(props)}
        {showContent(content)}
      </div>
    </div>
  );
};
