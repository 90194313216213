import { httpService } from "../services/http";
import { API } from "./utils";

class IssueService {
  async getAllIssues(payload) {
    let URL = `${API.issue}/${payload.country}/details?startDate=${payload?.raisedDate}&endDate=${payload?.closedDate}`;
    if (payload.region?.text?.length > 0) {
      URL += `&region=${payload?.region?.text}`;
    } else {
      URL += `&region=`;
    }
    if (payload?.actionParty) {
      URL += `&actionParty=${payload?.actionParty?.text}`;
    }
    if (payload?.status) {
      URL += `&status=${payload?.status?.text}`;
    }
    if (payload?.selectedSiteId) {
      URL += `&globalSiteId=${payload?.selectedSiteId}`;
    }
    URL += `&pageNo=1&pageSize=20`;
    try {
      const response = await httpService.get(URL);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async getAllSiteIds(country) {
    const URL = `${API.issueSitesInfo}/${country}`;
    try {
      const response = await httpService.get(URL);
      if (response?.hasOwnProperty("data")) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async createOrUpdateIssue(payload, countryCode) {
    const URL = `${API.issue}/${countryCode}`;
    try {
      return await httpService.post(URL, payload);
    } catch (error) {
      return null;
    }
  }

  async downloadIssueExcel(payload) {
    const URL = `${API.issue}/${payload.country}/download?region=${
      payload?.region?.text
    }&status=${payload?.status?.text}&startDate=${payload?.startDate}&endDate=${
      payload?.endDate
    }&actionParty=${payload?.actionParty?.text}&globalSiteId=${
      payload.globalSiteId || ""
    }`;
    await httpService.downloadFile(URL, "issue-log-report.xlsx");
  }
}

const issueService = new IssueService();
export { issueService };
