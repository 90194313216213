import React, { useState } from "react";
import { UserAuditListScreen } from "./user-management-screens/user-audit-list-screen";
import { UserLoginHistoryScreen } from "./user-management-screens/user-login-history-screen";
import { UserOnboardingScreen } from "./user-management-screens/user-onboarding-screen";
import { EditUserScreen } from "./user-management-screens/user-edit-screen";
import { store } from "../../../../state-management/store";
import { DuplicateDeliveriesScreen } from "./duplicate-deliveries-screen/duplicate-deliveries-screen";
import { SCREEN_IDS } from "../reportUtils";

const UserManagementContainer = (props) => {
  const [selectedScreen, setSelectedScreen] = useState(
    props?.selectedReport?.pageId
  );
  store.subscribe(() => {
    const { selectedReport } = store.getState().reports;
    setSelectedScreen(selectedReport.pageId);
  });
  const renderSelectedUserManagementScreen = () => {
    switch (selectedScreen) {
      case SCREEN_IDS.USER_AUDIT_LIST:
        return <UserAuditListScreen />;
      case SCREEN_IDS.USER_LOGIN_HISTORY:
        return <UserLoginHistoryScreen />;
      case SCREEN_IDS.USER_ONBOARDING:
        return <UserOnboardingScreen />;
      case SCREEN_IDS.EDIT_USERS:
        return <EditUserScreen />;
      case SCREEN_IDS.REVIEW_GSAP_DUPLICATES:
        return <DuplicateDeliveriesScreen />;

      default:
        return <></>;
    }
  };

  return (
    <div className="user-management-container">
      <div className="row content-container">
        {renderSelectedUserManagementScreen()}
      </div>
    </div>
  );
};

export { UserManagementContainer };
