import "./dataGrid.scss";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { orderBy, process } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { Loader } from "@progress/kendo-react-indicators";
import PropTypes from "prop-types";

const PageSize = {
  FIVE: 5,
  TEN: 10,
  TWENTY: 20,
  FIFTY: 50,
  HUNDRED: 100,
};

// Details row code. When you expand a row.
const FallBackContainer = () => {
  return <h1>Nothing to show...</h1>;
};

const getPageSizes = () => {
  return [
    PageSize.FIVE,
    PageSize.TEN,
    PageSize.TWENTY,
    PageSize.FIFTY,
    PageSize.HUNDRED,
  ];
};

const pagerSettings = {
  buttonCount: PageSize.FIVE,
  info: true,
  type: "numeric",
  pageSizes: getPageSizes(),
  previousNext: true,
};

const generateDataWithID = (props) => {
  return props.data
    ? props.data.map((e, index) =>
        Object.assign(
          {
            id: index,
          },
          e
        )
      )
    : [];
};

const renderGrid = (
  props,
  hasLockedRow,
  data,
  dataState,
  setDataState,
  sortChange,
  expandChange
) => {
  const processedData = hasLockedRow ? data : process(data, dataState);
  const handleDataStateChange = (e) => {
    if (e.dataState.sort) {
      e.dataState.sort = e.dataState.sort.map((sort) => {
        if (props?.numericSortFields?.includes(sort.field)) {
          return {
            ...sort,
            compare: (a, b) => {
              const val1 = a?.[sort.field]?.replace(/\D/g, "");
              const val2 = b?.[sort.field]?.replace(/\D/g, "");
              return sort.dir === "asc" ? val1 - val2 : val2 - val1;
            },
          };
        }
        return sort;
      });
    }
    setDataState(e.dataState);
  }
  const getDetailController = () => {
    if (props.expandable) {
      return props?.detailsContainer
        ? props.detailsContainer
        : FallBackContainer;
    }
    return null;
  };
  return (
    <Grid
      ref={props?.gridRef}
      style={props?.style}
      {...dataState}
      data={processedData}
      onDataStateChange={handleDataStateChange}
      onSortChange={hasLockedRow && sortChange}
      pageable={props.pageable && pagerSettings}
      pageSize={props?.pageSize}
      sortable={props.sortable}
      filterable={props.filterable || false}
      expandField="expanded"
      detail={getDetailController()}
      onExpandChange={expandChange}
      editField="inEdit"
      rowRender={props?.rowRender}
      onItemChange={props?.onItemChange}
      onRowClick={props?.onRowClick}
      resizable={props?.resizable}
      reorderable={props?.reorderable}
      fixedScroll={props?.fixedScroll}
      cellRender={props?.cellRender}
      cells={props?.cells}
      skip={dataState?.skip}
    >
      <GridNoRecords>
        {props?.noRecordsMessage
          ? props?.noRecordsMessage
          : "* No records available *"}{" "}
      </GridNoRecords>
      {props.columnsToShow.map((col) => (
        <GridColumn
          field={col.field}
          title={col.title}
          filterable={col.filterable || false}
          filterCell={col.filterCell}
          filter={col.filter || "text"}
          key={`${col.field}-${col.title}`}
          editable={col.editable}
          editor={col.editor}
          cell={col?.cell}
          width={col?.width}
          headerCell={col?.headerCell}
          className={col?.className}
          minResizableWidth={col?.minResizableWidth}
          locked={col?.locked}
          sortable={col?.sortable}
        >
          {col?.columns?.map((column) => (
            <GridColumn
              field={column.field}
              title={column.title}
              filterable={column.filterable || false}
              filterCell={column.filterCell}
              filter={column.filter || "text"}
              key={`${col.field}-${col.title}`}
              editable={column.editable}
              editor={column.editor}
              cell={column?.cell}
              width={column?.width}
              headerCell={column?.headerCell}
              className={column?.className}
              minResizableWidth={column?.minResizableWidth}
              locked={column?.locked}
              sortable={column?.sortable}
            />
          ))}
        </GridColumn>
      ))}
    </Grid>
  );
};
const AppDataGrid = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    async exportDataInExcel() {
      excelExport();
    },
    async getWorkBook() {
      return _export.current.workbookOptions();
    },
  }));
  const initialDataState = {
    sort: [
      {
        field: "code",
        dir: "asc",
      },
    ],
    take: props?.take || PageSize.TEN,
    skip: 0,
  };

  const [dataState, setDataState] = useState(initialDataState);
  const [data, setData] = useState(generateDataWithID(props));
  const [detailsLoader, setDetailsLoader] = useState(false);

  useEffect(() => {
    const dataWithID = generateDataWithID(props);
    setData(dataWithID || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (props?.resetIndex) {
      setDataState(initialDataState);
      props?.setResetIndex(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.resetIndex]);

  useEffect(() => {
    setDataState(initialDataState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.take]);

  // Export to excel's logic
  // ------------------------
  const _export = React.useRef(null);

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  // Row expansion logic
  const expandChange = async (event) => {
    props?.showExpandLoader && setDetailsLoader(true);
    const dataItem = props?.updateItemData
      ? await props?.updateItemData(event.dataItem)
      : event.dataItem;
    setDetailsLoader(false);
    const newData = data.map((item) => {
      if (item.id === event.dataItem.id) {
        dataItem.expanded = !event.dataItem.expanded;
        return dataItem;
      }

      return item;
    });
    props?.expandChange ? props?.expandChange(newData) : setData(newData);
  };

  const hasLockedRow = props?.hasLockedRow;

  const sortChange = (event) => {
    if (hasLockedRow) {
      setDataState((prev) => ({
        ...prev,
        sort: event?.sort,
      }));
      const items = orderBy(data, event?.sort);
      const index = items.findIndex((item) => item.locked === true);
      if (index !== -1) {
        // Remove the item from its current position
        const movedItem = items.splice(index, 1)[0];

        // Push the item to the end of the array
        items.push(movedItem);
      }
      setData(items);
    }
  };

  return (
    <>
      {props?.exportable ? (
        <span
          href="#"
          role="button"
          tabIndex="0"
          title="Export To Excel"
          className="export-link"
          onClick={excelExport}
        >
          <img
            src={require("../../../assets/export.svg").default}
            alt="Export to excel"
            className="export-link-img"
          />
          &nbsp;Export to Excel
        </span>
      ) : null}

      {props?.exportData ? (
        <span>
          <ExcelExport
            data={props?.exportData ? props.exportData : props.data}
            ref={_export}
            fileName={props.exportFileName}
          >
            {props.columnsToExport.map((col) => (
              <ExcelExportColumn
                field={col.field}
                title={col.title}
                filterable={col.filterable || false}
                filterCell={col.filterCell}
                filter={col.filter || "text"}
                key={`${col.field}-${col.title}`}
                editable={col.editable}
                editor={col.editor}
              />
            ))}
          </ExcelExport>
          {renderGrid(
            props,
            hasLockedRow,
            data,
            dataState,
            setDataState,
            sortChange,
            expandChange
          )}
          {detailsLoader && (
            <Loader
              className="gridSpinner"
              size="large"
              type="converging-spinner"
            />
          )}
        </span>
      ) : (
        <div>
          <ExcelExport
            data={props.data}
            ref={_export}
            fileName={props.exportFileName}
          >
            {renderGrid(
              props,
              hasLockedRow,
              data,
              dataState,
              setDataState,
              sortChange,
              expandChange
            )}
          </ExcelExport>
          {detailsLoader && (
            <Loader
              className="gridSpinner"
              size="large"
              type="converging-spinner"
            />
          )}
        </div>
      )}
    </>
  );
});

AppDataGrid.prototypes = {
  take: PropTypes.number,
  data: PropTypes.array,
  resetIndex: PropTypes.bool,
  setResetIndex: PropTypes.func,
  showExpandLoader: PropTypes.bool,
  updateItemData: PropTypes.array,
  expandChange: PropTypes.func,
  expandable: PropTypes.bool,
  hasLockedRow: PropTypes.bool,
  detailsContainer: PropTypes.any,
  gridRef: PropTypes.any,
  style: PropTypes.object,
  numericSortFields: PropTypes.array,
  pageable: PropTypes.bool,
  pageSize: PropTypes.number,
  sortable: PropTypes.bool,
  filterable: PropTypes.bool,
  rowRender: PropTypes.func,
  onItemChange: PropTypes.func,
  onRowClick: PropTypes.func,
  resizable: PropTypes.bool,
  fixedScroll: PropTypes.bool,
  cellRender: PropTypes.any,
  cells: PropTypes.array,
  exportData: PropTypes.array,
  exportFileName: PropTypes.string,
  columnsToExport: PropTypes.array,
  map: PropTypes.func,
  columnsToShow: PropTypes.array,
};

export { AppDataGrid };
