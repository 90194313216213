import { appConfig } from "../configs/app-config";
import { store } from "../state-management/store";
import { USER_ROLES } from "../ui/pages/reports/components/common-utils";

const getAppConfig = () => {
  if (appConfig.env === "prod") {
    return appConfig.api.prod;
  }
  return appConfig.env === "test" ? appConfig.api.test : appConfig.api.dev;
};

const API = getAppConfig();

const getFormattedDate = (d) => {
  const date = d?.getDate()?.toString()?.padStart(2, "0");
  const month = (d?.getMonth() + 1)?.toString()?.padStart(2, "0");
  const year = d?.getFullYear();
  if (date && month && year) {
    return `${year}-${month}-${date}`;
  }
  return "";
};

const reduceOneMinute = (date) => {
  const newDate = new Date(date);
  newDate.setMinutes(newDate.getMinutes() - 1);
  return newDate;
};

const getDateBeforeMonths = (n = 1) => {
  const currentDate = new Date();
  return new Date(currentDate.setMonth(currentDate.getMonth() - n));
};

const convertUTCDateToLocalDate = (date) => {
  const localDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60 * 1000
  );
  return getFormattedDateAndTime(localDate);
};

const getFormattedDateAndTime = (d) => {
  const date = d.getDate().toString().padStart(2, "0");
  const month = (d.getMonth() + 1).toString().padStart(2, "0");
  const year = d.getFullYear();
  let formattedDate = "";
  if (date && month && year) {
    formattedDate = `${year}-${month}-${date}`;
  }
  const hours = d.getHours().toString().padStart(2, "0");
  const minutes = d.getMinutes().toString().padStart(2, "0");
  const seconds = d.getSeconds().toString().padStart(2, "0");

  if (hours && minutes && seconds) {
    formattedDate = `${formattedDate} ${hours}:${minutes}:${seconds}`;
  }
  return formattedDate;
};

const getFormattedFullDate = (d) => {
  const msPad = 3;
  let formattedDate = getFormattedDateAndTime(d);
  const ms = d.getMilliseconds().toString().padStart(msPad, "0");

  if (ms) {
    formattedDate = `${formattedDate}:${ms}`;
  }
  return formattedDate;
};

const getDaysBeforeDate = (days = 1) => {
  const today = new Date();
  const twoDaysBeforeDate = new Date(today);
  twoDaysBeforeDate.setDate(today.getDate() - days);
  return twoDaysBeforeDate;
};

const readOnlyUser = () => {
  const userRoleId = store.getState()?.user?.user?.RoleId;
  return parseInt(userRoleId, 10) === USER_ROLES.VISITOR;
};

const isSOMAdminUser = () => {
  const userRoleId = store.getState()?.user?.user?.RoleId;
  return parseInt(userRoleId, 10) === USER_ROLES.SOM_ADMIN;
}

const isGlobalUser = () => {
  const userRoleId = store.getState()?.user?.user?.RoleId;
  return parseInt(userRoleId, 10) === USER_ROLES.GLOBAL;
};

const isDatesSame = (date1, date2) => {
  // Check if both dates are valid instances of Date
  if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
    return false;
  }

  // Check if year, month, and day components of both dates are the same
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

const isDateOlderThanToday = (date1, days = 0) => {
  // Check if both dates are valid instances of Date
  if (!(date1 instanceof Date)) {
    return false;
  }
  const currentTimestamp = new Date().getTime();
  const timeStampWithDays = date1.getTime() + days * 24 * 60 * 60 * 1000;

  return timeStampWithDays >= currentTimestamp;
};

const getDateAfterDays = (startDate, days = 0) => {
  // Check if both dates are valid instances of Date
  if (!(startDate instanceof Date)) {
    return new Date();
  }

  const toDate = new Date(startDate);
  toDate.setDate(toDate.getDate() + days);
  return toDate;
};

const getDateString = date => {
  const selectedDate = new Date(date);
  const dateStr = `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${selectedDate.getDate().toString().padStart(2, "0")}`;

  return dateStr;
};

export const error = "error";
export const errorStr = "error";

export {
  API,
  getFormattedDate,
  getDaysBeforeDate,
  readOnlyUser,
  isDatesSame,
  isDateOlderThanToday,
  getDateAfterDays,
  getFormattedDateAndTime,
  convertUTCDateToLocalDate,
  getDateBeforeMonths,
  reduceOneMinute,
  getFormattedFullDate,
  isGlobalUser,
  getDateString,
  isSOMAdminUser
};
