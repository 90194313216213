import { DateRangePicker } from "@progress/kendo-react-dateinputs";
import React, { useEffect, useState } from "react";

export const DateRangePickerComponent = (props) => {
  const { value, onChange } = props;
  const [currentValues, setCurrentValues] = useState();

  useEffect(() => {
    setCurrentValues(value);
  }, [value]);

  const onSelectionChange = (event) => {
    if (event?.value?.start && event?.value?.end) {
      onChange(event);
    }
    setCurrentValues(event?.value);
  };

  return (
    <div>
      <DateRangePicker
        {...props}
        value={currentValues}
        onChange={onSelectionChange}
      />
    </div>
  );
};
