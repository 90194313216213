import React, { useEffect, useState } from "react";
import "./report-menu.scss";
import { Input } from "@progress/kendo-react-inputs";
import { useSelector, useStore } from "react-redux";
import { setSelectedReport } from "../../../../state-management/actions";
import {
  Menu,
  SubMenu,
  MenuItem,
  Button,
} from "@sede-x/shell-ds-react-framework";
import { getMenus } from "./common-utils";
import { isGlobalUser } from "../../../../services/utils";
import { Search } from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";

export const ReportMenu = (props) => {
  const store = useStore();
  const userMenus = store.getState()?.user?.userScreenList;
  const [userReports, setUserReports] = useState(userMenus);
  const [inputValue, setInputValue] = useState("");
  const [moduleId, setModuleId] = useState("");
  const [submoduleId, setSubmoduleId] = useState("");
  const [selectedItem, setSelectedItem] = useState({});
  const [reportsList, setReportsList] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const savedReportsData = localStorage.getItem("reportsData");
  const parsedData = savedReportsData && JSON.parse(savedReportsData);
  const defaultCountry = useSelector((state) => state?.reports.selectedCountry);
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);
  const globalUser = isGlobalUser();

  store.subscribe(() => {
    const { selectedReport, selectedCountry } = store.getState().reports;
    const { userScreenList } = store.getState().user;
    setUserReports(userScreenList);
    const currentModuleId = selectedReport?.moduleId;
    const currentSubModuleId = selectedReport?.submoduleId;
    setModuleId(currentModuleId);
    setSubmoduleId(currentSubModuleId);
    setSelectedItem(selectedReport);
    setCurrentCountryCode(selectedCountry);
  });

  const getMenuItems = (reports, level = 0) => {
    const menuList = getMenus(currentCountryCode, reports);
    const cwrGroup = "cwr";
    const menus = menuList?.map((menu) => {
      let menuItem = {
        ...menu,
        label: menu?.title,
        key: menu?.id,
        level: level,
        className:
          globalUser && menu?.id?.startsWith(cwrGroup) ? "menu-cwr" : "",
      };
      if (menu?.children) {
        menuItem = {
          ...menuItem,
          children: getMenuItems(menu?.children, level + 1),
        };
      }
      return menuItem;
    });
    return menus;
  };

  useEffect(() => {
    if (parsedData) {
      const currentModuleId = parsedData?.moduleId;
      const currentSubModuleId = parsedData?.submoduleId;
      setExpanded([currentModuleId, currentSubModuleId]);
      setSelectedItem(parsedData);
      store.dispatch(setSelectedReport(parsedData));
    }
    setReportsList(getMenuItems(userReports));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const menus = getMenuItems(userReports);
    setReportsList(menus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReports]);

  const onSelect = (e, menu) => {
    const { id, reportId, title, pageName, tableName, columnName } = menu;
    const keyPath = e?.keyPath;
    if (inputValue) {
      setInputValue("");
    }
    if (keyPath?.length >= 1) {
      setModuleId(keyPath?.[keyPath?.length - 1]);
    }
    if (keyPath?.length >= 2) {
      setSubmoduleId(keyPath?.[keyPath?.length - 2]);
    }
    const index = expanded.indexOf(id);
    if (index === -1) {
      const selectedReport = {
        moduleId: moduleId,
        submoduleId: submoduleId,
        pageId: id,
        pageName,
        reportId,
        title,
        tableName,
        columnName,
      };
      localStorage.setItem("reportsData", JSON.stringify(selectedReport));
      setSelectedItem(selectedReport);
      store.dispatch(setSelectedReport(selectedReport));
    }
    setExpanded(keyPath);
  };

  const updateFilteredItemsFromChild = (
    data,
    parentId,
    value,
    filteredItems
  ) => {
    data.forEach((child) => {
      if (child.title.toLowerCase().includes(value.toLowerCase())) {
        filteredItems.push(parentId); // module id
        filteredItems.push(child.parentId); // submodule id
      }
      if (child.children) {
        updateFilteredItemsFromChild(
          child.children,
          child.parentId,
          value,
          filteredItems
        );
      }
    });
  };

  const updatedFilteredItems = (value, filteredItems) => {
    reportsList.forEach((list) => {
      if (list.children) {
        updateFilteredItemsFromChild(
          list.children,
          list.id,
          value,
          filteredItems
        );
      }
    });
  };

  const handleSearch = (value) => {
    setInputValue(value);
    let filteredItems = [];
    if (value) {
      updatedFilteredItems(value, filteredItems);
      filteredItems = Array.from(new Set(filteredItems));
      setExpanded(filteredItems);
    } else {
      setExpanded([selectedItem.moduleId, selectedItem.submoduleId]);
    }
  };

  const searchClass = props?.menuCollapse
    ? "search-container search-collapse-width"
    : "search-container search-width";

  const getClassName = (menu) => {
    let className = "menu-item";
    if (menu?.children) {
      className = menu?.level === 0 ? "parent-menu" : "sub-menu";
    }
    return className;
  };

  const renderMenuItem = (menu) => {
    const className = getClassName(menu);
    if (menu?.children) {
      return (
        <SubMenu className={className} title={menu?.label} key={menu?.id}>
          {menu?.children?.map((item) => renderMenuItem(item))}
        </SubMenu>
      );
    } else {
      return (
        <MenuItem
          className={className}
          onClick={(event) => {
            onSelect(event, menu);
          }}
        >
          {menu?.label}
        </MenuItem>
      );
    }
  };

  const renderSearchControl = () => {
    return (
      <div className={searchClass}>
        <Button
          style={{ margin: "0", background: "none" }}
          className="search-icon"
          aria-label="Search"
          icon={<Search />}
          sentiment="positive"
          size="small"
        ></Button>
        <Input
          onChange={(e) => handleSearch(e.value)}
          value={inputValue}
          placeholder="Search Title"
          className="input-field"
        />
      </div>
    );
  };

  return (
    <div className="accordian-container">
      {renderSearchControl()}
      <div className="menu-container">
        {
          <Menu
            openKeys={expanded}
            selectedKeys={[selectedItem?.pageId]}
            onOpenChange={(e) => {
              setExpanded(e);
            }}
          >
            {reportsList?.map((menu) => {
              return renderMenuItem(menu);
            })}
          </Menu>
        }
      </div>
    </div>
  );
};
