import "./dataGrid.scss";
import React from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import PropTypes from "prop-types";

const InlineEditGrid = (props) => {
  return (
    <Grid
      style={props?.style}
      data={props?.data}
      pageable={props?.pageable}
      filterable={props?.filterable}
      expandable={props?.expandable}
      take={props?.take}
      onItemChange={props?.onItemChange}
      editField={"inEdit"}
      cells={props?.cells}
    >
      {props?.columnsToShow?.map((col) => (
        <GridColumn
          field={col.field}
          title={col.title}
          filterable={col.filterable || false}
          filterCell={col.filterCell}
          filter={col.filter || "text"}
          key={`${col.field}-${col.title}`}
          editable={col.editable}
          editor={col.editor}
          cell={col?.cell}
          width={col?.width}
          headerCell={col?.headerCell}
          className={col?.className}
          minResizableWidth={col?.minResizableWidth}
          locked={col?.locked}
          sortable={col?.sortable}
        />
      ))}
    </Grid>
  );
};

InlineEditGrid.prototypes = {
  take: PropTypes.number,
  data: PropTypes.array,
  style: PropTypes.object,
  pageable: PropTypes.bool,
  filterable: PropTypes.bool,
  expandable: PropTypes.bool,
  onItemChange: PropTypes.func,
  cells: PropTypes.array,
  columnsToShow: PropTypes.array,
  hasLockedRow: PropTypes.bool,
  detailsContainer: PropTypes.any,
  gridRef: PropTypes.any,
  numericSortFields: PropTypes.array,
  pageSize: PropTypes.number,
  sortable: PropTypes.bool,
  rowRender: PropTypes.func,
  onRowClick: PropTypes.func,
  resizable: PropTypes.bool,
  fixedScroll: PropTypes.bool,
  cellRender: PropTypes.any,
  exportData: PropTypes.array,
  exportFileName: PropTypes.string,
  columnsToExport: PropTypes.array,
  map: PropTypes.func,
};

export { InlineEditGrid };
