import React, { useEffect, useRef, useState } from "react";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import { Loader } from "@progress/kendo-react-indicators";
import "./out-of-tolerance-trend-analysis.scss";
import { GridToolbar } from "@progress/kendo-react-grid";
import {
  DropDownSelection,
  TrendViewMode,
  formatOOTAResponse,
  getDefaultFilterOptions,
  getOOTACountObj,
  getOOTACountValues,
  getOOTAFetchApiPayload,
  colsToShow as getOOTAGridColumns,
  getOOTAGridHeight,
  getOOTASubmitPayload,
  getOOTATabItems,
  handleOBNValueChange,
  handleOOTAGridDataChange,
  handleOOTARowRender,
  handleOOTAShipToChange,
  handleReportConfigChange,
  hasOOTAFilterEmptyValues,
  setOOTACommentOptions,
  setOOTADateAndRegions,
  setOOTAMaterialAndTank,
  setOOTAShipToAndOBN,
} from "./out-of-tolerance-trend-analysis-utils";
import { getAllSelectedOptions, getLastDates } from "../../reportUtils";
import {
  MultiSelectionDropDown,
  multiSelectionDropDownFields,
} from "../../../../components/multi-select-dropdown/multi-select-dropdown";
import { getMultiSelectTreeValue } from "@progress/kendo-react-dropdowns";
import { LoadingPanel } from "../../../../components/loading-panel/loading-panel";
import { reportsService } from "../../../../../services/reports";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../../../../state-management/store";
import { triggerNotification } from "../../../../../state-management/actions";
import { SiteInfoScreen } from "./out-of-tolerance-site-info";
import {
  DEFAULT_PAGE_SIZE,
  isBFXCluster,
  isDachCluster,
  NUMBERS,
} from "../common-utils";
import { DatePicker } from "@progress/kendo-react-all";
import { Switch } from "@progress/kendo-react-inputs";
import { TabComponent } from "../../../../components/tab-component/tab-component";
import {
  getOOTAHeaderButtons,
  getOOTAHeaderColumns,
} from "./out-of-tolerance-trend-analysis-ui-components";
import { UIText } from "../label-constants";

const OutOfToleranceTrendAnalysis = (props) => {
  const [originalData, setOriginalData] = useState([]);
  const [changedItems, setChangedItems] = useState([]);
  const [countValues, setCountValues] = useState(getOOTACountObj());
  const [currentField, setCurrentField] = useState(null);
  const [historicalViewEnabled, setHistoricalViewEnabled] = useState(false);
  const [trendAnalysisData, setTrendAnalysisData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [commentsOptions, setCommentsOptions] = useState([]);
  const [masterDataLoading, setMasterDataLoading] = useState(true);
  const [blankCount, setBlankCount] = useState(0);
  const [missingCommentsCount, setMissingCommentsCount] = useState(0);
  const [missingOutOfToleranceCount, setMissingOutOfToleranceCount] =
    useState(0);
  const exportGridRef = useRef(null);
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const defaultCountry = useSelector(
    (state) => state?.reports?.selectedCountry
  );
  const [currentCountryCode, setCurrentCountryCode] = useState(defaultCountry);
  const [resetIndex, setResetIndex] = useState(false);
  store.subscribe(() => {
    const { selectedCountry } = store.getState().reports;
    setCurrentCountryCode(selectedCountry);
  });
  const [filters, setFilters] = useState(getDefaultFilterOptions(filterData));
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [exporting, setExporting] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedTab, setSelectedTab] = useState(TrendViewMode.MATERIAL);
  const [reportConfig, setReportConfig] = useState();
  const [showSiteInfoModal, setShowSiteInfoModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState();

  const handleShipToLinkAction = (siteId, country) => {
    setSelectedSite({ siteId: siteId, country: country });
    setShowSiteInfoModal(true);
  };

  useEffect(() => {
    setTrendAnalysisData([]);
  }, [historicalViewEnabled]);

  useEffect(() => {
    setBlankCount(0);
    setMissingCommentsCount(0);
    setMissingOutOfToleranceCount(0);
  }, [fetchingData]);

  const showNotification = (style, message) => {
    dispatch(
      triggerNotification({
        type: {
          style: style,
          icon: true,
        },
        message: message,
      })
    );
  };

  const fetchReportData = async () => {
    try {
      const response = await reportsService.getRestoreData();
      setFetchingData(false);
      if (response) {
        const ootReportConfig = response?.find(
          (item) => item?.reportCode === "oot-daily"
        );
        setReportConfig(ootReportConfig);
      } else {
        showNotification(UIText.error, UIText.Unable_to_fetch_the_data);
      }
    } catch (error1) {
      showNotification(UIText.error, UIText.Server_error_Unable_to_submit_the_request);
      setFetchingData(false);
    }
  };

  useEffect(() => {
    fetchReportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleReportConfigChange(reportConfig, setFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportConfig]);

  const closeModal = () => {
    setShowSiteInfoModal(false);
  };

  const [dateSelectedValues, setDateSelectedValues] = useState(
    filters.selectedDate
  );

  const [regionSelectedValues, setRegionSelectedValues] = useState(
    filters.selectedRegions
  );

  const [shipToSelectedValues, setShipToSelectedValues] = useState(
    filters.selectedShipTo
  );

  const [obnSelectedValues, setObnSelectedValues] = useState(
    filters.selectedOBN
  );

  const [tankSelectedValues, setTankSelectedValues] = useState(
    filters.selectedTanks
  );

  const [materialSelectedValues, setMaterialSelectedValues] = useState(
    filters.selectedMaterial
  );

  const [commentSelectedValues, setCommentSelectedValues] = useState(
    filters.selectedComments
  );

  const [day250SelectedValues, setDay250SelectedValues] = useState(
    filters.selectedDay250
  );
  const [oneDayMinsSelectedValues, setOneDayMinsSelectedValues] = useState(
    filters.selectedOneDayMins
  );
  const [fiveDCumVarSelectedValues, setFiveDCumVarSelectedValues] = useState(
    filters.selectedFiveDCumulVar
  );
  const [fiveDCumThSelectedValues, setFiveDCumThSelectedValues] = useState(
    filters.selectedFiveDCumulTh
  );
  const [thirtyDaysCumulSelectedValues, setThirtyDaysCumulSelectedValues] =
    useState(filters.selectedThirtyDaysCumul);
  const [
    thirtyDaysCumulPercentageSelectedValues,
    setThirtyDaysCumulPercentageSelectedValues,
  ] = useState(filters.selectedThirtyDaysCumulPercentage);

  const fetchTrendAnalysisReport = async (refresh = false) => {
    const filter = refresh ? appliedFilters : filters;
    setFetchingData(true);
    setChangedItems([]);
    const payload = getOOTAFetchApiPayload(
      filter,
      currentCountryCode,
      historicalViewEnabled,
      selectedTab
    );

    try {
      const response = await reportsService.fetchToleranceTrendDataV2(
        payload,
        historicalViewEnabled
      );
      setFetchingData(false);
      if (response) {
        if (!refresh) {
          setAppliedFilters(filter);
        }
        const trendData = formatOOTAResponse(
          response,
          currentCountryCode,
          setBlankCount,
          setMissingCommentsCount,
          setMissingOutOfToleranceCount
        );
        setOriginalData(trendData ? trendData : []);
        setTrendAnalysisData(trendData ? trendData : []);
        setCountValues(getOOTACountValues(trendData));
        setFetchingData(false);
      } else {
        showNotification(UIText.error, UIText.Unable_to_fetch_the_data);
        setFetchingData(false);
      }
    } catch (error1) {
      showNotification(UIText.error, UIText.Server_error_Unable_to_submit_the_request);
      setFetchingData(false);
    }
  };

  const loadData = (modeChanged = false) => {
    const emptySelection =
      hasOOTAFilterEmptyValues(filters) ||
      (selectedTab === 1 && filters?.selectedTanks?.length === 0);
    if (!emptySelection && modeChanged) {
      fetchTrendAnalysisReport();
    }
  };

  const fetchMasterData = async () => {
    const masterData = await reportsService.fetchToleranceTrendMasterData({
      country: currentCountryCode,
    });
    setFilterData(masterData || []);
  };

  const fetchCommentsMasterData = async () => {
    const response = await reportsService.fetchCommentsMasterData();
    const options = response?.map((option) => option?.categoryText);
    setCommentsOptions(options || []);
  };

  const handleCountryCodeChange = async () => {
    setMasterDataLoading((_prev) => true);
    await Promise.all([fetchCommentsMasterData(), fetchMasterData()]);
    setMasterDataLoading((_prev) => false);

    setChangedItems([]);
    setTrendAnalysisData([]);
    setBlankCount(0);
    setMissingCommentsCount(0);
    setMissingOutOfToleranceCount(0);
  };
  useEffect(() => {
    const selectedDate = getLastDates(
      NUMBERS.ONE,
      isDachCluster() ? NUMBERS.TWO : NUMBERS.ONE
    );
    setFilters((prev) => {
      return {
        ...prev,
        selectedDate: selectedDate,
        dates: getLastDates(NUMBERS.FIVE, NUMBERS.ONE),
        selectedRegions: [],
      };
    });

    handleCountryCodeChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountryCode]);

  useEffect(() => {
    loadData(true);
    setChangedItems([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    setTrendAnalysisData([]);
    setOriginalData([]);
  }, [selectedTab]);

  useEffect(() => {
    setOOTACommentOptions(commentsOptions, setFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsOptions]);

  useEffect(() => {
    setOOTADateAndRegions(
      filterData,
      currentCountryCode,
      setFilters,
      setRegionSelectedValues
    );
    setFetchingData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    setOOTAShipToAndOBN(
      filters,
      filterData,
      setFilters,
      setShipToSelectedValues,
      setObnSelectedValues
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedRegions]);

  useEffect(() => {
    setOOTAMaterialAndTank(
      filters,
      filterData,
      setFilters,
      setMaterialSelectedValues,
      setTankSelectedValues
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.selectedShipTo]);

  useEffect(() => {
    setCommentSelectedValues(filters.selectedComments);
  }, [filters.selectedComments]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedDate: dateSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedRegions: regionSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionSelectedValues]);

  useEffect(() => {
    if (currentField === DropDownSelection.ShipTo) {
      handleOOTAShipToChange(
        filters,
        shipToSelectedValues,
        setFilters,
        setObnSelectedValues,
        setMaterialSelectedValues
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipToSelectedValues]);

  useEffect(() => {
    if (currentField === DropDownSelection.OBN) {
      handleOBNValueChange(
        filters,
        obnSelectedValues,
        setFilters,
        setShipToSelectedValues
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obnSelectedValues]);

  useEffect(() => {
    if (selectedTab === 1) {
      setFilters((prev) => ({
        ...prev,
        selectedTanks: tankSelectedValues,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tankSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedMaterial: materialSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedComments: commentSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedDay250: day250SelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day250SelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedOneDayMins: oneDayMinsSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneDayMinsSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedFiveDCumulVar: fiveDCumVarSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fiveDCumVarSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedFiveDCumulTh: fiveDCumThSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fiveDCumThSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedThirtyDaysCumul: thirtyDaysCumulSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thirtyDaysCumulSelectedValues]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      selectedThirtyDaysCumulPercentage:
        thirtyDaysCumulPercentageSelectedValues,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thirtyDaysCumulPercentageSelectedValues]);

  const checkError = (response) => {
    const responseErrors =
      response instanceof Array
        ? response?.filter((data) => data?.StatusCode !== NUMBERS.TWO_HUNDRED)
        : [];
    responseErrors?.forEach((data) => {
      showNotification(UIText.error, data?.Value);
    });
    if (responseErrors?.length === 0) {
      showNotification(UIText.success, UIText.Comments_saved_successfully);
      setChangedItems([]);
      fetchTrendAnalysisReport();
    }
  };

  const saveChanges = async () => {
    setSubmitting(true);
    const payload = getOOTASubmitPayload(
      changedItems,
      currentCountryCode,
      filters
    );
    const response = await reportsService.saveOOTAReportComment(payload);
    if (
      response?.status >= NUMBERS.TWO_HUNDRED &&
      response?.status < NUMBERS.THREE_HUNDRED
    ) {
      checkError(response?.data);
    } else {
      showNotification(UIText.error, UIText.Unable_to_submit_the_comments);
    }
    setSubmitting(false);
  };

  const cancelChanges = async () => {
    setTrendAnalysisData(originalData);
    setChangedItems([]);
  };

  const showHideLoaders = (show = false) => {
    setExporting(show);
  };

  const exportData = async () => {
    showHideLoaders(true);
    await cancelChanges();
    exportGridRef?.current?.exportDataInExcel &&
      (await exportGridRef.current.exportDataInExcel());

    setTimeout(showHideLoaders, 1000);
  };

  const onDateSelection = (event) => {
    if (event.operation === "delete") {
      setDateSelectedValues([]);
    } else {
      setDateSelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.dates, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const onRegionSelection = (event) => {
    if (event.operation === "delete") {
      setRegionSelectedValues([]);
    } else {
      setRegionSelectedValues((prev) => {
        const selectedValues = getAllSelectedOptions(event, prev);
        return getMultiSelectTreeValue(filters?.regions, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        });
      });
    }
  };

  const onShipToSelection = (event) => {
    setCurrentField(DropDownSelection.ShipTo);
    if (event.operation === "delete") {
      setShipToSelectedValues([]);
    } else {
      setShipToSelectedValues((prev) => {
        const selectedValues = getAllSelectedOptions(event, prev);
        return getMultiSelectTreeValue(filters?.shipTos, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        });
      });
    }
  };

  const onOBNSelection = (event) => {
    setCurrentField(DropDownSelection.OBN);
    if (event.operation === "delete") {
      setObnSelectedValues([]);
    } else {
      setObnSelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.allOBNs, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const onTankSelection = (event) => {
    if (event.operation === "delete") {
      setTankSelectedValues([]);
    } else {
      setTankSelectedValues((prev) => {
        const selectedValues = getAllSelectedOptions(event, prev);
        return getMultiSelectTreeValue(filters?.tanks, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        });
      });
    }
  };

  const onMaterialSelection = (event) => {
    if (event.operation === "delete") {
      setMaterialSelectedValues([]);
    } else {
      setMaterialSelectedValues((prev) => {
        const selectedValues = getAllSelectedOptions(event, prev);
        return getMultiSelectTreeValue(filters?.materials, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        });
      });
    }
  };

  const onCommentSelection = (event) => {
    if (event.operation === "delete") {
      setCommentSelectedValues([]);
    } else {
      setCommentSelectedValues((prev) => {
        const selectedValues = getAllSelectedOptions(event, prev);
        return getMultiSelectTreeValue(filters?.comments, {
          ...multiSelectionDropDownFields,
          ...event,
          value: selectedValues,
        });
      });
    }
  };

  const onDay250Selection = (event) => {
    if (event.operation === "delete") {
      setDay250SelectedValues([]);
    } else {
      setDay250SelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.oneDayGain, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const onOneDayMinsSelection = (event) => {
    if (event.operation === "delete") {
      setOneDayMinsSelectedValues([]);
    } else {
      setOneDayMinsSelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.oneDayLoss, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const onFiveDCumVarSelection = (event) => {
    if (event.operation === "delete") {
      setFiveDCumVarSelectedValues([]);
    } else {
      setFiveDCumVarSelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.isFiveDaysCumulative, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const onFiveDCumThSelection = (event) => {
    if (event.operation === "delete") {
      setFiveDCumThSelectedValues([]);
    } else {
      setFiveDCumThSelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.isFiveDaysCumulativePercent, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const onThirtyDayCumThSelection = (event) => {
    if (event.operation === "delete") {
      setThirtyDaysCumulSelectedValues([]);
    } else {
      setThirtyDaysCumulSelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.isThirtyDaysCumulative, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const onThirtyDayCumPercentageSelection = (event) => {
    if (event.operation === "delete") {
      setThirtyDaysCumulPercentageSelectedValues([]);
    } else {
      setThirtyDaysCumulPercentageSelectedValues((prev) => {
        return getMultiSelectTreeValue(filters?.isThirtyDaysCumulativePercent, {
          ...multiSelectionDropDownFields,
          ...event,
          value: prev,
        });
      });
    }
  };

  const fetchData = () => {
    const canShowError =
      hasOOTAFilterEmptyValues(filters) ||
      (selectedTab === 1 && filters?.selectedTanks?.length === 0);

    setShowError(canShowError);
    if (!canShowError) {
      fetchTrendAnalysisReport();
      setResetIndex(true);
    }
  };

  const onDateChange = (e) => {
    const date = new Date(e?.value);
    setFilters((prev) => {
      return {
        ...prev,
        selectedDateVal: date,
      };
    });
  };

  const headerFilters = () => {
    return (
      <div className="header-container">
        <div className="filters-container">
          {historicalViewEnabled ? (
            <span className="filter-date">
              <label htmlFor="Date">{UIText.Date}</label>
              <DatePicker
                format={"dd-MMM-yyyy"}
                value={filters?.selectedDateVal}
                onChange={onDateChange}
                max={new Date(reportConfig?.restoreEndDate)}
                min={new Date(reportConfig?.restoreStartDate)}
              />
            </span>
          ) : (
            <span className="filter">
              <label htmlFor="Date">{UIText.Date}</label>
              <MultiSelectionDropDown
                data={filters.dates}
                values={dateSelectedValues}
                onChange={onDateSelection}
                filterable={false}
                showError={showError}
                maxSelection={1}
                customStyle={{ width: "7vw", minHeight: "3vh" }}
              />
            </span>
          )}
          {filters.regions?.length > 1 && (
            <span className="filter">
              <label htmlFor="region">{UIText.Region}</label>
              <MultiSelectionDropDown
                data={filters.regions}
                values={regionSelectedValues}
                onChange={onRegionSelection}
                filterable={false}
                showError={showError}
                customStyle={{
                  width: isBFXCluster() ? "5.5vw" : "9vw",
                  minHeight: "3vh",
                }}
              />
            </span>
          )}

          <span className="filter">
            <label htmlFor="shipTo">{UIText.ShipTo}</label>
            <MultiSelectionDropDown
              data={filters.shipTos}
              values={shipToSelectedValues}
              onChange={onShipToSelection}
              filterable={true}
              showError={showError}
              customStyle={{ width: "6.5vw", minHeight: "3vh" }}
            />
          </span>
          <span className="filter">
            <label htmlFor="shipTo">{UIText.OBN}</label>
            <MultiSelectionDropDown
              data={filters.allOBNs}
              values={obnSelectedValues}
              onChange={onOBNSelection}
              filterable={true}
              showError={showError}
              customStyle={{ width: "5vw", minHeight: "3vh" }}
              maxSelection={1}
            />
          </span>
          <span className="filter">
            <label htmlFor="material">{UIText.Material_No}</label>
            <MultiSelectionDropDown
              data={filters.materials}
              values={materialSelectedValues}
              onChange={onMaterialSelection}
              filterable={true}
              showError={showError}
              customStyle={{ width: "6.5vw", minHeight: "3vh" }}
            />
          </span>

          <span className="filter">
            <label htmlFor="comments">{UIText.Comments}</label>
            <MultiSelectionDropDown
              data={filters.comments}
              values={commentSelectedValues}
              onChange={onCommentSelection}
              filterable={true}
              showError={showError}
              customStyle={{
                width:
                  filters.regions?.length > 1 && isBFXCluster()
                    ? "10vw"
                    : "14vw",
                minHeight: "3vh",
              }}
            />
          </span>
          {selectedTab === 1 && (
            <span className="filter">
              <label htmlFor="tank">{UIText.Tank_Group}</label>
              <MultiSelectionDropDown
                data={filters.tanks}
                values={tankSelectedValues}
                onChange={onTankSelection}
                filterable={true}
                showError={showError}
                customStyle={{ width: "7.0vw", minHeight: "3vh" }}
              />
            </span>
          )}
        </div>
        {selectedTab === TrendViewMode.MATERIAL && (
          <div className="filters-container">
            <span className="filter">
              <label htmlFor="+250">
                {isDachCluster() ? UIText.One_day250 : UIText.One_day150}
              </label>
              <MultiSelectionDropDown
                data={filters.oneDayGain}
                values={day250SelectedValues}
                onChange={onDay250Selection}
                filterable={false}
                showError={showError}
                maxSelection={1}
                customStyle={{ width: "4.5vw", minHeight: "3vh" }}
              />
            </span>
            <span className="filter">
              <label htmlFor="-250">
                {isDachCluster()
                  ? UIText.One_day_minus_250
                  : UIText.One_day_minus_150}
              </label>
              <MultiSelectionDropDown
                data={filters.oneDayLoss}
                values={oneDayMinsSelectedValues}
                onChange={onOneDayMinsSelection}
                filterable={false}
                showError={showError}
                maxSelection={1}
                customStyle={{ width: "4.5vw", minHeight: "3vh" }}
              />
            </span>
            <span className="filter-column">
              <label htmlFor="fiveDCumVar">{UIText.Five_Day_Cumul250}</label>
              <MultiSelectionDropDown
                data={filters.isFiveDaysCumulative}
                values={fiveDCumVarSelectedValues}
                onChange={onFiveDCumVarSelection}
                filterable={false}
                showError={showError}
                maxSelection={1}
                customStyle={{ width: "5vw", minHeight: "3vh" }}
              />
            </span>
            <span className="filter-column">
              <label htmlFor="fiveDCumThu">{UIText.Five_Day_Cumul_3_Th}</label>
              <MultiSelectionDropDown
                data={filters.isFiveDaysCumulativePercent}
                values={fiveDCumThSelectedValues}
                onChange={onFiveDCumThSelection}
                filterable={false}
                showError={showError}
                maxSelection={1}
                customStyle={{ width: "5vw", minHeight: "3vh" }}
              />
            </span>
            {isBFXCluster() && (
              <div className="filters-container">
                <span className="filter-column">
                  <label htmlFor="fiveDCumVar">
                    {UIText.Three_Zero_D_Cumul_750}
                  </label>
                  <MultiSelectionDropDown
                    data={filters.isThirtyDaysCumulative}
                    values={thirtyDaysCumulSelectedValues}
                    onChange={onThirtyDayCumThSelection}
                    filterable={false}
                    showError={showError}
                    maxSelection={1}
                    customStyle={{ width: "5vw", minHeight: "3vh" }}
                  />
                </span>
                <span className="filter-column">
                  <label htmlFor="fiveDCumThu">
                    {UIText.Three_Zero_D_Cumul_2_Percent_Th}
                  </label>
                  <MultiSelectionDropDown
                    data={filters.isThirtyDaysCumulativePercent}
                    values={thirtyDaysCumulPercentageSelectedValues}
                    onChange={onThirtyDayCumPercentageSelection}
                    filterable={false}
                    showError={showError}
                    maxSelection={1}
                    customStyle={{ width: "5vw", minHeight: "3vh" }}
                  />
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const showGridToolBar = () => {
    return (
      <GridToolbar>
        <div className="gridToolbar">
          {headerFilters()}
          <div className="rowContainer">
            {getOOTAHeaderButtons({
              changedItems,
              fetchData,
              fetchingData,
              fetchTrendAnalysisReport,
              appliedFilters,
              exportData,
              exporting,
              trendAnalysisData,
              selectedTab,
              saveChanges,
              cancelChanges,
            })}
            {selectedTab === TrendViewMode.MATERIAL &&
              getOOTAHeaderColumns(selectedTab, countValues)}
          </div>
        </div>
      </GridToolbar>
    );
  };

  const handleDataChange = (e) => {
    const changeItem = e?.dataItem;
    handleOOTAGridDataChange(
      changeItem,
      trendAnalysisData,
      originalData,
      changedItems,
      setTrendAnalysisData,
      setChangedItems
    );
  };

  const showGridDataView = () => (
    <AppDataGrid
      resetIndex={resetIndex}
      setResetIndex={setResetIndex}
      take={DEFAULT_PAGE_SIZE}
      style={{
        margin: "0 0.5vw 0.5vw 0.5vw",
        height: getOOTAGridHeight(selectedTab, historicalViewEnabled),
      }}
      pageable={true}
      filterable={true}
      data={trendAnalysisData}
      pageSizes={[5, 10, 20, 50, 100]}
      columnsToShow={getOOTAGridColumns({
        trendAnalysisData: trendAnalysisData,
        commentsOptions: commentsOptions,
        selectedMode: selectedTab,
        requestDate: filters.selectedDate?.[0]?.text,
        blankCount: blankCount,
        missingOutOfToleranceCount: missingOutOfToleranceCount,
        handleShipToLinkAction: handleShipToLinkAction,
        historicalViewEnabled: historicalViewEnabled,
        missingCommentsCount: missingCommentsCount,
      })}
      onItemChange={handleDataChange}
      rowRender={handleOOTARowRender}
      sortable={true}
      ref={exportGridRef}
      exportFileName={UIText.OOATT}
      noRecordsMessage={UIText.No_data_found_filter_text}
    />
  );

  const showTabContent = () => (
    <div>
      {showGridToolBar()}
      {showGridDataView()}
    </div>
  );

  const showTabComponent = () => {
    const items = getOOTATabItems();
    return (
      <TabComponent
        items={items}
        activeIndex={selectedTab}
        onChange={(index) => {
          setSelectedTab(index);
        }}
      />
    );
  };

  const showModeComponent = () => {
    return (
      <div className="tab-container">
        {showTabComponent()}
        <div className="switchContainer">
          <Switch
            onLabel={""}
            offLabel={""}
            size="small"
            checked={historicalViewEnabled}
            onChange={() => {
              const enabled = !historicalViewEnabled;
              setHistoricalViewEnabled(enabled);
              props?.historyViewAction(enabled);
            }}
          />
          <span className="switch-label">{UIText.Enable_Historical_View}</span>
        </div>
      </div>
    );
  };

  return (
    <div className="out-of-trend-analysis-screen-container data-grid">
      <div className="row ">
        {showModeComponent()}
        {masterDataLoading ? (
          <div className="loader-container">
            <Loader
              size="medium"
              themeColor="warning"
              type="converging-spinner"
            />
            <br />
            <h4>Fetching Details, Please Wait...</h4>
          </div>
        ) : (
          <div
            className={`data-grid-container ${
              selectedTab === TrendViewMode.TANK ? "grid-tank-view" : ""
            }`}
          >
            {showTabContent()}
            {showSiteInfoModal && (
              <SiteInfoScreen
                globalSiteId={selectedSite?.siteId}
                countryCode={selectedSite?.country || currentCountryCode}
                close={closeModal}
              />
            )}

            {exporting && (
              <LoadingPanel message={UIText.Exporting_the_data} />
            )}
            {fetchingData && (
              <LoadingPanel message={UIText.Fetching_the_data} />
            )}
            {submitting && (
              <LoadingPanel message={UIText.Submitting_the_values} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export { OutOfToleranceTrendAnalysis };
