import React from "react";
import { Input, RangeSlider } from "@progress/kendo-react-inputs";

export const SliderRangeComponent = (props) => {
  const { sliderValue, differenceVal, setSliderValue } = props;

  const onSliderChange = (event, type) => {
    const inputValue = event.target.value;
    const regex = /^(-?\d+|\d+)$/;
    const valid = regex.test(inputValue);
    const inputRange =
      inputValue >= differenceVal.min && inputValue <= differenceVal.max;
    if ((valid && inputRange) || inputValue === "-" || inputValue === "") {
      setSliderValue(prev => ({
        ...prev,
        [type]: inputValue,
      }));
    }
  };

  return (
    <span className="filter">
      <label htmlFor="Threshold">{props?.label}</label>
      <span className="filter-row">
        <span>
          <Input
            value={sliderValue.start}
            onChange={(e) => {
              onSliderChange(e, "start");
            }}
          />
        </span>
        <span>
          <Input
            value={sliderValue.end}
            onChange={(e) => {
              onSliderChange(e, "end");
            }}
          />
        </span>
      </span>
      <RangeSlider
        defaultValue={sliderValue}
        value={sliderValue}
        step={1}
        min={differenceVal.min}
        max={differenceVal.max}
        onChange={(event) => {
          setSliderValue({
            start: Math.round(event?.value?.start),
            end: Math.round(event?.value?.end),
          });
        }}
      />
    </span>
  );
};
