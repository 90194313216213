import { CustomHeaderCell } from "../account-for-deliveries/account-for-deliveries-utils";
import { TotalHighlightedCell } from "../common-grid-cells";
import { DECIMAL } from "../common-utils";
import { UIText } from "../label-constants";

export const isSame = (dataItem, selectedItem, field) => {
  const valuesSame =
    dataItem.product === selectedItem.product &&
    dataItem.tank === selectedItem.tank &&
    field === selectedItem?.editField;

  return (
    dataItem.date === selectedItem.date &&
    dataItem.globalSiteId === selectedItem.globalSiteId &&
    valuesSame
  );
};

export const getFooterItem = (response) => {
  const footerItem = {
    title: UIText.TOTAL,
    total: "",
    locked: true,
  };

  let total = 0;
  const regionTotals = {};
  response?.forEach((item) => {
    total += item?.total;
    item?.regions?.forEach((region) => {
      const regionCount = item[region.regionName];
      const currentCount =
        parseInt(regionTotals[region.regionName]) > 0
          ? parseInt(regionTotals[region.regionName])
          : 0;
      regionTotals[region.regionName] = currentCount + regionCount;
    });
  });

  return {
    ...footerItem,
    ...regionTotals,
    total: total,
  };
};

const RegionCountCell = (props) => {
  const { dataItem, field } = props;
  const value = dataItem?.[field] || 0;

  const missingRow = dataItem?.title?.toLowerCase()?.includes("missing sites");
  const highlight = missingRow && value > 0;

  if (props.dataItem?.locked) {
    const styles = {};
    let classNames = props.className;
    styles.bottom = 0;
    classNames += " k-grid-row-sticky";
    return (
      <td
        style={{
          ...styles,
        }}
        className={classNames}
      >
        {value}
      </td>
    );
  }
  return (
    <td
      {...props}
      style={{
        color: highlight ? "#dd1d21" : "#595959",
        fontWeight: highlight ? "bold" : "normal",
      }}
    >
      {value}
    </td>
  );
};

export const countColsToShow = (ottData, isSummary) => {
  const validRow = ottData?.find((item) => item?.regions?.length > 0);

  const columnsList = validRow?.regions?.sort((a, b) =>
    a?.regionName?.localeCompare(b?.regionName)
  );

  const regionColumns = columnsList?.map((region) => {
    const isMPColumn = region?.regionName?.includes("MP");
    const columnWidth =
      isMPColumn && window.innerWidth * DECIMAL.POINT_ZERO_FOUR_THREE;
    return {
      title: region?.regionName,
      field: region?.regionName,
      filterable: true,
      editable: false,
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return <RegionCountCell {...props} />;
      },
      width: columnWidth,
    };
  });

  let width = DECIMAL.POINT_TWO;
  if (regionColumns?.length > 0) {
    width = DECIMAL.POINT_TWO / regionColumns?.length;
    if (width < DECIMAL.POINT_ZERO_FIVE) {
      width = DECIMAL.POINT_ZERO_FIVE;
    }
  }

  let columns = [
    {
      title: isSummary ? "" : UIText.Reconciliation_Issues,
      field: "title",
      filterable: true,
      editable: false,
      headerCell: CustomHeaderCell,
      width: isSummary
        ? window.innerWidth * width
        : window.innerWidth * DECIMAL.POINT_ONE_THREE,
    },
  ];

  if (regionColumns instanceof Array && regionColumns?.length !== 1) {
    columns = [...columns, ...regionColumns];
  }
  columns = [
    ...columns,
    {
      title: UIText.Total,
      field: "total",
      filterable: true,
      editable: false,
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return <TotalHighlightedCell {...props} />;
      },
    },
  ];

  return columns;
};
