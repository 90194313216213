import React from "react";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Popup } from "@progress/kendo-react-popup";
import { Button, DateInput, Input, DatePicker } from "@progress/kendo-react-all";
import "./common-utils.scss";
import { isTimeOlderThanMins } from "./common-utils";
import {
  ArrowCcw,
  FileText,
  MailSend,
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import { UIText } from "./label-constants";

export const MissingIconCell = (props) => {
  const { dataItem, field } = props;
  const dependent = props?.dependent;
  const value = dataItem?.[field];
  if (value || value === 0 || dataItem?.footerItem) {
    if (dependent) {
      const stock = dataItem?.[dependent?.field];
      if (!(stock || stock === 0 || dataItem?.footerItem)) {
        return (
          <td>
            <div className="dependentContainer">
              {value}{" "}
              <Tooltip openDelay={100} position="left" anchorElement="target">
                <div
                  className="dependentIndicator"
                  title={dependent?.message}
                ></div>
              </Tooltip>
            </div>
          </td>
        );
      }
      return <td>{value}</td>;
    }
    return <td>{value}</td>;
  } else {
    const icon = require("../../../../assets/warning.png");
    const iconClass = "warningIcon";
    return (
      <td>
        <Tooltip openDelay={100} position="left" anchorElement="target">
          <img className={iconClass} title={UIText.Data_not_received} src={icon} alt="Warning" />
        </Tooltip>
      </td>
    );
  }
};

export const MissingMandatoryCell = (props) => {
  const { dataItem, field } = props;
  const value = dataItem?.[field];
  if (value || value === 0 || dataItem?.footerItem) {
    return <td>{value}</td>;
  } else {
    const icon = require("../../../../assets/error.png");
    const iconClass = "errorIcon";
    return (
      <td>
        <Tooltip openDelay={100} position="left" anchorElement="target">
          <img className={iconClass} title={UIText.Data_not_received} src={icon} alt="Warning" />
        </Tooltip>
      </td>
    );
  }
};

export const MultilineTextCell = (props) => {
  const { dataItem, field } = props;
  const value = dataItem?.[field];
  const arrayValues = value?.split("\n");
  const uniqueArray = arrayValues instanceof Array && [...new Set(arrayValues)];
  const lines = uniqueArray;
  return (
    <td>
      {lines.map((line) => (
        <div key={`${line}`}>{line}</div>
      ))}
    </td>
  );
};

export const ShipToLinkCell = (props) => {
  const { dataItem, field } = props;
  const value = dataItem?.[field];
  const country =
    dataItem?.country ||
    dataItem?.countryCode ||
    dataItem?.Country ||
    dataItem?.region;
  return (
    <td>
      <Button
        fillMode="link"
        className="ship-to-link-btn"
        onClick={() => props?.linkAction(value, country)}
      >
        {value}
      </Button>
    </td>
  );
};

export const TotalHighlightedCell = (props) => {
  const { dataItem, field } = props;
  const value = dataItem?.[field];
  if (props.dataItem?.locked) {
    const styles = {};
    let classNames = props.className;
    styles.bottom = 0;
    classNames += " k-grid-row-sticky";
    return (
      <td
        style={{
          textAlign: "center",
          ...styles,
        }}
        className={classNames}
      >
        {value}
      </td>
    );
  }
  return (
    <td {...props} className="totalCount">
      {value}
    </td>
  );
};

export const BoxItem = ({ item }) => {
  return (
    <div className="boxContainer">
      <span className="highlighter"></span>
      <span className="box-title">{item?.title}</span>
      <span className="box-count">{item?.count}</span>
    </div>
  );
};

export const StartDateInput = (props) => {
  const dateClassName =
    props.value !== null || !props?.showError ? "date" : "empty-date";
  return (
    <div className="dateContainer">
      <label htmlFor="startDate">{UIText.Start_Date}</label>
      <DateInput
        {...props}
        label={undefined}
        className={`${dateClassName}`}
        placeholder="MM/DD/YY"
      />
    </div>
  );
};

export const EndDateInput = (props) => {
  const dateClassName =
    props.value !== null || !props?.showError ? "date" : "empty-date";
  return (
    <div className="dateContainer">
      <label htmlFor="endDate">{UIText.End_Date}</label>
      <DateInput
        {...props}
        label={undefined}
        className={`${dateClassName}`}
        placeholder="MM/DD/YY"
      />
    </div>
  );
};

export const ActionType = {
  Retry: 0,
  Send: 1,
  Logs: 2,
};

const getActionIcon = (props) => {
  switch (props?.actionType) {
    case ActionType.Retry:
      return (
        <div className="retry-icon-img">
          <ArrowCcw width={"0.8vw"} height={"0.8vw"} />
        </div>
      );
    case ActionType.Send:
      return (
        <div className="send-icon">
          <MailSend width={"0.8vw"} height={"0.8vw"} />
        </div>
      );
    case ActionType.Logs:
      return (
        <div className="send-icon">
          <FileText width={"0.8vw"} height={"0.8vw"} />
        </div>
      );

    default:
      return <></>;
  }
};

export const ActionButtonCell = (props) => {
  const { dataItem } = props;
  const shouldValidate = props?.shouldValidate;
  const value = dataItem?.totalStock;
  const isOlderThan10Mins = dataItem?.RetriedOn
    ? isTimeOlderThanMins(dataItem?.RetriedOn, 10)
    : true;
    
  if (shouldValidate && !(value || value === 0)) {
    return <td className="action-cell-icon-container" />;
  }
  return (
    <td className="action-cell-icon-container">
      <Button
        onClick={(event) => {
          const element = event.target;
          const rect = element.getBoundingClientRect();
          const x = event.clientX;
          const y = rect.top;
          const offset = {
            left: x,
            top: y,
          };
          props?.handleAction(dataItem, offset);
        }}
        disabled={!isOlderThan10Mins}
      >
        <span className="retry-container">
          <Tooltip openDelay={100} position="left" anchorElement="target">
            <div className="dependentIndicator" title={props?.title}>
              {getActionIcon(props)}
            </div>
          </Tooltip>
        </span>
      </Button>
    </td>
  );
};

export const ViewDetailCell = (props) => {
  const { dataItem, setShowDetailsModal, setModalContent, field } = props;
  return (
    <td>
      <Button
        className="ship-to-link-btn"
        onClick={() => {
          setModalContent((_prev) => {
            return {
              ...dataItem,
              type: field,
              content: dataItem?.[field],
            };
          });
          setShowDetailsModal(true);
        }}
      >
        <span className="view-detail">{props.title}</span>
      </Button>
    </td>
  );
};

export const PumpInputCell = (props) => {
  const [focused, setFocused] = React.useState(false);
  const anchor = React.useRef(null);
  const isValid = props.dataItem[props.field + "Valid"];
  return props.tdProps ? (
    <>
      <td
        ref={anchor}
        {...props.tdProps}
        style={{
          ...props.tdProps.style,
        }}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
      >
        {React.Children.toArray(props.children).map((child) => {
          return React.cloneElement(child, {
            className:
              child.props.className +
              (isValid ? " k-input-solid k-invalid" : ""),
          });
        })}
      </td>
      {props.dataItem.inEdit && focused && (
        <Popup
          anchor={anchor.current}
          show={!!isValid}
          popupClass={"popup-content"}
        >
          <span className="error-message">{isValid}</span>
        </Popup>
      )}
    </>
  ) : null;
};

export const TextInputField = (props) => {
  const selectedField = `${props?.selectedField}`;
  const selectedValue = props?.fields?.[selectedField];
  const mandatory = props?.mandatory;

  const sameItem = props?.selectedItem === selectedField;
  const errorClassName =
    props?.showError && mandatory && !(selectedValue?.length > 0)
      ? "errorClassName"
      : "";

  const disabled = props?.disabled ? "disabled-input" : "";

  return (
    <div className="deliveryRow">
      <span className="inputLbl">
        {props?.label}
        {mandatory && <span style={{ color: "red" }}>*</span>}
      </span>
      <span className={`${errorClassName} inputVal ${disabled}`}>
        <Input
          ref={sameItem ? props?.inputRef : null}
          value={selectedValue}
          onChange={(event) => {
            props?.onChange({
              event,
              selectedField,
              ...props,
            });
          }}
          disabled={props?.disabled}
        />
      </span>
    </div>
  );
};
export const DateField = (props) => {
  const selectedField = `${props?.selectedField}`;
  const selectedValue = props?.fields?.[selectedField];
  const mandatory = props?.mandatory;
  const errorClassName =
    props?.showError && !selectedValue ? "errorClassName" : "";
  return (
    <div className="deliveryRow">
      <span className="dateLbl">
        {props?.label}
        {mandatory && <span style={{ color: "red" }}>*</span>}
      </span>
      <span className="dateVal">
        <DatePicker
          format={"dd-MMM-yyyy"}
          value={selectedValue}
          onChange={(event) => {
            props?.onDateChange({
              event,
              selectedField,
              ...props
            });
          }}
          className={errorClassName}
          placeholder={UIText.Select}
          max={props?.max}
          min={props.min}
        />
      </span>
    </div>
  );
};

