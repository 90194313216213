import { useEffect, useState } from "react";
import { userService } from "../../../../../services/user";
import { siteService } from "../../../../../services/sites";
import { AppDataGrid } from "../../../../components/data-grid/dataGrid";
import "../../reports.scss";
import { DEFAULT_PAGE_SIZE } from "../common-utils";
import UserEditDetailsContainer from "./user-edit-detail-screen";
import { UIText } from "../label-constants";

const EditUserScreen = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [siteIds, setSiteIds] = useState([]);

  const fetchAllUsers = async () => {
    const userList = await userService.fetchAuditList();
    setUsers(userList);
  };
  const fetchUserRoles = async () => {
    const roleList = await userService.fetchUserRoles();
    setRoles(roleList);
  };

  const fetchAllSiteIds = async () => {
    const siteIdList = await siteService.fetchSiteIdList("DE");
    setSiteIds(siteIdList);
  };

  useEffect(() => {
    fetchAllUsers();
    fetchUserRoles();
    fetchAllSiteIds();
  }, []);

  const colsToShow = [
    {
      title: UIText.First_Name,
      field: "UserFirstName",
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Last_Name,
      field: "UserLastName",
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Email,
      field: "UserEmail",
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Is_Active,
      field: "IsActive",
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.Role,
      field: "RoleName",
      filterable: true,
      sortable: true,
    },
  ];

  const showDetailController = (props) => {
    return (
      <UserEditDetailsContainer {...props} roles={roles} siteIds={siteIds} />
    );
  };

  return (
    <>
      <div className="container data-grid">
        <div className="row ">
          <div className="data-grid-container">
            <AppDataGrid
              exportable={true}
              exportFileName="Users Information"
              style={{ height: "71.5vh" }}
              take={DEFAULT_PAGE_SIZE}
              pageable={true}
              filterable={true}
              expandable={true}
              sortable={true}
              data={users}
              detailsContainer={props => showDetailController(props)}
              columnsToShow={colsToShow}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { EditUserScreen };
