import { useNavigate } from "react-router-dom";
import "./navbar.scss";
import { Grid } from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { loginService } from "../../../services/login";
import DropDownTreeList from "../dropdown-tree/dropdown-tree";
import {
  Menu,
  MenuItem,
  NavBar,
  Pecten,
} from "@sede-x/shell-ds-react-framework";
import { HeaderProfile } from "./HeaderProfile.jsx";
import {
  setSelectedReport,
  setUserScreenList,
  setCurrentCountry,
  setCurrentCluster,
} from "../../../state-management/actions.js";
import { userService } from "../../../services/user.js";
import { emptyCacheAndHardReload } from "../../pages/reports/components/common-utils.js";
import { isGlobalUser, isSOMAdminUser } from "../../../services/utils.js";

const TitleComponent = (props) => {
  return (
    <div role="button" className="pecten-container" tabIndex="0" onClick={props?.onHomeClick}>
      <a href="/home" aria-label="WSM-Analytics">
        <Pecten colorStyle="color" size="medium" />
      </a>
    </div>
  );
};

const NavigationBar = (props) => {
  const store = useStore();
  const user = store.getState()?.user?.user;
  const uniqueUserId = store.getState()?.login?.UniqueUserId;
  const userPreferredCountry = user?.UserPreference?.DefaultCountry;
  const defaultCountry = useSelector(
    (state) => state?.reports?.selectedCountry
  );
  const [currentCountryCode, setCurrentCountryCode] = useState(
    defaultCountry || userPreferredCountry
  );
  const [loggedInUser, setLoggedInUser] = useState(user);
  
  const dispatch = useDispatch();

  store.subscribe(() => {
    const { selectedCountry } = store.getState().reports;
    setCurrentCountryCode(selectedCountry);

    const userInfo = store.getState()?.user?.user;
    setLoggedInUser(userInfo);
  });

  useEffect(() => {
    props?.clusterDetails?.forEach((parent) => {
      const selectedItem = parent?.items?.find(
        (item) => item.text === currentCountryCode
      );
      if (selectedItem) {
        dispatch(setCurrentCluster(parent?.text));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.clusterDetails]);

  const navigate = useNavigate();
  const handeLogOut = async () => {
    await emptyCacheAndHardReload();
    const email = store.getState()?.user?.user?.UserEmail;
    if (email.includes("@shell.com")) {
      window.location.href = "/login";
    } else {
      await loginService.nonShellUserLogout();
    }
  };
  const regionDisabled =
    !(isGlobalUser() || isSOMAdminUser());

  const onHomeClick = (event) => {
    navigate("/home");
    event.preventDefault();
  };

  const onAboutClick = () => {
    navigate("/about");
  };

  const onTourClick = () => {
    navigate("/tour");
  };

  const onContactClick = () => {
    navigate("/contact");
  };

  const refreshSelectedReport = (screenList, report) => {
    screenList?.forEach((item) => {
      if (item?.children) {
        refreshSelectedReport(item?.children, report);
      } else if (item?.id === report?.pageId) {
        dispatch(
          setSelectedReport({
            ...report,
            pageName: item?.pageName,
            reportId: item?.reportId,
            tableName: item?.tableName,
            columnName: item?.columnName,
          })
        );
        return;
      }
    });
  };

  const handelReportChange = async (cluster, country) => {
    if (currentCountryCode === country) {
      return;
    }
    setCurrentCountryCode(country);
    dispatch(setCurrentCluster(cluster));
    dispatch(setCurrentCountry(country));

    const User = await userService.fetchUser(uniqueUserId, country);
    if (User?.hasOwnProperty("UserFirstName")) {
      dispatch(setUserScreenList(User?.ScreenList));
      const { selectedReport } = store.getState().reports;
      refreshSelectedReport(User?.ScreenList, selectedReport);
    }
  };

  return (
    <div className="container-fluid ">
      <div className="app-navbar">
        <NavBar
          className="nav-bar"
          elevation={true}
          size="medium"
          pectenArea={<TitleComponent onHomeClick={onHomeClick} />}
          avatarArea={
            <HeaderProfile profile={loggedInUser} logout={handeLogOut} />
          }
          direction="horizontal"
          header={{
            title: "WSM-Analytics",
          }}
        >
          <Menu
            mode="horizontal"
            defaultSelectedKeys={["regions"]}
            disabledOverflow
            className="menu"
          >
            <MenuItem onClick={onHomeClick} key="home" itemIcon={<Grid />}>
              <span className="dashboard-item">Dashboard</span>
            </MenuItem>
            <MenuItem key="countrySelection">
              <DropDownTreeList
                data={props?.clusterDetails}
                userPreferredCountry={currentCountryCode}
                disabled={regionDisabled}
                onChange={handelReportChange}
              />
            </MenuItem>
            <MenuItem key="about-us" onClick={onAboutClick}>
              <span className="about-item">
                <img
                  src={require("../../../assets/about.svg").default}
                  alt="Info Icon"
                  className="link-icon"
                />
                <p className="link-text">About us</p>
              </span>
            </MenuItem>
            <MenuItem key="tour" onClick={onTourClick}>
              <span className="about-item">
                <img
                  src={require("../../../assets/tour.svg").default}
                  alt="Info Icon"
                  className="link-icon"
                />
                <p className="link-text">Take a tour</p>
              </span>
            </MenuItem>
            <MenuItem key="contact-us" onClick={onContactClick}>
              <span className="about-item">
                <img
                  src={require("../../../assets/contact.svg").default}
                  alt="Info Icon"
                  className="link-icon"
                />
                <p className="link-text">Contact Us</p>
              </span>
            </MenuItem>
          </Menu>
        </NavBar>
      </div>
    </div>
  );
};

export { NavigationBar };
