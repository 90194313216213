import { CustomHeaderCell } from "../account-for-deliveries/account-for-deliveries-utils";
import { ShipToLinkCell } from "../common-grid-cells";
import { UIText } from "../label-constants";

export const getCountries = () => {
  return [
    {
      id: "de",
      text: "DE",
    },
    {
      id: "ch",
      text: "CH",
    },
    {
      id: "at",
      text: "AT",
    },
    {
      id: "be",
      text: "BE",
    },
    {
      id: "fr",
      text: "FR",
    },
    {
      id: "lu",
      text: "LU",
    },
    {
      id: "nl",
      text: "NL",
    },
  ];
};

export const colsToShow = (handleShipToLinkAction) => {
  const columns = [
    {
      title: UIText.ShipTo,
      field: "siteID",
      headerCell: CustomHeaderCell,
      cell: (props) => {
        return (
          <ShipToLinkCell {...props} linkAction={handleShipToLinkAction} />
        );
      },
      filterable: true,
      sortable: true,
    },
    {
      title: UIText.OutBound_Delivery_No,
      field: "shipmentID",
      filterable: true,
      sortable: true,
      headerCell: CustomHeaderCell,
    },
    {
      title: UIText.Correlation_Id,
      field: "correlationId",
      filterable: true,
      sortable: true,
      headerCell: CustomHeaderCell,
    },
    {
      title: UIText.Received_On,
      field: "timeGenerated",
      filterable: true,
      sortable: true,
      headerCell: CustomHeaderCell,
    },
  ];
  return columns;
};
