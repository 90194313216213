import React from "react";
import "./out-of-tolerance-trend-analysis.scss";
import { OutOfToleranceTrendAnalysis } from "./out-of-tolerance-trend-analysis";

const OutOfToleranceTrendAnalysisContainer = (props) => {
  return (
    <>
      <div className="out-of-trend-analysis-screen-container">
        <div className="row content-container">
          <OutOfToleranceTrendAnalysis {...props} />
        </div>
      </div>
    </>
  );
};

export { OutOfToleranceTrendAnalysisContainer };
